import { capitalize } from 'lodash';
import { DataCardConfig } from '#/types/data-card-config.types';

import { PROVIDERS } from '../constants';
import {
  CredTimelineStep,
  ENotificationType,
  EnrollmentAddressFormFieldParams,
  FacilityFileTypeConfigsPayload,
  FileLogicFieldType,
  FileTypeConfigOverridesType,
  FileTypeConfigsPayload,
  INotificationConfigItem,
  PsvChecklist,
} from '../types';
import { stringCompareFn } from './misc';
import { MissingInfoFormFielsConfig } from '#/types/provider-enrollment-workflows.types';
import {
  entitySupportingDocuments,
  entityTaxDocuments,
} from '../components/OrgEntities/constants';
import { getCredStepDisplayLabel } from './misc';

export const PROVIDER_APPLICATION_STATUS = {
  applicationSent: 'Application Sent',
  ApplicationInProgress: 'Application In Progress',
  applicationReceived: 'Application Received',
};

export const BASE_URL = process.env.BASE_API_PATH || '/_api';
export const NEXT_BASE_URL = process.env.NEXT_PUBLIC_API_URL;
export const providerTypes = [
  'AA',
  'ABA',
  'ACU',
  'ADC',
  'APN',
  'AT',
  'ATR',
  'AUD',
  'BCaBA',
  'BSW',
  'BT',
  'CNS',
  'CP',
  'CphT',
  'CRNA',
  'CSP',
  'CSW',
  'CTRS',
  'DC',
  'DDS',
  'DMD',
  'DO',
  'DPM',
  'DT',
  'EMT',
  'GC',
  'HOS',
  'IBCLC',
  'LN',
  'LP',
  'LPN',
  'LPT',
  'MA',
  'MD',
  'MFT',
  'MT',
  'MW',
  'ND',
  'NEU',
  'NMW',
  'NP',
  'OA',
  'OD',
  'OPT',
  'OT',
  'OTA',
  'PA',
  'PC',
  'PDH',
  'PHA',
  'PHD',
  'PT',
  'PTA',
  'PTNL',
  'RDH',
  'RN',
  'RNFA',
  'Rph',
  'RPSGT',
  'RT',
  'SA',
  'SLP',
  'PSW',
];
export const fileTypes = ['Clean', 'Non_Clean'];
export const stateLicensesSourcesMapByState = [
  { label: 'Indiana Board Of Psychology', dependencyValue: 'IN' },
  { label: 'Michigan Board Of Psychology', dependencyValue: 'MI' },
  { label: 'Oregon Medical Board', dependencyValue: 'OR' },
  { label: 'State Medical Board of Ohio', dependencyValue: 'OH' },
  { label: 'Arkansas State Medical Board', dependencyValue: 'AR' },
  { label: 'Tennessee Board of Osteopathic Examiners', dependencyValue: 'TN' },
  {
    label: 'Michigan Board of Osteopathic Medicine and Surgery',
    dependencyValue: 'MI',
  },
  {
    label: 'Washington Board of Osteopathic Medicine and Surgery',
    dependencyValue: 'WA',
  },
  {
    label: 'Oklahoma State Board of Medical Licensure & Supervision',
    dependencyValue: 'OK',
  },
  { label: 'Maryland Board of Nursing', dependencyValue: 'MD' },
  { label: 'Nevada State Board of Nursing', dependencyValue: 'NV' },
  {
    label: 'Nebraska Advanced Practice Registered Nurse Board',
    dependencyValue: 'NE',
  },
  { label: 'North Dakota Board of Counselor Examiners', dependencyValue: 'ND' },
  {
    label: 'State Of Vermont Board Of Allied Mental Health',
    dependencyValue: 'VT',
  },
  {
    label: 'Texas State Board Of Examiners Of Professional Counselors',
    dependencyValue: 'TX',
  },
  {
    label: 'The Virginia State Board Of Professional Counselor Examiners',
    dependencyValue: 'VA',
  },
  {
    label: 'State Of Vermont Board Of Applied Behavior Analysis',
    dependencyValue: 'VT',
  },
  {
    label: 'The Kansas State Board Of Addiction Counselor Examiners',
    dependencyValue: 'KS',
  },
  {
    label:
      'State Board of Marriage and Family Therapy Examiners: The Alcohol and Drug Counselor Committee',
    dependencyValue: 'NJ',
  },
  {
    label: 'Massachusetts Board of Registration in Dentistry',
    dependencyValue: 'MA',
  },
  { label: 'Oklahoma State Board of Dentistry', dependencyValue: 'OK' },
  { label: 'Michigan Board of Dentistry', dependencyValue: 'MI' },
  { label: 'Board of Dental Examiners of Alabama', dependencyValue: 'AL' },
  { label: 'Pennsylvania State Board of Dentistry', dependencyValue: 'PA' },
  {
    label: 'Delaware Board of Dentistry and Dental Hygiene',
    dependencyValue: 'DE',
  },
  {
    label: 'Massachusetts Board Of Marriage And Family Therapist',
    dependencyValue: 'MA',
  },
  {
    label:
      'The Virginia State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'VA',
  },
  { label: 'Hawaii Midwives Licensing Program', dependencyValue: 'HI' },
  { label: 'Mississippi Board of Psychology', dependencyValue: 'MS' },
  {
    label: 'Office of Professional Licensure & Certification Midwifery Council',
    dependencyValue: 'NH',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'KY' },
  {
    label: 'Arizona Regulatory Board of Physician Assistants',
    dependencyValue: 'AZ',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'WI' },
  {
    label: 'Alabama Board Of Examiners Inmarriage And Family Therapy',
    dependencyValue: 'AL',
  },
  {
    label:
      'South Carolina Board of Examiners for Licensure of Professional Counselors, Marriage and Family Therapists, Addiction Counselors and Psycho-Educational Specialists',
    dependencyValue: 'SC',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'WV' },
  { label: 'Tennessee Physician Assistant Committee', dependencyValue: 'TN' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'SD' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'DE' },
  { label: 'ND State Board of Counselor Examiners', dependencyValue: 'ND' },
  { label: 'West Virginia Board of Social Work', dependencyValue: 'WV' },
  {
    label: 'Illinois Department of Financial & Professional Regulation',
    dependencyValue: 'IL',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'AP' },
  { label: 'Marshall Islands', dependencyValue: 'MH' },
  { label: 'Medical Licensing Board of Indiana', dependencyValue: 'IN' },
  {
    label: 'Illinois Department of Financial and Professional Regulation',
    dependencyValue: 'IL',
  },
  { label: 'Iowa Board Of Psychology', dependencyValue: 'IA' },
  { label: 'Rhode Island Board of Psychology', dependencyValue: 'RI' },
  { label: 'Massachusetts Board Of Psychology', dependencyValue: 'MA' },
  { label: 'Wisconsin Examining Board Of Psychology', dependencyValue: 'WI' },
  { label: 'Texas Behavioral Health Executive Council', dependencyValue: 'TX' },
  {
    label: 'Tennessee Board Of Examiners Of Psychology',
    dependencyValue: 'TN',
  },
  {
    label: 'The Kansas State Board Of Psychologist Examiners',
    dependencyValue: 'KS',
  },
  {
    label: 'State Of Vermont Board Of Psychological Examiners',
    dependencyValue: 'VT',
  },
  { label: 'Alaska State Medical Board', dependencyValue: 'AK' },
  { label: 'Washington Medical Commission', dependencyValue: 'WA' },
  {
    label: 'Nevada State Board of Osteopathic Medicine',
    dependencyValue: 'NV',
  },
  {
    label: 'Rhode Island Advisory Council of Midwifery',
    dependencyValue: 'RI',
  },
  {
    label: 'Tennessee Council of Certified Professional Midwifery',
    dependencyValue: 'TN',
  },
  { label: 'Florida Board of Nursing', dependencyValue: 'FL' },
  { label: 'Illinois Board of Nursing', dependencyValue: 'IL' },
  { label: 'Michigan Board of Nursing', dependencyValue: 'MI' },
  { label: 'Tennessee Board of Nursing', dependencyValue: 'TN' },
  { label: 'Mississippi Board of Nursing', dependencyValue: 'MS' },
  { label: 'Arizona State Board of Nursing', dependencyValue: 'AZ' },
  { label: 'North Carolina Board of Nursing', dependencyValue: 'NC' },
  { label: 'California Board of Registered Nursing', dependencyValue: 'CA' },
  {
    label: 'Maine Board Of Counseling Professionals Licensure',
    dependencyValue: 'ME',
  },
  {
    label: 'The Missouri State Committee For Professional Counselors',
    dependencyValue: 'MO',
  },
  {
    label:
      'Delaware Board of Mental Health and Chemical Dependency Professionals',
    dependencyValue: 'DE',
  },
  {
    label:
      'Wisconsin Marriage And Family Therapy, Professional Counseling And Social Work Examining Board',
    dependencyValue: 'WI',
  },
  { label: 'Utah State Board of Nursing', dependencyValue: 'UT' },
  { label: 'New Hampshire Board of Nursing', dependencyValue: 'NH' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NJ' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'CO' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'TN' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'GA' },
  { label: 'Alaska,Regulation Of Behavior Analyst', dependencyValue: 'AK' },
  {
    label: 'Rhode Island Board of Applied Behavioral Analysts',
    dependencyValue: 'RI',
  },
  {
    label: 'Kentucky Applied Behavior Analysis Licensing Board',
    dependencyValue: 'KY',
  },
  { label: 'Maine Board Of Alcohol & Drug Counselors', dependencyValue: 'ME' },
  {
    label: 'The Virginia State Board Of Addiction Counselor Examiners',
    dependencyValue: 'VA',
  },
  {
    label:
      'Washington State Department Of Health Substance Use Disorder Professional Certification Advisory Committee',
    dependencyValue: 'WA',
  },
  { label: 'New Jersey State Board of Dentistry', dependencyValue: 'NJ' },
  { label: 'District of Columbia Board of Social Work', dependencyValue: 'DC' },
  {
    label: 'State Of Connecticut Board Of Social Worker',
    dependencyValue: 'CT',
  },
  {
    label: 'The Missouri State Committee For Social Workers',
    dependencyValue: 'MO',
  },
  {
    label: 'New Jersey’S State Board Of Social Work Examiners',
    dependencyValue: 'NJ',
  },
  {
    label:
      'Georgia Composite Board of Professional Counselors, Social Workers, and Marriage & Family Therapists',
    dependencyValue: 'GA',
  },
  { label: 'Colorado Dental Board', dependencyValue: 'CO' },
  { label: 'Nebraska Board of Dentistry', dependencyValue: 'NE' },
  {
    label: 'Texas State Board Of Examiners Of Marriage And Family Therapists',
    dependencyValue: 'TX',
  },
  {
    label:
      'The Nebraska State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'NE',
  },
  { label: 'New Mexico Psychologist Examiners Board', dependencyValue: 'NM' },
  {
    label: 'Maryland Department of Health: Board of Examiners of Psychologists',
    dependencyValue: 'MD',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NV' },
  {
    label:
      'Nevada State Board of Examiners for Alcohol, Drug and Gambling Counselors',
    dependencyValue: 'NV',
  },
  {
    label: 'South Carolina Board of Social Work Examiners',
    dependencyValue: 'SC',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'AL' },
  {
    label: 'Arizona Board of Behavioral Health Examiners',
    dependencyValue: 'AZ',
  },
  {
    label: 'Minnesota Board of Marriage and Family Therapy',
    dependencyValue: 'MN',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'AR' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'HI' },
  {
    label: 'Michigan Department of Licensing and Regulatory Affairs',
    dependencyValue: 'MI',
  },
  {
    label: 'Minnesota Board Of Behavioral Health & Therapy',
    dependencyValue: 'MN',
  },
  {
    label: 'Texas State Board Of Examiners Of Psychologists',
    dependencyValue: 'TX',
  },
  {
    label: 'Illinois Clinical Psychologists Licensing And Disciplinary Board',
    dependencyValue: 'IL',
  },
  {
    label:
      'Washington State Department Of Health Examining Board Of Psychology',
    dependencyValue: 'WA',
  },
  { label: 'Wyoming Board of Medicine', dependencyValue: 'WY' },
  { label: 'Utah Physicians Licensing Board', dependencyValue: 'UT' },
  { label: 'Kansas State Board of Healing Arts', dependencyValue: 'KS' },
  { label: 'Montana Board of Medical Examiners', dependencyValue: 'MT' },
  {
    label: 'Massachusetts Board of Registration of Physician Assistants',
    dependencyValue: 'MA',
  },
  { label: 'Georgia Board of Nursing', dependencyValue: 'GA' },
  {
    label: 'Rhode Island Board of Nurse Registration and Nursing Education',
    dependencyValue: 'RI',
  },
  { label: 'Michigan Physician Assistant Task Force', dependencyValue: 'MI' },
  { label: 'Montana Board of Behavioral Health', dependencyValue: 'MT' },
  {
    label: 'New Hampshire Board of Mental Health Practice',
    dependencyValue: 'NH',
  },
  {
    label: 'Pennsylvania Board Of Professional Counseling Licensing',
    dependencyValue: 'PA',
  },
  {
    label: 'The Hawaii State Board Of Professional Counselor Examiners',
    dependencyValue: 'HI',
  },
  {
    label: 'The Nebraska State Board Of Professional Counselor Examiners',
    dependencyValue: 'NE',
  },
  {
    label: 'Maryland State Board of Professional Counselors and Therapists',
    dependencyValue: 'MD',
  },
  {
    label: 'The New Mexico State Board Of Professional Counselor Examiners',
    dependencyValue: 'NM',
  },
  {
    label: 'District of Columbia Board of Professional Counseling Licensing',
    dependencyValue: 'DC',
  },
  {
    label:
      'Arkansas Board Of Examiners In Counseling And Marriage & Family Therapy',
    dependencyValue: 'AR',
  },
  {
    label:
      'Board For Licensed Professional Counselors, Licensed Marital And Family Therapists And Licensed Clinical Pastoral Therapists',
    dependencyValue: 'TN',
  },
  {
    label: 'Connecticut Board of Examiners for Nursing',
    dependencyValue: 'CT',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'CA' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'IL' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'IN' },
  {
    label: 'Ohio Chemical Dependency Professionals Board',
    dependencyValue: 'OH',
  },
  {
    label: 'Kentucky Board of Alcohol and Drug Counselors',
    dependencyValue: 'KY',
  },
  {
    label: 'The Colorado State Board Of Addiction Counselor Examiners',
    dependencyValue: 'CO',
  },
  { label: 'Pennsylvania Board Of Social Work', dependencyValue: 'PA' },
  { label: 'New York State Board of Social Work', dependencyValue: 'NY' },
  { label: 'State Of Vermont Board Of Social Workers', dependencyValue: 'VT' },
  {
    label: 'Massachusetts Board of Registration of Social Workers',
    dependencyValue: 'MA',
  },
  {
    label: 'North Carolina Social Work Certification and Licensure Board',
    dependencyValue: 'NC',
  },
  { label: 'Indiana State Board of Dentistry', dependencyValue: 'IN' },
  {
    label: 'Pennsylvania Board Of Marriage And Family Therapist',
    dependencyValue: 'PA',
  },
  {
    label: 'The Missouri State Committee Of Marital & Family Therapists',
    dependencyValue: 'MO',
  },
  {
    label: 'The Hawaii State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'HI',
  },
  {
    label: 'The Kansas State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'KS',
  },
  {
    label:
      'Pennsylvania State Board of Social Workers, Marriage and Family Therapists and Professional Counselors',
    dependencyValue: 'PA',
  },
  {
    label: 'Oklahoma State Board of Behavioral Health Licensure (BBHL)',
    dependencyValue: 'OK',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MD' },
  {
    label: 'Rhode Island Marriage, Family and Mental Health Therapy',
    dependencyValue: 'RI',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MT' },
  { label: 'South Carolina Board of Nursing', dependencyValue: 'SC' },
  {
    label: 'New York State Board for Professional Medical Conduct',
    dependencyValue: 'NY',
  },
  {
    label: 'West Virginia Board of Examiners in Counseling',
    dependencyValue: 'WV',
  },
  {
    label: 'Wisconsin Department of Safety and Professional Services',
    dependencyValue: 'WI',
  },
  {
    label: 'North Dakota Board of Social Work Examiners',
    dependencyValue: 'ND',
  },
  {
    label: 'Division of Quality Assurance Osteopathic Licensure',
    dependencyValue: 'CT',
  },
  { label: 'New Mexico Medical Board', dependencyValue: 'NM' },
  { label: 'Kentucky Board of Medical Licensure', dependencyValue: 'KY' },
  { label: 'Alabama State Board of Medical Examiners', dependencyValue: 'AL' },
  {
    label: 'Delaware Board of Medical Licensure and Discipline',
    dependencyValue: 'DE',
  },
  { label: 'New York State Board for Psychology', dependencyValue: 'NY' },
  {
    label: 'The Missouri State Committee Of Psychologists',
    dependencyValue: 'MO',
  },
  {
    label: 'The Colorado State Board Of Psychologist Examiners',
    dependencyValue: 'CO',
  },
  {
    label: 'The Virginia State Board Of Psychologist Examiners',
    dependencyValue: 'VA',
  },
  {
    label: 'State Of Connecticut Board Of Examiners Of Psychologists',
    dependencyValue: 'CT',
  },
  {
    label: 'Alaska Board Of Psychologist And Psychological Associate Examiners',
    dependencyValue: 'AK',
  },
  { label: 'Arizona Medical Board', dependencyValue: 'AZ' },
  { label: 'Michigan Board of Medicine', dependencyValue: 'MI' },
  { label: 'Virgin Islands Board of Medical Examiners', dependencyValue: 'VI' },
  {
    label: 'Arizona Board of Osteopathic Examiners in Medicine & Surgery',
    dependencyValue: 'AZ',
  },
  { label: 'Oregon State Board of Nursing', dependencyValue: 'OR' },
  { label: 'Pennsylvania State Board of Nursing', dependencyValue: 'PA' },
  { label: 'Oregon Mental Health Regulatory Agency', dependencyValue: 'OR' },
  {
    label: 'State Of Ohio Counselor And Social Worker Board',
    dependencyValue: 'OH',
  },
  {
    label: 'Indiana Board Of Professional Counseling Licensing',
    dependencyValue: 'IN',
  },
  {
    label: 'Kentucky Board of Licensed Professional Counselors',
    dependencyValue: 'KY',
  },
  {
    label: 'Massachusetts Board Of Professional Counseling Licensing',
    dependencyValue: 'MA',
  },
  {
    label: 'The Utah State Board Of Professional Counselor Examiners',
    dependencyValue: 'UT',
  },
  {
    label:
      'Rhode Island Board of Mental Health Counselors and Marriage/Family Therapists',
    dependencyValue: 'RI',
  },
  {
    label:
      'South Dakota Board Of Examiners For Counselors & Marriage And Family Therapists',
    dependencyValue: 'SD',
  },
  {
    label:
      'State Board of Marriage and Family Therapy Examiners: Professional Counselor Examiners Committee',
    dependencyValue: 'NJ',
  },
  { label: 'New Jersey Board of Nursing', dependencyValue: 'NJ' },
  {
    label: 'Texas Department of Licensing and Regulation',
    dependencyValue: 'TX',
  },
  {
    label: 'Tennessee Applied Behavior Analyst Licensing Committee',
    dependencyValue: 'TN',
  },
  {
    label: 'State Of Wisconsin Department Of Safety And Professional Services',
    dependencyValue: 'WI',
  },
  {
    label: 'Tennessee Board Of Alcohol And Drug Abuse Counselors',
    dependencyValue: 'TN',
  },
  {
    label: 'State Of Vermont Board Of Alcohol & Drug Abuse Counselor',
    dependencyValue: 'VT',
  },
  { label: 'Maine Board of Dental Practice', dependencyValue: 'ME' },
  { label: 'Indiana Board Of Social Work', dependencyValue: 'IN' },
  { label: 'Massachusetts Board Of Social Work', dependencyValue: 'MA' },
  {
    label: 'Texas State Board Of Social Worker Examiners',
    dependencyValue: 'TX',
  },
  {
    label: 'The South Dakota Board Of Social Work Examiners',
    dependencyValue: 'SD',
  },
  {
    label: 'The Colorado State Board Of Social Work Examiners',
    dependencyValue: 'CO',
  },
  {
    label: 'Indiana Behavioral Health and Human Services Licensing Board',
    dependencyValue: 'IN',
  },
  { label: 'Connecticut State Dental Commission', dependencyValue: 'CT' },
  {
    label: 'Utah Dentist and Dental Hygienist Licensing Board',
    dependencyValue: 'UT',
  },
  {
    label:
      'Illinois Marriage And Family Therapy Licensing And Disciplinary Board',
    dependencyValue: 'IL',
  },
  { label: 'Vermont Office of Professional Regulation', dependencyValue: 'VT' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'LA' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NC' },
  {
    label: 'North Dakota State Board of Marriage and Family Therapists',
    dependencyValue: 'ND',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'VT' },
  {
    label: 'State Board of Marriage and Family Therapy Examiners',
    dependencyValue: 'NJ',
  },
  { label: 'West Virginia Board of Registered Nurses', dependencyValue: 'WV' },
  { label: 'Tennessee Board of Social Workers', dependencyValue: 'TN' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'UT' },
  { label: 'MAINE STATE BOARD OF NURSING', dependencyValue: 'ME' },
  { label: 'Micronesia, Federated', dependencyValue: 'FM' },
  { label: 'Ohio Nursing Board', dependencyValue: 'OH' },
  { label: 'Government of the Yukon Territory', dependencyValue: 'YT' },
  { label: 'Rhode Island Department of Health', dependencyValue: 'RI' },
  { label: 'Georgia Composite Medical Board', dependencyValue: 'GA' },
  { label: 'The Ohio Board Of Psychology', dependencyValue: 'OH' },
  { label: 'Arizona Board of Psychologist Examiners', dependencyValue: 'AZ' },
  {
    label: 'The Hawaii State Board Of Psychologist Examiners',
    dependencyValue: 'HI',
  },
  { label: 'Wisconsin Medical Examining Board', dependencyValue: 'WI' },
  {
    label: 'New Jersey State Board of Medical Examiners',
    dependencyValue: 'NJ',
  },
  { label: 'Hawaii Board of Nursing', dependencyValue: 'HI' },
  { label: 'Delaware Board of Nursing', dependencyValue: 'DE' },
  { label: 'Iowa Board of Physician Assistants', dependencyValue: 'IA' },
  { label: 'Michigan Board Of Counseling', dependencyValue: 'MI' },
  {
    label: 'Alabama Board of Examiners in Counseling (ABEC)',
    dependencyValue: 'AL',
  },
  {
    label: 'Georgia Board Of Professional Counseling Licensing',
    dependencyValue: 'GA',
  },
  {
    label: 'North Carolina Board of Licensed Clinical Mental Health Counselors',
    dependencyValue: 'NC',
  },
  {
    label:
      'Washington State Department Of Health Mental Health Counselors, Marriage And Family Therapists, And Social Workers Advisory Committee',
    dependencyValue: 'WA',
  },
  { label: 'Montana Board of Nursing', dependencyValue: 'MT' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NE' },
  {
    label: 'Arizona Board of Behavioral Health Examiners',
    dependencyValue: 'AZ',
  },
  {
    label: 'Oklahoma Board of Licensed Alcohol and Drug Counselors',
    dependencyValue: 'OK',
  },
  { label: 'Illinois Board of Dentistry', dependencyValue: 'IL' },
  {
    label: 'Washington Dental Quality Assurance Commission',
    dependencyValue: 'WA',
  },
  { label: 'Georgia Board Of Social Work', dependencyValue: 'GA' },
  { label: 'Kentucky Board Of Social Work', dependencyValue: 'KY' },
  { label: 'Arkansas Board Of Social Worker', dependencyValue: 'AR' },
  {
    label: 'Maine State Board Of Social Worker Licensure',
    dependencyValue: 'ME',
  },
  {
    label: 'Maryland State Board Of Social Work Examiners',
    dependencyValue: 'MD',
  },
  {
    label: 'The Kansas State Board Of Social Work Examiners',
    dependencyValue: 'KS',
  },
  {
    label: 'State Of Connecticut Board Of Marriage And Family Therapist',
    dependencyValue: 'CT',
  },
  {
    label: 'The Utah State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'UT',
  },
  { label: 'Utah Division of Professional Licensing', dependencyValue: 'UT' },
  {
    label: 'New Mexico Counseling and Therapy Practice Board',
    dependencyValue: 'NM',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'OR' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'SC' },
  {
    label: 'ND State Board of Marriage and Family Therapists',
    dependencyValue: 'ND',
  },
  { label: 'Minnesota Board of Psychology', dependencyValue: 'MN' },
  { label: 'Hawaii Board of Psychology', dependencyValue: 'HI' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MO' },
  {
    label: 'South Dakota Board Of Examiners Of Psychologists',
    dependencyValue: 'SD',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'ME' },
  { label: 'Bahamas Medical Council', dependencyValue: 'NP' },
  {
    label: 'Missouri Division of Professional Registration',
    dependencyValue: 'MO',
  },
  {
    label: 'Board Of Alcohol And Drug Abuse Counselors',
    dependencyValue: 'TN',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'KS' },
  {
    label: 'south dakota board of medical and osteopathic examiners',
    dependencyValue: 'SD',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'ND' },
  { label: 'Virginia Board of Medicine', dependencyValue: 'VA' },
  { label: 'New York State Board for Medicine', dependencyValue: 'NY' },
  { label: 'Osteopathic Medical Board of California', dependencyValue: 'CA' },
  { label: 'California Board Of Psychology', dependencyValue: 'CA' },
  { label: 'District of Columbia Board of Psychology', dependencyValue: 'DC' },
  {
    label: 'The Utah State Board Of Psychologist Examiners',
    dependencyValue: 'UT',
  },
  { label: 'Texas Medical Board', dependencyValue: 'TX' },
  { label: 'Iowa Board of Medicine', dependencyValue: 'IA' },
  { label: 'North Carolina Medical Board', dependencyValue: 'NC' },
  { label: 'North Dakota Board of Medicine', dependencyValue: 'ND' },
  { label: 'Maine Board of Licensure in Medicine', dependencyValue: 'ME' },
  { label: 'South Carolina Board of Medical Examiners', dependencyValue: 'SC' },
  {
    label: 'South Dakota Board of Medical & Osteopathic Examiners',
    dependencyValue: 'SD',
  },
  { label: 'Tennessee Board of Medical Examiners', dependencyValue: 'TN' },
  { label: 'Iowa Board of Nursing', dependencyValue: 'IA' },
  { label: 'Kansas State Board of Nursing', dependencyValue: 'KS' },
  { label: 'North Dakota Board of Nursing', dependencyValue: 'ND' },
  { label: 'Vermont State Board of Nursing', dependencyValue: 'VT' },
  { label: 'Missouri State Board of Nursing', dependencyValue: 'MO' },
  { label: "Washington's State Board of Nursing", dependencyValue: 'WA' },
  { label: 'Iowa Board Of Behavioral Science', dependencyValue: 'IA' },
  {
    label: 'The Wyoming Mental Health Professions Licensing Board',
    dependencyValue: 'WY',
  },
  {
    label:
      'Idaho Licensing Board of Professional Counselors and Marriage and Family Therapists',
    dependencyValue: 'ID',
  },
  {
    label:
      'Florida Board Of Clinical Social Work, Marriage & Family Therapy And Mental Health Counseling',
    dependencyValue: 'FL',
  },
  { label: 'Alaska Board of Nursing', dependencyValue: 'AK' },
  { label: 'Michigan Board Of Behavior Analysts', dependencyValue: 'MI' },
  {
    label: 'The Applied Behavior Analyst Licensing Committee',
    dependencyValue: 'TN',
  },
  {
    label: 'The Utah State Board Of Behavior Analyst Examiners',
    dependencyValue: 'UT',
  },
  {
    label: 'The Utah State Board Of Addiction Counselor Examiners',
    dependencyValue: 'UT',
  },
  {
    label: 'Texas State Board Of Examiners Of Chemical Dependency Counselors',
    dependencyValue: 'TX',
  },
  {
    label:
      'The Substance Abuse Counselor Certification Review Committee Of Wisconsin',
    dependencyValue: 'WI',
  },
  {
    label:
      'New Hampshire Board of Licensing for Alcohol and Other Drug Use Professionals',
    dependencyValue: 'NH',
  },
  {
    label: 'Rhode Island Board of Social Work Examiners',
    dependencyValue: 'RI',
  },
  {
    label: 'The Hawaii State Board Of Social Work Examiners',
    dependencyValue: 'HI',
  },
  { label: 'Montana Board of Dentistry', dependencyValue: 'MT' },
  {
    label: 'Indiana Board Of Marriage And Family Therapist',
    dependencyValue: 'IN',
  },
  {
    label: 'State Board Of Marriage And Family Therapy Examiners',
    dependencyValue: 'NJ',
  },
  {
    label:
      'The New Mexico State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'NM',
  },
  { label: 'Indiana State Board of Nursing (ISBN)', dependencyValue: 'IN' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'TX' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'CT' },
  {
    label: 'Oklahoma State Board of Licensed Social Workers (OSBLSW)',
    dependencyValue: 'OK',
  },
  { label: 'Washington Department of Health', dependencyValue: 'WA' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MS' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MN' },
  {
    label: 'Rhode Isnlad Advisory Council of Midwifery',
    dependencyValue: 'RI',
  },
  {
    label: 'Texas State Board of Social Worker Examiners',
    dependencyValue: 'TX',
  },
  { label: 'Kansas Board of Nursing', dependencyValue: 'KS' },
  { label: 'Medical Board of California', dependencyValue: 'CA' },
  { label: 'Maryland Board of Physicians', dependencyValue: 'MD' },
  { label: 'Nebraska Board of Medicine and Surgery', dependencyValue: 'NE' },
  {
    label: 'Puerto Rico Board of Medical Licensure & Discipline',
    dependencyValue: 'PR',
  },
  {
    label: 'Rhode Island Board of Medical Licensure and Discipline',
    dependencyValue: 'RI',
  },
  { label: 'Maine Board Of Examiners Of Psychologists', dependencyValue: 'ME' },
  {
    label: 'Delaware Board of Examiners of Psychologists',
    dependencyValue: 'DE',
  },
  {
    label: 'Massachusetts Board of Registration of Psychologists',
    dependencyValue: 'MA',
  },
  { label: 'New Hampshire Board of Medicine', dependencyValue: 'NH' },
  { label: 'Vermont Board of Medical Practice', dependencyValue: 'VT' },
  { label: 'California Physician Assistant Board', dependencyValue: 'CA' },
  { label: 'Florida Council of Licensed Midwifery', dependencyValue: 'FL' },
  { label: 'Wisconsin Board of Nursing', dependencyValue: 'WI' },
  { label: 'Wyoming State Board of Nursing', dependencyValue: 'WY' },
  {
    label: 'Rhode Island Board of Licensure for Physician Assistants',
    dependencyValue: 'RI',
  },
  {
    label: 'The Colorado State Board Of Professional Counselor Examiners',
    dependencyValue: 'CO',
  },
  {
    label: 'Board Of Examiners For Counselors & Marriage And Family Therapists',
    dependencyValue: 'SD',
  },
  {
    label:
      'Nevada Board Of Examiners For Marriage And Family Therapist And Clinical Professional Counselors',
    dependencyValue: 'NV',
  },
  { label: 'Indiana State Board of Nursing', dependencyValue: 'IN' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NH' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'PA' },
  {
    label:
      'Virginia Board Of Medicine, Behavior Analyst And Assistant Behavior Analyst',
    dependencyValue: 'VA',
  },
  {
    label: 'Rhode Island Board of Chemical Dependency Professionals',
    dependencyValue: 'RI',
  },
  {
    label: 'The New Mexico State Board Of Addiction Counselor Examiners',
    dependencyValue: 'NM',
  },
  {
    label:
      'State Board Of Marriage And Family Therapy Examiners: The Alcohol And Drug Counselor Committee',
    dependencyValue: 'NJ',
  },
  { label: 'Dental Board of California', dependencyValue: 'CA' },
  { label: 'Wyoming Board of Dental Examiners', dependencyValue: 'WY' },
  { label: 'New Mexico Board of Dental Health Care', dependencyValue: 'NM' },
  { label: 'Delaware Board of Social Work Examiners', dependencyValue: 'DE' },
  { label: 'Hawaii State Board of Dental Examiners', dependencyValue: 'HI' },
  { label: 'Virginia Department of Health Professions', dependencyValue: 'VA' },
  {
    label: 'New Mexico Board of Psychologist Examiners',
    dependencyValue: 'NM',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MA' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NY' },
  { label: 'ND Board of Social Work Examiners', dependencyValue: 'ND' },
  { label: 'Iowa Board of Certification (IBC)', dependencyValue: 'IA' },
  {
    label: 'Maryland State Board Of Professional Counselors & Therapists',
    dependencyValue: 'MD',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'NM' },
  { label: 'The DC Board of Dentistry', dependencyValue: 'DC' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'ID' },
  { label: 'Oregon Board of Licensed Social Workers', dependencyValue: 'OR' },
  { label: 'Washington State Department of Health', dependencyValue: 'WA' },
  { label: 'DC Board of Nursing', dependencyValue: 'DC' },
  { label: 'Alabama Board of Examiners in Psychology', dependencyValue: 'AL' },
  { label: 'Michigan Licensing and Regulatory Affairs', dependencyValue: 'MI' },
  { label: 'DC Department of Health', dependencyValue: 'DC' },
  { label: 'Florida Board of Medicine', dependencyValue: 'FL' },
  { label: 'The Wyoming Board of Psychology', dependencyValue: 'WY' },
  { label: 'Board Of Examiners Of Psychology', dependencyValue: 'TN' },
  { label: 'Pennsylvania Board Of Psychology', dependencyValue: 'PA' },
  { label: 'Nevada Board Of Psychological Examiners', dependencyValue: 'NV' },
  {
    label: 'West Virginia Board Of Examiners Of Psychologists',
    dependencyValue: 'WV',
  },
  { label: 'Hawaii Medical Board', dependencyValue: 'HI' },
  { label: 'Pennsylvania State Board of Medicine', dependencyValue: 'PA' },
  { label: 'Oklahoma Board of Osteopathic Examiners', dependencyValue: 'OK' },
  {
    label: 'Mississippi State Board of Medical Licensure',
    dependencyValue: 'MS',
  },
  {
    label: 'Massachusetts Board of Registration in Medicine',
    dependencyValue: 'MA',
  },
  {
    label: 'Vermont Board of Osteopathic Physicians and Surgeons',
    dependencyValue: 'VT',
  },
  { label: 'West Virginia Board of Medicine', dependencyValue: 'WV' },
  { label: 'Indiana Midwifery Committee', dependencyValue: 'IN' },
  { label: 'Ohio Board of Nursing', dependencyValue: 'OH' },
  { label: 'Idaho Board of Nursing', dependencyValue: 'ID' },
  { label: 'Texas Board of Nursing', dependencyValue: 'TX' },
  {
    label: 'Oklahoma State Board Of Behavioral Health Licensure',
    dependencyValue: 'OK',
  },
  {
    label: 'New York State Board for Mental Health Practitioners',
    dependencyValue: 'NY',
  },
  {
    label: 'The Kansas State Board Of Professional Counselor Examiners',
    dependencyValue: 'KS',
  },
  {
    label:
      'State Board Of Marriage And Family Therapy Examiners: Professional Counselor Examiners Committee',
    dependencyValue: 'NJ',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'VA' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'FL' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'MI' },
  { label: 'Massachusetts Board Of Behavior Analyst', dependencyValue: 'MA' },
  {
    label: 'The Kansas State Board Of Behavior Analyst Examiners',
    dependencyValue: 'KS',
  },
  {
    label:
      'Washington State Department Of Health Applied Behavior Analysis Advisory Committee',
    dependencyValue: 'WA',
  },
  {
    label: 'South Dakota Board Of Examiners Of Alcohol & Drug Counselor',
    dependencyValue: 'SD',
  },
  { label: 'BOARD OF DENTAL EXAMINERS ALASKA', dependencyValue: 'AK' },
  { label: 'Michigan Board Of Social Workers', dependencyValue: 'MI' },
  { label: 'Tennessee Board Of Social Workers', dependencyValue: 'TN' },
  {
    label: 'The Nebraska State Board Of Social Work Examiners',
    dependencyValue: 'NE',
  },
  {
    label: 'The Virginia State Board Of Social Work Examiners',
    dependencyValue: 'VA',
  },
  {
    label: 'New Jersey’s State Board of Social Work Examiners',
    dependencyValue: 'NJ',
  },
  { label: 'New York State Board for Dentistry', dependencyValue: 'NY' },
  { label: 'Texas State Board of Dental Examiners', dependencyValue: 'TX' },
  {
    label: 'Michigan Board Of Marriage And Family Therapy',
    dependencyValue: 'MI',
  },
  { label: 'Alaska Board Of Marital & Family Therapy', dependencyValue: 'AK' },
  {
    label: 'New Jersey State Board Of Marriage And Family Therapy Examiners',
    dependencyValue: 'NJ',
  },
  {
    label: 'Oregon Board of Licensed Professional Counselors and Therapists',
    dependencyValue: 'OR',
  },
  {
    label: 'Ohio Counselor Social Worker & Marriage and Family Therapist Board',
    dependencyValue: 'OH',
  },
  { label: 'Oregon Board of Psychology', dependencyValue: 'OR' },
  {
    label: 'South Carolina Board of Examiners in Psychology',
    dependencyValue: 'SC',
  },
  { label: 'Colorado Medical Board', dependencyValue: 'CO' },
  { label: 'Florida Board of Osteopathic Medicine', dependencyValue: 'FL' },
  { label: 'District of Columbia Board of Medicine', dependencyValue: 'DC' },
  {
    label: 'Louisiana State Board of Medical Examiners',
    dependencyValue: 'LA',
  },
  { label: 'Florida Board Of Psychology', dependencyValue: 'FL' },
  { label: 'Arkansas Board Of Psychologist', dependencyValue: 'AR' },
  { label: 'State Board Of Psychological Examiners', dependencyValue: 'NJ' },
  { label: 'Kentucky Board of Examiners of Psychology', dependencyValue: 'KY' },
  {
    label: 'The New Mexico State Board Of Psychologist Examiners',
    dependencyValue: 'NM',
  },
  {
    label: 'Pennsylvania State Board of Osteopathic Medicine',
    dependencyValue: 'PA',
  },
  {
    label: 'Utah Osteopathic Physician and Surgeons Licensing Board',
    dependencyValue: 'UT',
  },
  { label: 'Washington Midwifery Advisory Committee', dependencyValue: 'WA' },
  { label: 'Alabama Board of Nursing', dependencyValue: 'AL' },
  { label: 'Colorado Board of Nursing', dependencyValue: 'CO' },
  { label: 'Kentucky Board of Nursing', dependencyValue: 'KY' },
  { label: 'New Mexico Board of Nursing', dependencyValue: 'NM' },
  { label: 'Maine State Board of Nursing', dependencyValue: 'ME' },
  { label: 'New York State Board of Nursing', dependencyValue: 'NY' },
  { label: 'District of Columbia Board of Nursing', dependencyValue: 'DC' },
  { label: 'Alaska Board Of Professional Counselors', dependencyValue: 'AK' },
  { label: 'Alabama Board of Examiners in Counseling', dependencyValue: 'AL' },
  {
    label: 'Illinois Professional Counselor Licensing And Disciplinary Board',
    dependencyValue: 'IL',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'OH' },
  {
    label: 'New York State Education Department, Office of the Professions',
    dependencyValue: 'NY',
  },
  {
    label: 'The Nebraska State Board Of Addiction Counselor Examiners',
    dependencyValue: 'NE',
  },
  {
    label:
      'New Jersey State Board Of Marriage And Family Therapy Examiners: The Alcohol And Drug Counselor Committee',
    dependencyValue: 'NJ',
  },
  { label: 'New Hampshire Board of Dental Examiners', dependencyValue: 'NH' },
  { label: 'Maryland Board of Social Work', dependencyValue: 'MD' },
  {
    label: 'Alabama State Board of Social Work Examiners',
    dependencyValue: 'AL',
  },
  {
    label: 'The Utah State Board Of Social Work Examiners',
    dependencyValue: 'UT',
  },
  { label: 'Missouri Dental Board', dependencyValue: 'MO' },
  { label: 'Florida Board of Dentistry', dependencyValue: 'FL' },
  { label: 'Tennessee Board of Dentistry', dependencyValue: 'TN' },
  { label: 'Nevada State Board of Dental Examiners', dependencyValue: 'NV' },
  { label: 'Rhode Island Board of Nurse Registration', dependencyValue: 'RI' },
  {
    label: 'District of Columbia Board of Marriage and Family Therapist',
    dependencyValue: 'DC',
  },
  {
    label: 'Kentucky Board of Licensure for Marriage and Family Therapists',
    dependencyValue: 'KY',
  },
  {
    label:
      'The Colorado State Board Of Marriage And Family Therapist Examiners',
    dependencyValue: 'CO',
  },
  {
    label: "Massachusetts Bureau of Substance Addiction Services' (BSAS)",
    dependencyValue: 'MA',
  },
  {
    label:
      'New Jersey State Board Of Marriage And Family Therapy Examiners: Professional Counselor Examiners Committee',
    dependencyValue: 'NJ',
  },
  {
    label: 'Maryland State Board Of Examiners Of Psychologists',
    dependencyValue: 'MD',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'AZ' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'OK' },
  {
    label: 'Lousiana Addictive Disorder Regulatory Authority',
    dependencyValue: 'LA',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'RI' },
  {
    label: 'State of Nevada Board of Examiners for Social Workers',
    dependencyValue: 'NV',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'DC' },
  { label: 'West Virginia Board of Osteopathy', dependencyValue: 'WV' },
  { label: 'Connecticut Medical Examining Board', dependencyValue: 'CT' },
  { label: 'Minnesota Board of Medical Practice', dependencyValue: 'MN' },
  {
    label: 'Missouri Board of Registration for the Healing Arts',
    dependencyValue: 'MO',
  },
  { label: 'Georgia Board Of Psychology', dependencyValue: 'GA' },
  { label: 'Montana Board of Psychologists', dependencyValue: 'MT' },
  { label: 'New Hampshire Board of Psychologists', dependencyValue: 'NH' },
  { label: 'Idaho Board of Psychologist Examiners', dependencyValue: 'ID' },
  { label: 'California Board Of Behavioral Sciences', dependencyValue: 'CA' },
  {
    label: 'New Jersey State Board Of Psychological Examiners',
    dependencyValue: 'NJ',
  },
  {
    label: 'North Dakota State Board of Psychologist Examiners',
    dependencyValue: 'ND',
  },
  {
    label: 'The Nebraska State Board Of Psychologist Examiners',
    dependencyValue: 'NE',
  },
  { label: 'Idaho State Board of Medicine', dependencyValue: 'ID' },
  { label: 'Guam Board of Medical Examiners', dependencyValue: 'GU' },
  { label: 'Maine Board of Osteopathic Licensure', dependencyValue: 'ME' },
  { label: 'Nevada State Board of Medical Examiners', dependencyValue: 'NV' },
  {
    label: 'Commonwealth of the Northern Mariana Islands',
    dependencyValue: 'MP',
  },
  { label: 'Virginia Board of Nursing', dependencyValue: 'VA' },
  { label: 'Minnesota Board of Nursing', dependencyValue: 'MN' },
  { label: 'South Dakota Board of Nursing', dependencyValue: 'SD' },
  {
    label: 'Massachusetts Board of Registration in Nursing',
    dependencyValue: 'MA',
  },
  {
    label: 'State Of Connecticut Board Of Professional Counselor',
    dependencyValue: 'CT',
  },
  {
    label:
      'Tennessee Board For Licensed Professional Counselors, Licensed Marital And Family Therapists And Licensed Clinical Pastoral Therapists',
    dependencyValue: 'TN',
  },
  {
    label: 'The Missouri Behavior Analysts Advisory Board',
    dependencyValue: 'MO',
  },
  {
    label: 'State Of Connecticut Board Of Behavior Analyst',
    dependencyValue: 'CT',
  },
  {
    label: 'North Dakota State Board of Counselor Examiners',
    dependencyValue: 'ND',
  },
  {
    label: 'State Of Connecticut Board Of Alcohol And Drug Counselor',
    dependencyValue: 'CT',
  },
  {
    label: 'Rhode Island Board of Examiners in Dentistry',
    dependencyValue: 'RI',
  },
  { label: 'Board Of Social Workers', dependencyValue: 'TN' },
  { label: 'Iowa Board Of Social Work', dependencyValue: 'IA' },
  { label: 'Idaho Board of Social Work Examiners', dependencyValue: 'ID' },
  { label: 'Alaska Board Of Social Work Examiners', dependencyValue: 'AK' },
  {
    label: 'Illinois Social Work Examining And Disciplinary Board',
    dependencyValue: 'IL',
  },
  { label: 'The Oregon Board of Dentistry', dependencyValue: 'OR' },
  { label: 'Vermont Board of Dental Examiners', dependencyValue: 'VT' },
  { label: 'Wisconsin Dentistry Examining Board', dependencyValue: 'WI' },
  { label: 'District of Columbia Board of Dentistry', dependencyValue: 'DC' },
  {
    label: 'Oklahoma State Board Of Licensed Social Workers',
    dependencyValue: 'OK',
  },
  { label: 'Utah Physician Assistant Licensing Board', dependencyValue: 'UT' },
  { label: 'Connecticut Department of Public Health', dependencyValue: 'CT' },
  {
    label: 'New York State Education Department Office of the Professions',
    dependencyValue: 'NY',
  },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'WA' },
  { label: 'Behavior Analyst Certification Board', dependencyValue: 'IA' },
  { label: 'State Board of Psychological Examiners', dependencyValue: 'NJ' },
  {
    label:
      'Maryland Department Of Health State Board Of Professional Counselors & Therapists',
    dependencyValue: 'MD',
  },
  { label: 'Hawaii Board of Osteopathic Examiners', dependencyValue: 'HI' },
  {
    label: 'Alabama Board Of Examiners In Marriage And Family Therapy',
    dependencyValue: 'AL',
  },
  { label: 'Wyoming Board of Psychology', dependencyValue: 'WY' },
  {
    label:
      'Alaska Division of Corporations, Business and Professional Licensing',
    dependencyValue: 'AK',
  },
];
export const contractEntityTypes = ['C001', 'C002', 'C003', 'C004', 'C005'];
export const npiStatuses = ['Active', 'Inactive'];
export const MAX_NPI_COUNT = 30;
export const MAX_EXTERNAL_ID_COUNT = 30;
export const allOrgsValue = 'allOrgs';
export const allOrgs = {
  key: allOrgsValue,
  value: 'All My Orgs',
};

export const payorNames = [
  'United Healthcare',
  'Aetna',
  'Humana',
  'Cigna',
  'Blue Cross Blue Shield',
  'Anthem',
  'CVS Health',
  'Care Source',
  'Centene',
];
export const lobs = ['Medicare', 'Medicaid', 'Commercial', 'ISNP'];
export const enrollmentPlans = [
  'Platinum',
  'Gold',
  'Silver',
  'Bronze',
  'All plans',
];
export const stateCodes = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM', //Federated States of Micronesia
  'GA',
  'GU', //Guam
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH', //  Marshall Islands

  'MI',
  'MN',
  'MO',
  'MP', // Northern Mariana Islands
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'PW', //Palau
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'UM', //U.S. Minor Outlying Islands
  'VA',
  'VI', //U.S. Virgin Islands
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];
export const jurisdictions = stateCodes;
export const DurationTypes = ['Time-Limited', 'Lifetime', 'MOC'];

export const FacilityType = [
  'Adult Family Care Providers',
  'Adult Medical Day Care',
  'Ambulance/Medical Transport Providers',
  'Ambulatory Behavioral Health Clinics',
  'Ambulatory Surgery Centers',
  'Ambulatory Surgical Centers',
  'Assisted Living Program',
  'Assisted Living Residence',
  'Assisted Living Service Agencies',
  'Behavioral Health Facilities (Inpatient, Outpatient, Residential, Ambulatory)',
  'Birthing Centers',
  'Cancer Treatment Center',
  'Children’s Hospital',
  'Chore Services',
  'Chronic & Convalescent Nursing Home & Rest Homes with Nursing Supervision',
  'Chronic Disease Hospitals',
  'Clinical Laboratories',
  'Community Residential Homes',
  'Companion Care Provider',
  'Comprehensive Outpatient Rehabilitation Facilities',
  'Comprehensive Personal Care Home Service Providers',
  'Diabetes Education Centers',
  'Diabetes Supply Centers',
  'Dialysis Centers',
  'Durable Medical Equipment Providers',
  'End of Life/Terminal Care Facilities: Hospice',
  'Facilities for the Care or Treatment of Substance Abusive Persons',
  'Family Planning',
  'Federally Qualified Health Center',
  'Federally Qualified Health Center (FQHC)',
  'General Hospitals',
  'Healthcare Services Firms',
  'Hearing Centers',
  'Hemodialysis',
  'Home Delivered Meals',
  'Home Health Agencies',
  'Home Health Care',
  'Home Health/Private Duty Nursing Providers',
  'Home Infusion',
  'Home Infusion Services',
  'Home Medical Supplies / Durable Medical Equipment (DME)',
  'Homemaker Home Health Aide Agency',
  'Hospital for Mentally Ill Persons',
  'Hospitals',
  "Hospitals, Children's",
  'Hospitals, Critical Access',
  'Hospitals, Long Term Acute Care (LTAC)',
  'Hospitals, Psychiatric',
  'Hospitals, Rehabilitation',
  'Independent Diagnostic Testing Facilities',
  'Independent Diagnostic Testing Facility (IDTF)',
  'Infusion Suites',
  'Inpatient Acute Rehabilitation Facilities',
  'Intensive Outpatient Program (IOP)',
  'Laboratory / Pathology',
  'Mammography Screening Centers',
  'Maternity Hospital',
  'Medical Detox',
  'Medication Dispensing Device Service Providers',
  'Mental Health Community Residence',
  'Mental Health Day Treatment',
  'Mental Health Facility',
  'Mental Health Residential Living Centers',
  'Mobile Crisis Outreach',
  'Non-Medical Transport Providers',
  'Non-Traditional Service Providers',
  'Nursing Home Management Companies',
  'Outpatient Clinics',
  'Outpatient Rehabilitation Providers',
  'Partial Hospitalization/Day Treatment',
  'Pediatric Medical Day Care Providers',
  'Personal Care Assistant Providers',
  'Personal Emergency Response Services',
  'Personal/Home Care Agencies',
  'Physiological Laboratories',
  'Prosthetics and Orthotics',
  'Prosthetics and Orthotics',
  'Psychiatric Outpatient Clinic for Adults',
  'Public Health Centers / Clinics',
  'Radiology / Imaging Centers',
  'Radiology/Imaging Centers including Mobile X-ray/Imaging Service Providers',
  'Receiving Centers',
  'Recovery Residence',
  'Residential Care Homes',
  'Residential Support',
  'Residential Treatment Center',
  'Rest Home with Nursing Supervision',
  'Retail Health Clinics',
  'Skilled Nursing Facilities',
  'Skilled Nursing/Sub-acute/Long Term Care Facility Providers',
  'Sleep Disorder Laboratories',
  'Sleep Study Labs',
  'Social Adult Day Care Providers',
  'Social Detox',
  'Specialty Pharmacy Providers',
  'Temporary Help Agencies',
  'Urgent Care Centers',
  'Vaccine Network for Pharmacies',
];

export const stateLicenseStatuses = [
  'Active',
  'Inactive',
  'Expired',
  'Retired',
  'Revoked',
  'Lapsed',
  'Probation',
  'Surrendered',
  'Suspended',
];
export const deaActivities = ['Active', 'Inactive'];
export const boardCertMocOptions = ['Yes', 'No', 'Not Required'];
export const boardCertStatusOptions = ['Certified', 'Expired', 'Active'];
export const npdbSources = ['National Practitioner Data Bank'];
export const natProviderDatabankActionTypes = [
  'No Action',
  'Medical Malpractice Payment',
  'Health Plan',
  'State Licensure/Certification',
  'Professional Society',
  'Exclusion/Debarment',
  'DEA/Federal Licensure',
  'Government Administrative',
  'Judgement/Conviction',
  'Clinical Privileges',
  'Peer Review Organization',
];
export const npdbReportTypes = [
  'Adverse Action Report',
  'Medical Malpractice Payment Report',
  'No Report Found',
];
export const sanctionTypes = [
  'OIG',
  'GSA/SAM/OFAC',
  'OFAC',
  'MOO',
  'State Exclusion List',
  'Preclusion List Check',
];
export const sanctionActionTypes = ['No Match Found', 'Match Found'];
export const malpracticeInsuranceSources = [
  'Copy of Insurance',
  'Application',
  'N/A',
];
export const malpracticeInsuranceStatuses = ['active', 'expired'];
export const appVerificationTypes = [
  'Work History',
  'Disclosure Questions',
  'Hospital Admitting Privileges',
  'Deceased Check',
  'Peer Reference',
  'ID Verification',
  'Scope of Practice/Privileges',
  'Clinical Quality',
  'Hospital Admitting Arrangements',
  'Hospital Non-Admitting Privileges',
  'Background Check',
  '805 Report (CA)',
  'PPS Pupil Personnel Services (CA)',
  'RPD Restricted Provider Database (CA)',
  'Medicaid ID',
  'Medicare ID',
  'Medi-Cal Exclusion',
  'Medi-Cal Participation',
  'Supervisory Agreement',
];

export const appVerificationTypesRdb = [
  'WORK_HISTORY',
  'DISCLOSURE_QUESTIONS',
  'DECEASED_CHECK',
];
export const appVerificationSources = [
  'Verified through Provider Application',
  'SSA Death Master File',
  'Primary Source',
];

export const appVerificationExplanations = [
  'Match Found',
  'Disclosure question answered favourably',
  'Disclosure question answered unfavourably',
  'Employment gap found with explanation',
  'No employment gap found',
  'No SSN Provided for Match',
  'No Match Found',
  'Present',
  'Not Required in State of Interest',
];

export const perfIndicatorComplaintOptions = ['Yes', 'No'];
export const hospitalAffiliationSources = [
  'Verified through Licensing Board',
  'Verified through Board Certification',
  'Verified by Provider Application',
  'Verified by Hospital Institution',
  'Verified through the American Medical Association (AMA)',
  'Verified by Other',
];
export const hospitalAffiliationSpecialties = [
  'Acute Care',
  'Adult Nurse Practitioner',
  'Adult Psychiatric-Mental Health',
  'Adult-Gerontology Acute Care',
  'Adult-Gerontology Primary Care',
  'Advanced Diabetes Management',
  'Aerospace Medicine',
  'Allergy and Immunology',
  'Anatomic Pathology and Clinical Pathology',
  'Anesthesiology',
  'Assistant Behavior Analyst',
  'Audiology',
  'Behavioral Analyst',
  'Board Certified Behavior Analyst - Doctoral',
  'Cardiac Surgery',
  'Cardiology',
  'Cardiothoracic Surgery',
  'Cataract Surgery',
  'Certified Registered Nurse Anesthetist',
  'Chiropractor',
  'Clinical Biochemical Genetics',
  'Clinical Cytogenetics',
  'Clinical Genetics (MD)',
  'Clinical Genetics and Genomics (MD)',
  'Clinical Mental Health Counselor',
  'Clinical Molecular Genetics',
  'Clinical Neurophysiology',
  'Clinical Nurse Specialist',
  'Clinical Psychology',
  'Clinical Social Work',
  'Colon and Rectal Surgery',
  'Community Behavioral Health Clinician',
  'Dental',
  'Dermatology',
  'Diagnostic Radiology',
  'Emergency Medicine',
  'Emergency Nurse Practitioner',
  'Endocrinology',
  'ENT/Otolaryngology',
  'Family Medicine',
  'Family Nurse Practitioner',
  'Family Practice',
  'Foot and Ankle Surgery',
  'Gastroenterology',
  'General Practice',
  'General Surgery',
  'Geriatrics',
  'Gerontological',
  'Gerontology',
  'Gynecology',
  'Infectious Diseases',
  'Internal Medicine',
  'Interventional Radiology and Diagnostic Radiology',
  'Laboratory Genetics and Genomics',
  'Medical Physics (Diagnostic, Nuclear, Therapeutic)',
  'Nephrology',
  'Neurological Surgery',
  'Neurology',
  'Neurology with Special Qualification in Child Neurology',
  'No Speciality',
  'Nuclear Medicine',
  'Nurse Practitioner',
  'Obesity Medicine',
  'Obstetrics & Gynecology',
  'Occupational Medicine',
  'Occupational Therapist',
  'Oncology - Medical',
  'Oncology - Medical, Surgical',
  'Oncology - Radiation/Radiation Oncology',
  'Oncology - Surgical',
  'Ophthalmology',
  'Optometry',
  'Oral and Maxillofacial Surgery',
  'Orthopaedic Surgery',
  'Otolaryngology',
  'Otolaryngology - Head and Neck Surgery',
  'Pathology - Anatomic',
  'Pathology - Anatomic/Pathology - Clinical',
  'Pathology - Clinical',
  'Pediatric Nurse Practitioner - Primary Care',
  'Pediatric Primary Care',
  'Pediatrics',
  'Pediatrics: Developmental-Behavioral Pediatrics',
  'Physical Medicine and Rehabilitation',
  'Physician Assistant',
  'Plastic Surgery',
  'Podiatry',
  'Primary Care',
  'Primary Care - Nurse Practitioners',
  'Primary Care - Physician Assistants',
  'Psychiatric Mental Health Nurse Practitioner',
  'Psychiatric-Mental Health',
  'Psychiatry',
  'Psychiatry & Neurology',
  'Psychiatry: Child and Adolescent Psychiatry',
  'Psychiatry: Geriatric Psychiatry',
  'Psychology',
  'Psychotherapy',
  'Public Health and General Preventive Medicine',
  'Pulmonology',
  'Radiation Oncology',
  'Radiologic Physics',
  'Radiology',
  'Registered Behavior Technician',
  'Rheumatology',
  'School Nurse Practitioner',
  'Speech-Language Pathology',
  'Surgery',
  'Therapeutic Radiology',
  'Thoracic and Cardiac Surgery',
  'Thoracic Surgery',
  'Urgent Care Medicine',
  'Urology',
  'Vascular Surgery',
];
export const hospitalAffiliationTypes = [
  'Hospital Privileges',
  'Hospital Admitting Arrangements',
  'Non-hospital Admitting Arrangements',
];
export const educationTrainingSources = [
  'Verified through Licensing Board',
  'Verified through Board Certification',
  'Verified by Provider Application',
  'Verified by Educational Institution',
  'Verified through the American Medical Association (AMA)',
  'Verified by Other',
];
export const educationTrainingSpecialty = [
  'Obstetrics & Gynecology, Reproductive Endocrinology',
  'Psychiatry',
];
export const educationTrainingType = [
  'Fellowship',
  'Residency',
  'Professional',
];
export const educationDegreeOptions = [
  { label: 'Bachelor of Arts (BA)', value: 'BA' },
  { label: 'Bachelor of Accountancy (BAcc)', value: 'BAcc' },
  { label: 'Bachelor of Architecture (BArch)', value: 'BArch' },
  { label: 'Bachelor of Business Administration (BBA)', value: 'BBA' },
  { label: 'Bachelor of Commerce (BComm)', value: 'BComm' },
  { label: 'Bachelor of Computer Science (BCS)', value: 'BCS' },
  { label: 'Bachelor of Computer Application (BCA)', value: 'BCA' },
  { label: 'Bachelor of Civil Law (BCL)', value: 'BCL' },
  { label: 'Bachelor of Divinity (BDiv)', value: 'BDiv' },
  { label: 'Bachelor of Economics (BEc)', value: 'BEC' },
  { label: 'Bachelor of Education (BEd)', value: 'BED' },
  { label: 'Bachelor of Engineering (BEng)', value: 'BENG' },
  { label: 'Bachelor of Fine Arts (BFA)', value: 'BFA' },
  { label: 'Bachelor of Laws (LLB)', value: 'LLB' },
  { label: 'Bachelor of Letters (BLitt)', value: 'BLitt' },
  { label: 'Bachelor of Music (BM)', value: 'BM' },
  { label: 'Bachelor of Management Studies (BMS)', value: 'BMS' },
  { label: 'Bachelor of Pharmacy (BPharm)', value: 'BPharm' },
  { label: 'Bachelor of Philosophy (BPhil)', value: 'BPhil' },
  { label: 'Bachelor of Science (BS)', value: 'BS' },
  { label: 'Bachelor of Science in Nursing (BSN)', value: 'BSN' },
  { label: 'Bachelor of Social Work (BSW)', value: 'BSW' },
  { label: 'Bachelor of Technology (BTech)', value: 'BTech' },
  { label: 'Bachelor of Theology (BTh)', value: 'BTh' },
  { label: 'Bachelor of Medicine, Bachelor of Surgery (MBBS)', value: 'MBBS' },
  { label: 'Bachelor of Civil Law (BCL)', value: 'BCL' },
  { label: 'Licentiate in Sacred Theology (STL)', value: 'STL' },
  { label: 'Magister Juris (MJur)', value: 'MJur' },
  { label: 'Master of Business Administration (MBA)', value: 'MBA' },
  { label: 'Master of Counselling (MCouns)', value: 'MCouns' },
  { label: 'Master of Divinity (MDiv)', value: 'MDiv' },
  { label: 'Master of Education (MEd)', value: 'MED' },
  { label: 'Master of Engineering (MEng)', value: 'MENG' },
  { label: 'Master of Fine Arts (MFA)', value: 'MFA' },
  { label: 'Master of Laws (LLM)', value: 'LLM' },
  { label: 'Master of Letters (MLitt)', value: 'MLitt' },
  { label: 'Master of Medicine (MMed)', value: 'MMED' },
  { label: 'Master of Management Studies (MMS)', value: 'MMS' },
  { label: 'Master of Philosophy (MPhil)', value: 'MPhil' },
  { label: 'Master of Public Administration (MPA)', value: 'MPA' },
  { label: 'Master of Public Health (MPH)', value: 'MPH' },
  { label: 'Master of Research (MRes)', value: 'MRES' },
  { label: 'Master of Sacred Theology (STM)', value: 'STM' },
  { label: 'Master of Science (MSc)', value: 'MSc' },
  { label: 'Master of Science in Nursing (MSN)', value: 'MSN' },
  { label: 'Master of Social Work (MSW)', value: 'MSW' },
  { label: 'Master of Studies (MSt)', value: 'MSS' },
  { label: 'Master of Surgery (ChM or MS)', value: 'MS' },
  { label: 'Master of Technology (Mtech)', value: 'Mtech' },
  { label: 'Master of Art (MA)', value: 'MA' },
  { label: 'Master in Physician Assistant Studies (MSPA)', value: 'MSPA' },
  { label: 'Professional Science Masters (PSM)', value: 'PSM' },
  { label: 'Doctor of Arts (DA)', value: 'DA' },
  { label: 'Doctor of Audiology (AuD)', value: 'AUD' },
  { label: 'Doctor of Business Administration (DBA)', value: 'DBA' },
  { label: 'Doctor of Canon Law (JCD)', value: 'JCD' },
  { label: 'Doctor of Civil Law (DCL)', value: 'DCL' },
  { label: 'Doctor of Clinical Psychology (DClinPsy)', value: 'DClinPsy' },
  { label: 'Doctor of Chiropractic (DC)', value: 'DC' },
  { label: 'Doctor of Dental Surgery (DDS)', value: 'DDS' },
  { label: 'Doctor of Divinity (DDiv)', value: 'DDiv' },
  { label: 'Doctor of Education (EdD)', value: 'EDD' },
  { label: 'Doctor of Juridical Science (JSD)', value: 'JSD' },
  { label: 'Doctor of Letters (DLitt)', value: 'DLitt' },
  { label: 'Doctor of Medicine (MD)', value: 'MD' },
  { label: 'Doctor of Ministry (DMin)', value: 'DMin' },
  { label: 'Doctor of Naturopathic Medicine (ND)', value: 'ND' },
  { label: 'Doctor of Osteopathic Medicine (DO)', value: 'DO' },
  { label: 'Doctor of Pharmacy (DPharm)', value: 'PHARMD' },
  { label: 'Doctor of Philosophy (PhD)', value: 'PHD' },
  { label: 'Doctor of Psychology (PsyD)', value: 'PSYD' },
  { label: 'Doctor of Science (DSc or ScD)', value: 'SCD' },
  { label: 'Doctor of Theology (ThD)', value: 'THD' },
  { label: 'Doctor of Veterinary Medicine (DVM)', value: 'DVM' },
  { label: 'Education Specialist degree (EdS)', value: 'EdS' },
  { label: 'Juris Doctor (JD)', value: 'JD' },
  { label: 'Other Degree', value: 'Other Degree' },
];
export const boardCertificationSources = [
  'American Academy of Nurse Practitioners',
  'American Association of Critical Nurse Care',
  'American Board of Allergy & Immunology',
  'American Board of Anesthesiology',
  'American Board of Clinical Neuropsychology',
  'American Board of Clinical Psychology',
  'American Board of Clinical Social Work',
  'American Board of Colon & Rectal Surgery',
  'American Board of Dermatology',
  'American Board of Emergency Medicine',
  'American Board of Family Medicine',
  'American Board of Foot and Ankle Surgery',
  'American Board of Internal Medicine',
  'American Board of Lower Extremity Surgery',
  'American Board of Medical Genetics and Genomics',
  'American Board of Neurological Surgery',
  'American Board of Nuclear Medicine',
  'American Board of Obesity Medicine',
  'American Board of Obstetrics & Gynecology',
  'American Board of Ophthalmology',
  'American Board of Oral & Maxillofacial Surgery',
  'American Board of Orthopaedic Surgery',
  'American Board of Otolaryngology - Head and Neck Surgery',
  'American Board of Pathology',
  'American Board of Pediatrics',
  'American Board of Physical Medicine & Rehabilitation',
  'American Board of Physician Specialties',
  'American Board of Plastic Surgery',
  'American Board of Podiatric Medicine',
  'American Board of Preventive Medicine',
  'American Board of Professional Psychology',
  'American Board of Psychiatry & Neurology',
  'American Board of Radiology',
  'American Board of Surgery',
  'American Board of Thoracic Surgery',
  'American Board of Urgent Care Medicine',
  'American Board of Urology',
  'American College of Psychotherapy',
  'American Nurses Credentialing Center',
  'American Osteopathic Association (AOA)',
  'American Speech-Language-Hearing Association',
  'Behavioral Analyst Certification Board',
  'National Board for Certification in Occupational Therapy',
  'National Board for Certified Counselors',
  'National Board of Certification and Recertification for Nurse Anesthetists',
  'National Board of Physicians and Surgeons',
  'National Certification Corporation',
  'National Commission on Certification of Physician Assistants',
  'Other',
  'Pediatric Nursing Certification Board',
  'Substance Abuse and Mental Health Services Administration',
];
export const specialities = [
  'Acute Care',
  'Adult Nurse Practitioner',
  'Adult Psychiatric-Mental Health',
  'Adult-Gerontology Acute Care',
  'Adult-Gerontology Primary Care',
  'Advanced Diabetes Management',
  'Aerospace Medicine',
  'Allergy and Immunology',
  'Anatomic Pathology and Clinical Pathology',
  'Anesthesiology',
  'Assistant Behavior Analyst',
  'Audiology',
  'Behavioral Analyst',
  'Board Certified Behavior Analyst - Doctoral',
  'Cardiac Surgery',
  'Cardiology',
  'Cardiothoracic Surgery',
  'Cataract Surgery',
  'Certified Nurse Midwife',
  'Certified Registered Nurse Anesthetist',
  'Chiropractor',
  'Clinical Biochemical Genetics',
  'Clinical Cytogenetics',
  'Clinical Genetics (MD)',
  'Clinical Genetics and Genomics (MD)',
  'Clinical Mental Health Counselor',
  'Clinical Molecular Genetics',
  'Clinical Neurophysiology',
  'Clinical Nurse Specialist',
  'Clinical Psychology',
  'Clinical Social Work',
  'Colon and Rectal Surgery',
  'Community Behavioral Health Clinician',
  'Dental',
  'Dermatology',
  'Diagnostic Radiology',
  'Emergency Medicine',
  'Emergency Nurse Practitioner',
  'Endocrinology',
  'ENT/Otolaryngology',
  'Family Medicine',
  'Family Nurse Practitioner',
  'Family Practice',
  'Foot and Ankle Surgery',
  'Gastroenterology',
  'General Practice',
  'General Surgery',
  'Geriatrics',
  'Gerontological',
  'Gerontology',
  'Gynecology',
  'Infectious Diseases',
  'Internal Medicine',
  'Interventional Radiology and Diagnostic Radiology',
  'Laboratory Genetics and Genomics',
  'Medical Physics (Diagnostic, Nuclear, Therapeutic)',
  'Nephrology',
  'Neurological Surgery',
  'Neurology',
  'Neurology with Special Qualification in Child Neurology',
  'Nuclear Medicine',
  'Nurse Executive, Advanced',
  'Nurse Practitioner',
  'Obesity Medicine',
  'Obstetrics & Gynecology',
  'Occupational Medicine',
  'Occupational Therapist',
  'Oncology - Medical',
  'Oncology - Medical, Surgical',
  'Oncology - Radiation/Radiation Oncology',
  'Oncology - Surgical',
  'Ophthalmology',
  'Optometry',
  'Oral and Maxillofacial Surgery',
  'Orthopaedic Surgery',
  'Otolaryngology',
  'Otolaryngology and Facial Plastic Surgery',
  'Otolaryngology - Head and Neck Surgery',
  'Pathology - Anatomic',
  'Pathology - Anatomic/Pathology - Clinical',
  'Pathology - Clinical',
  'Pediatric Nurse Practitioner - Primary Care',
  'Pediatric Primary Care',
  'Pediatrics',
  'Pediatrics: Developmental-Behavioral Pediatrics',
  'Physical Medicine and Rehabilitation',
  'Physician Assistant',
  'Plastic Surgery',
  'Podiatry',
  'Preventative Medicine',
  'Primary Care',
  'Primary Care - Nurse Practitioners',
  'Primary Care - Physician Assistants',
  'Psychiatric Mental Health Nurse Practitioner',
  'Psychiatric-Mental Health',
  'Psychiatry',
  'Psychiatry & Neurology',
  'Psychiatry: Child and Adolescent Psychiatry',
  'Psychiatry: Geriatric Psychiatry',
  'Psychology',
  'Psychotherapy',
  'Public Health and General Preventive Medicine',
  'Pulmonology',
  'Radiation Oncology',
  'Radiologic Physics',
  'Radiology',
  'Registered Behavior Technician',
  'Rheumatology',
  'School Nurse Practitioner',
  'Speech-Language Pathology',
  'Surgery',
  'Therapeutic Radiology',
  'Thoracic and Cardiac Surgery',
  'Thoracic Surgery',
  'Urgent Care Medicine',
  'Urology',
  'Vascular Surgery',
  `Women's Health Nurse Practitioner`,
];
export const subSpecialities = [
  'None',
  'N/A',
  'Addiction Medicine',
  'Addiction Psychiatry',
  'Adolescent Medicine',
  'Adolescent Medicine (Family Medicine)',
  'Adolescent Medicine (Internal Medicine)',
  'Adolescent Medicine (Pediatrics)',
  'Adult Cardiac Anesthesiology',
  'Adult Congenital Heart Disease',
  'Adult and Pediatric Allergy and Immunology',
  'Advanced Heart Failure and Transplant',
  'Anesthesiology',
  'Anesthesiology Critical Care Medicine',
  'Blood Banking/Transfusion Medicine',
  'Brain Injury Medicine',
  'Cardiology',
  'Cardiovascular Disease',
  'Child Abuse Pediatrics',
  'Child and Adolescent Psychiatry',
  'Child Psychiatry',
  'Clinical Cardiac Electrophysiology',
  'Clinical Child and Adolescent',
  'Clinical Informatics',
  'Clinical Neurophysiology',
  'Clinical and Laboratory Dermatological Immunology',
  'Complex Family Planning',
  'Complex General Surgical Oncology',
  'Complex Pediatric Otolaryngology',
  'Congenital Cardiac Surgery',
  'Consultation-Liaison Psychiatry',
  'Critical Care Medicine',
  'Critical Care Medicine (Anesthesiology)',
  'Critical Care Medicine (Internal Medicine)',
  'Critical Care Medicine (OB/GYN)',
  'Cytopathology',
  'Dermatology',
  'Dermatopathology',
  'Dermatopathology (Dermatology)',
  'Dermatopathology (Pathology)',
  'Developmental-Behavioral Pediatrics',
  'Diagnostic Radiology',
  'Emergency Medical Services',
  'Emergency Medicine',
  'Endocrinology, Diabetes and Metabolism',
  'Epilepsy',
  'Family Medicine',
  'Female Pelvic Medicine and Reconstructive Surgery',
  'Focused Practice in Hospital Medicine',
  'Focused Practice in Metabolic Bariatric Surgery',
  'Focused Practice in Minimally Invasive Gynecologic Surgery',
  'Foot Surgery',
  'Forensic Psychiatry',
  'Gastroenterology',
  'Geriatric Medicine',
  'Geriatric Medicine (Family Medicine)',
  'Geriatric Medicine (Internal Medicine)',
  'Geriatric Psychiatry',
  'Gynecologic Oncology',
  'Hematology',
  'Hematopathology',
  'Hospice and Palliative Medicine',
  'Hospice and Palliative Medicine (Anesthesiology)',
  'Hospice and Palliative Medicine (Emergency Medicine)',
  'Hospice and Palliative Medicine (Family Medicine)',
  'Hospice and Palliative Medicine (Internal Medicine)',
  'Hospice and Palliative Medicine (OB/GYN)',
  'Hospice and Palliative Medicine (Pediatrics)',
  'Hospice and Palliative Medicine (Physical Medicine)',
  'Hospice and Palliative Medicine (Psychiatry and Neurology)',
  'Hospice and Palliative Medicine (Radiology)',
  'Hospice and Palliative Medicine (Surgery)',
  'Infectious Disease',
  'Internal Medicine',
  'Internal Medicine - Critical Care Medicine',
  'Interventional Cardiology',
  'Maternal and Fetal Medicine',
  'Maternal-Fetal Medicine',
  'Medical Biochemical Genetics',
  'Medical Oncology',
  'Medical Toxicology',
  'Medical Toxicology (Emergency Medicine)',
  'Medical Toxicology (Pediatrics)',
  'Medical Toxicology (Preventive Medicine)',
  'Micrographic Dermatologic Surgery',
  'Molecular Genetic Pathology',
  'Neonatal-Perinatal Medicine',
  'Nephrology',
  'Neurocritical Care',
  'Neurodevelopmental Disabilities',
  'Neurodevelopmental Disabilities (Pediatrics)',
  'Neurodevelopmental Disabilities (Psychiatry and Neurology)',
  'Neuromuscular Medicine',
  'Neuromuscular Medicine (Physical Medicine)',
  'Neuromuscular Medicine (Psychiatry and Neurology)',
  'Neuropathology',
  'Neuroradiology',
  'Neurotology',
  'No Subspecialty (Allergy and Immunology)',
  'No Subspecialty (Anesthesiology)',
  'No Subspecialty (Colon and Rectal Surgery)',
  'No Subspecialty (Dermatology)',
  'No Subspecialty (Emergency Medicine)',
  'No Subspecialty (Family Medicine)',
  'No Subspecialty (Genetics)',
  'No Subspecialty (Internal Medicine)',
  'No Subspecialty (Neurological Surgery)',
  'No Subspecialty (Nuclear Medicine)',
  'No Subspecialty (OB/GYN)',
  'No Subspecialty (Ophthalmology)',
  'No Subspecialty (Orthopaedic Surgery)',
  'No Subspecialty (Otolaryngology)',
  'No Subspecialty (Pathology)',
  'No Subspecialty (Pediatrics)',
  'No Subspecialty (Physical Medicine)',
  'No Subspecialty (Plastic Surgery)',
  'No Subspecialty (Preventive Medicine)',
  'No Subspecialty (Psychiatry and Neurology)',
  'No Subspecialty (Radiology)',
  'No Subspecialty (Surgery)',
  'No Subspecialty (Thoracic Surgery)',
  'No Subspecialty (Urology)',
  'Nuclear Radiology',
  'Obstetrics & Gynecology',
  'Ophthalmology',
  'Optometry',
  'Orthopaedic Sports Medicine',
  'Osteopathic Manipulative Treatment',
  'Pain Medicine',
  'Pain Medicine (Anesthesiology)',
  'Pain Medicine (Physical Medicine)',
  'Pain Medicine (Psychiatry and Neurology)',
  'Pathology - Chemical',
  'Pathology - Forensic',
  'Pathology - Hematology',
  'Pathology - Medical Microbiology',
  'Pathology - Molecular Genetic',
  'Pathology - Pediatric',
  'Pediatric and Adolescent Gynecology',
  'Pediatric Anesthesiology',
  'Pediatric Cardiology',
  'Pediatric Critical Care Medicine',
  'Pediatric Critical Care Medicine (Pediatrics)',
  'Pediatric Dermatology',
  'Pediatric Emergency Medicine',
  'Pediatric Emergency Medicine (Emergency Medicine)',
  'Pediatric Emergency Medicine (Pediatrics)',
  'Pediatric Endocrinology',
  'Pediatric Gastroenterology',
  'Pediatric Hematology-Oncology',
  'Pediatric Hospital Medicine',
  'Pediatric Infectious Diseases',
  'Pediatric Nephrology',
  'Pediatric Otolaryngology',
  'Pediatric Pulmonology',
  'Pediatric Radiology',
  'Pediatric Rehabilitation Medicine',
  'Pediatric Rheumatology',
  'Pediatric Surgery',
  'Pediatric Transplant Hepatology',
  'Pediatric Urology',
  'Pediatrics',
  'Plastic Surgery Within the Head and Neck (Otolaryngology)',
  'Plastic Surgery Within the Head and Neck (Plastic Surgery)',
  'Podiatry',
  'Psychiatry',
  'Psychosomatic Medicine',
  'Pulmonary Disease',
  'Reconstruction Rearfoot/Ankle Surgery',
  'Reproductive Endocrinology and Infertility',
  'Reproductive Endocrinology/Infertility',
  'Rheumatology',
  'Sleep Medicine',
  'Sleep Medicine (Family Medicine)',
  'Sleep Medicine (Internal Medicine)',
  'Sleep Medicine (Otolaryngology)',
  'Sleep Medicine (Pediatrics)',
  'Sleep Medicine (Psychiatry and Neurology)',
  'Spinal Cord Injury Medicine',
  'Sports Medicine',
  'Sports Medicine (Emergency Medicine)',
  'Sports Medicine (Family Medicine)',
  'Sports Medicine (Internal Medicine)',
  'Sports Medicine (Pediatrics)',
  'Sports Medicine (Physical Medicine)',
  'Surgery of the Hand',
  'Surgery of the Hand (Orthopaedic Surgery)',
  'Surgery of the Hand (Plastic Surgery)',
  'Surgery of the Hand (Surgery)',
  'Surgical Critical Care',
  'Transplant Hepatology',
  'Undersea and Hyperbaric Medicine',
  'Undersea and Hyperbaric Medicine (Emergency Medicine)',
  'Undersea and Hyperbaric Medicine (Preventive Medicine)',
  'Vascular and Interventional Radiology',
  'Vascular Neurology',
];

// Cred Statuses
export const CredSteps = {
  NOT_SENT_TO_CREDENTIALING: 'Not sent to credentialing',
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  DATA_MISSING: 'Data missing',
  OUTREACH_IN_PROGRESS: 'Outreach in progress',
  PSV_COMPLETE_BY_CERTIFYOS: 'PSV complete by CertifyOS',
  CREDENTIALING_RESTARTED: 'Credentialing restarted',
  PSV_READY: 'PSV ready',
  CRED_APPROVED: 'Cred approved',
  HOLD_FOR_CRED_COMM: 'Hold for Cred Comm',
  CRED_DENIED: 'Cred denied',
  PROVIDER_TERMINATED: 'Provider Terminated',
  RECREDENTIALING_IN_PROGRESS: 'Recredentialing in progress',
  TABLED: 'Tabled',
  WITHDRAWN_CANCELLED: `Withdrawn/Cancelled`,
};

export const CredStepsDisplayLabel: Record<string, string> = {
  'Cred approved': 'Cred approved',
  'Not Started': 'Not Started',
  'Cred denied': 'Cred denied',
  'Credentialing restarted': 'Credentialing restarted',
  'Data missing': 'Data missing',
  'Hold for Cred Comm': 'Hold for Cred Comm',
  'In Progress': 'In Progress',
  'Not sent to credentialing': 'Not sent to credentialing',
  'Outreach in progress': 'Outreach in progress',
  'PSV complete by CertifyOS': 'PSV Complete',
  'PSV ready': 'PSV ready',
  'Provider Terminated': 'Provider Terminated',
  'Recredentialing in progress': 'Recredentialing in progress',
  Tabled: 'Tabled',
  'Withdrawn/Cancelled': 'Withdrawn/Cancelled',
};

export const ProviderStatuses = {
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  ACTIVE: 'Active',
  DENIED: 'Denied',
  TERMINATED: 'Terminated',
};

export const SUPERVISOR_ROLE = 'Supervisor';
export const VIEWER_ROLE = 'Viewer';
export const ANALYST_ROLE = 'Analyst';
export const ORGANIZATION_ADMIN_ROLE = 'Organization Admin';
export const ENTITY_MANAGER = 'Entity Manager';
export const CREDENTIALING_USER_ROLE = 'Credentialing User';
export const PLATFORM_USER_ROLE = 'Platform User';
export const MEDICAL_DIRECTOR_ROLE = 'Medical Director';
export const PROVISIONAL_APPROVER_ROLE = 'Provisional Approver';
export const BUSINESS_ADMIN_ROLE = 'Business Admin';
export const LICENSING_ADMIN_ROLE = 'Licensing Admin';
export const LICENSING_TEAM_LEAD_ROLE = 'Licensing Team Lead';
export const LICENSING_SPECIALIST_ROLE = 'Licensing Specialist';
export const LICENSING_ANALYST_ROLE = 'Licensing Analyst';
export const PE_LEAD_ROLE = 'PE Lead';
export const PE_ANALYST_ROLE = 'PE Analyst';
export const PE_CLIENT_ADMIN = 'PE Client Admin';
export const ROSTER_MANAGER_ROLE = 'Roster Manager';

export const CredentialingCycle = {
  INITIAL: 'Initial',
  RECRED: 'Recredentialing',
} as const;

export const CredentialingCycles = [
  CredentialingCycle.INITIAL,
  CredentialingCycle.RECRED,
];

export const credentialingStatuses = [
  CredSteps.NOT_SENT_TO_CREDENTIALING,
  CredSteps.NOT_STARTED,
  CredSteps.IN_PROGRESS,
  CredSteps.DATA_MISSING,
  CredSteps.OUTREACH_IN_PROGRESS,
  CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
  CredSteps.CREDENTIALING_RESTARTED,
  CredSteps.PSV_READY,
  CredSteps.CRED_APPROVED,
  CredSteps.HOLD_FOR_CRED_COMM,
  CredSteps.CRED_DENIED,
  CredSteps.PROVIDER_TERMINATED,
  CredSteps.RECREDENTIALING_IN_PROGRESS,
  CredSteps.TABLED,
  CredSteps.WITHDRAWN_CANCELLED,
];

export const CREDENTIALING_STATUS_ROLES: { [key: string]: string[] } = {
  [CREDENTIALING_USER_ROLE]: [
    CredSteps.IN_PROGRESS,
    CredSteps.RECREDENTIALING_IN_PROGRESS,
    CredSteps.DATA_MISSING,
    CredSteps.OUTREACH_IN_PROGRESS,
    CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
  ],
  [ANALYST_ROLE]: [
    CredSteps.IN_PROGRESS,
    CredSteps.RECREDENTIALING_IN_PROGRESS,
    CredSteps.DATA_MISSING,
    CredSteps.OUTREACH_IN_PROGRESS,
    CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
  ],
  [SUPERVISOR_ROLE]: [
    CredSteps.IN_PROGRESS,
    CredSteps.RECREDENTIALING_IN_PROGRESS,
    CredSteps.DATA_MISSING,
    CredSteps.OUTREACH_IN_PROGRESS,
    CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
    CredSteps.PSV_READY,
  ],
  [PROVISIONAL_APPROVER_ROLE]: [
    CredSteps.PSV_READY,
    CredSteps.HOLD_FOR_CRED_COMM,
    CredSteps.TABLED,
  ],
  [MEDICAL_DIRECTOR_ROLE]: [
    CredSteps.PSV_READY,
    CredSteps.HOLD_FOR_CRED_COMM,
    CredSteps.TABLED,
  ],
};

export const MONITORING_STATUS = {
  IN_PROGRESS: 'In Progress',
  DATA_MISSING: 'Data Missing',
  OUTREACH_IN_PROGRESS: 'Outreach in Progress',
  REVIEWED: 'Reviewed by CertifyOS',
} as const;

export type MONITORING_STATUS_TITLE =
  (typeof MONITORING_STATUS)[keyof typeof MONITORING_STATUS];
export const monitoringStatuses = [
  'In Progress',
  'Data Missing',
  'Outreach in Progress',
  'Reviewed by CertifyOS',
];
export const monitoringStatusesApiPath: Record<string, string> = {
  'In Progress': 'move-to-in-progress',
  'Data Missing': 'move-to-data-missing',
  'Outreach in Progress': 'move-to-outreach-in-progress',
  'Reviewed by CertifyOS': 'move-to-certifyos-review-complete',
};

export const monitoringFlags = [
  { label: 'Expiring soon', id: 'EXPIRING_SOON' },
  { label: 'Expired', id: 'EXPIRED' },
  { label: 'Needs review', id: 'NEEDS_REVIEW' },
];

export const monitoringFlagType = {
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  EXPIRED: 'EXPIRED',
  MATCH_FOUND: 'MATCH_FOUND',
} as const;

export const monitoringFlagsSources = [
  { label: 'Sanctions', id: 'SANCTIONS' },
  { label: 'Board Certifications', id: 'BOARD_CERTIFICATIONS' },
  { label: 'Dea Data', id: 'DEA_DATA' },
  { label: 'Licensure Actions', id: 'LICENSURE_ACTIONS' },
  { label: 'State Licenses', id: 'STATE_LICENSES' },
  { label: 'NPPES', id: 'NPPES' },
];

export const patientDeceasedOptions = [
  {
    label: 'Yes',
    value: 'Y',
  },
  {
    label: 'No',
    value: 'N',
  },
  {
    label: 'Unknown',
    value: 'U',
  },
];

export const DEA_DATA_TYPES = ['DEA', 'CDS'];

export const providerDataRdbCards = {
  OVERVIEW: 'overview',
  STATE_LICENSES: 'stateLicenses',
  DEA: 'dea',
  BOARD_CERTIFICATIONS: 'boardCertifications',
  NATIONAL_PRACTITIONER_DATA_BANK: 'nationalPractitionerDataBank',
  LICENSURE_ACTIONS: 'licensureActions',
  SANCTIONS: 'sanctions',
  MALPRACTICE_INSURANCE: 'malpracticeInsurance',
  PERF_INDICATOR: 'perfIndicator',
  APP_VERIFICATION: 'appVerification',
  TRAINING: 'training',
  CREDENTIALING_TIMELINE: 'credentialingTimeline',
  SUPPORTING_DOCUMENTS: 'supportingDocuments',
  ERRORS_WARNINGS: 'errorsWarnings',
  EVENTS: 'events',
};

export const AllSupportingDocumentsOfEntity = [
  ...entitySupportingDocuments,
  ...entityTaxDocuments,
].map((item) => ({ label: item.label, value: item.name }));

export const providerDataRdbCardsConfig: DataCardConfig[] = [
  {
    id: providerDataRdbCards.OVERVIEW,
    title: 'Overview',
    isEditableOnProvider: true,
    fields: {
      firstName: {
        label: 'First Name (Displayed)',
        editable: false,
      },
      middleName: {
        label: 'Middle Name',
        editable: false,
      },
      lastName: {
        label: 'Last Name (Displayed)',
        editable: false,
      },
      providerType: {
        label: 'Provider Type',
        editable: false,
        options: providerTypes,
        type: 'select',
      },
      npi: {
        label: 'NPI',
        editable: false,
      },
      caqhProviderId: {
        label: 'CAQH Provider ID',
        editable: false,
      },
      otherNames: { label: 'Other Names', editable: false },
      dateOfBirth: {
        label: 'DOB',
        type: 'date',
        utcDate: true,
        editable: true,
      },
      primaryEmail: { label: 'Primary Email', type: 'text', editable: true },
      homeAddress: {
        label: 'Residence Address',
        type: 'address',
        editable: true,
      },
      assignedStates: { label: 'Assigned States', editable: false },
    },
  },
  {
    id: providerDataRdbCards.STATE_LICENSES,
    title: 'State License',
  },
  {
    id: providerDataRdbCards.DEA,
    title: 'DEA/CDS',
  },
  {
    id: providerDataRdbCards.BOARD_CERTIFICATIONS,
    title: 'Board Certification',
  },
  {
    id: providerDataRdbCards.NATIONAL_PRACTITIONER_DATA_BANK,
    title: 'National Practitioner Data Bank',
  },
  {
    id: providerDataRdbCards.LICENSURE_ACTIONS,
    title: 'Licensure Actions',
  },
  {
    id: providerDataRdbCards.SANCTIONS,
    title: 'Sanctions',
  },
  {
    id: providerDataRdbCards.MALPRACTICE_INSURANCE,
    title: 'Malpractice Insurance',
  },
  {
    id: providerDataRdbCards.PERF_INDICATOR,
    title: 'Performance Indicator',
  },
  {
    id: providerDataRdbCards.APP_VERIFICATION,
    title: 'App Verification',
  },
  {
    id: providerDataRdbCards.TRAINING,
    title: 'Education and Training',
  },
  {
    id: providerDataRdbCards.CREDENTIALING_TIMELINE,
    title: 'Credentialing Timeline',
  },
  {
    id: providerDataRdbCards.SUPPORTING_DOCUMENTS,
    title: 'Supporting Documents',
  },
  {
    id: providerDataRdbCards.ERRORS_WARNINGS,
    title: 'Errors and Warnings',
  },
  {
    id: providerDataRdbCards.EVENTS,
    title: 'Events',
  },
];
export const providerDataCards: DataCardConfig[] = [
  {
    id: 'overview',
    title: 'Overview',
    disableCollapse: true,
    allowAddMore: false,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      firstName: {
        label: 'First Name',
        editable: false,
      },
      middleName: {
        label: 'Middle Name',
        editable: false,
      },
      lastName: {
        label: 'Last Name',
        editable: false,
      },
      dateOfBirth: { label: 'Date of Birth', type: 'date', utcDate: true },
      npi: {
        label: 'NPI',
        editable: false,
      },
      providerType: {
        label: 'Provider Type',
        type: 'select',
        editable: false,
        options: providerTypes,
      },

      providerStatus: { label: 'Provider Status', editable: false },
      fileType: { label: 'File Type', editable: false, popupInfo: true },
      caqhProviderId: {
        label: 'CAQH Provider ID',
        editable: false,
      },
      primaryEmail: {
        label: 'Primary Email',
        editable: true,
      },
      homeAddress: {
        label: 'Residence Address',
        type: 'address',
        editable: true,
      },
      lastFetchStatus: { label: 'Last Fetch', editable: false },
      credentialingStatus: {
        label: 'Credentialing Status',
        type: 'chip-select',
        editable: false,
      },
      source: { label: 'source', editable: false, visible: false },
      flags: { label: 'Flag', type: 'chip', chipStandardStyle: true },
      assignedStates: {
        label: 'States to Credential',
        type: 'select',
        options: stateCodes,
        multiple: true,
        editable: false,
        editableRoles: [SUPERVISOR_ROLE],
      },
      states: { label: 'States with License', editable: false },
      externalId: { label: 'External Id', editable: true },
    },
  },
  {
    id: 'stateLicenses',
    title: 'State License',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      state: { label: 'State', type: 'select', options: stateCodes },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      issueDate: { label: 'Issue date', type: 'date', utcDate: true },
      type: { label: 'Type', options: providerTypes, type: 'select' },
      dataLastAcquiredDate: {
        label: 'Report Date',
        type: 'date',
        utcDate: false,
        editable: true,
      },
      status: {
        label: 'Status',
        options: stateLicenseStatuses,
        type: 'select',
      },
      licenseNumber: { label: 'License number' },
      licenseType: { label: 'License type' },
      source: {
        label: 'Source',
        type: 'select',
        optionMap: stateLicensesSourcesMapByState,
        allowFreeText: true,
        dependencyFieldName: 'state',
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachmentCount: {
        label: 'Attachments',
        type: 'attachmentCount',
        editable: false,
      },
    },
  },
  {
    id: 'dea',
    title: 'DEA/CDS',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      state: { label: 'State', type: 'select', options: stateCodes },
      deaNumber: { label: 'License number' },
      type: { label: 'Type', type: 'select', options: DEA_DATA_TYPES },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      providerType: {
        label: 'Provider type',
        type: 'select',
        options: providerTypes,
      },
      status: { label: 'Status', type: 'select', options: deaActivities },
      source: { label: 'Source' },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachmentCount: {
        label: 'Attachments',
        type: 'attachmentCount',
        editable: false,
      },
    },
  },
  {
    id: 'boardCertifications',
    title: 'Board Certification',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      subSpecialty: {
        label: 'Sub specialty',
        type: 'select',
        options: subSpecialities,
      },
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: specialities,
      },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      issueDate: { label: 'Issue date', type: 'date', utcDate: true },
      durationType: {
        label: 'Duration Type',
        type: 'select',
        options: DurationTypes,
      },
      status: {
        label: 'Status',
        type: 'select',
        options: boardCertStatusOptions,
      },
      moc: { label: 'MOC', type: 'select', options: boardCertMocOptions },
      source: {
        label: 'Source',
        type: 'select',
        options: boardCertificationSources,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachmentCount: {
        label: 'Attachments',
        type: 'attachmentCount',
        editable: false,
      },
    },
  },
  {
    id: 'nationalPractitionerDataBank',
    title: 'National Practitioner Data Bank',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    allowDownload: true,
    fields: {
      typeOfAction: {
        label: 'Type Of Action',
        type: 'select',
        options: natProviderDatabankActionTypes,
        allowFreeText: true,
      },
      reportType: {
        label: 'Report Type',
        type: 'select',
        options: npdbReportTypes,
      },
      deceased: {
        label: 'Patient Deceased',
        type: 'select',
        options: patientDeceasedOptions,
        saveKey: 'reportData.deceased',
      },
      totalPaymentForThisPractitioner: {
        label: 'MMPR Payment Amount',
        type: 'text',
        saveKey: 'reportData.totalPaymentForThisPractitioner',
      },
      reportDate: { label: 'Report Date', type: 'date', utcDate: false },
      processDate: { label: 'Process Date', type: 'date', utcDate: false },
      source: { label: 'source', type: 'select', options: npdbSources },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'licensureActions',
    title: 'Licensure Actions',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      lastActionDate: {
        label: 'Last action date',
        type: 'date',
        utcDate: false,
      },
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      state: { label: 'State', type: 'select', options: stateCodes },
      details: { label: 'Details', type: 'textarea' },
      actionType: {
        label: 'Action type',
        type: 'select',
        allowFreeText: true,
        options: ['No Action', 'Action Found'],
      },
      source: { label: 'source', editable: false, visible: false },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'sanctions',
    title: 'Sanctions',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      typeOfAction: {
        label: 'Action type',
        type: 'select',
        options: sanctionActionTypes,
      },
      sanctionType: {
        label: 'Sanction type',
        type: 'select',
        options: sanctionTypes,
      },
      source: {
        label: 'source',
        type: 'select',
        options: PROVIDERS.SANCTION_SOURCE,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'malpracticeInsurance',
    title: 'Malpractice Insurance',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      policyNumber: { label: 'Policy number' },
      source: {
        label: 'source',
        type: 'select',
        options: malpracticeInsuranceSources,
      },
      status: {
        label: 'status',
        type: 'select',
        options: malpracticeInsuranceStatuses,
      },
      occurrenceCoverageAmount: {
        label: 'Occurrence Coverage Amount',
        type: 'amount',
      },
      aggregateCoverageAmount: {
        label: 'Aggregate Coverage Amount',
        type: 'amount',
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachmentCount: {
        label: 'Attachments',
        type: 'attachmentCount',
        editable: false,
      },
    },
  },
  {
    id: 'perfIndicator',
    title: 'Performance Indicator',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      complaints: { label: 'Total complaints' },
      clinical: { label: 'Clinical compliants' },
      service: { label: 'Service complaints' },
      benefits: { label: 'Benefit complaints' },
      claims: { label: 'Claim complaints' },
      accessIssues: { label: 'Access issue complaints' },
      source: { label: 'source', editable: false, visible: false },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'appVerification',
    title: 'App Verification',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      verificationType: {
        label: 'Verification Type',
        type: 'select',
        editable: true,
        allowFreeText: true,
        options: appVerificationTypes,
      },
      explanation: {
        label: 'Explanation',
        editable: true,
        visible: true,
        type: 'select',
        allowFreeText: true,
        options: appVerificationExplanations,
      },
      source: {
        label: 'source',
        editable: true,
        visible: true,
        type: 'select',
        allowFreeText: true,
        options: appVerificationSources,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'training',
    title: 'Education and Training',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      source: {
        label: 'Source',
        type: 'select',
        options: educationTrainingSources,
      },
      type: {
        label: 'Type',
        type: 'select',
        options: educationTrainingType,
      },
      degree: {
        label: 'Degree',
        type: 'select',
        options: educationDegreeOptions,
      },
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: specialities,
      },
      subSpecialty: {
        label: 'Sub specialty',
        type: 'select',
        options: subSpecialities,
      },
      institution: {
        label: 'Institution',
      },
      startDate: {
        label: 'Start Date',
        type: 'date',
        utcDate: false,
      },
      endDate: {
        label: 'End Date',
        type: 'date',
        utcDate: false,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachmentCount: {
        label: 'Attachments',
        type: 'attachmentCount',
        editable: false,
      },
    },
  },
  {
    id: 'affiliation',
    title: 'Hospital Affiliation',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      name: {
        label: 'Name',
        type: 'text',
      },
      type: {
        label: 'Type',
        type: 'select',
        options: hospitalAffiliationTypes,
      },
      source: {
        label: 'Source',
        type: 'select',
        options: hospitalAffiliationSources,
      },
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: hospitalAffiliationSpecialties,
      },
      startDate: {
        label: 'Start Date',
        type: 'date',
        utcDate: false,
      },
      endDate: {
        label: 'End Date',
        type: 'date',
        utcDate: false,
      },
      verificationUrl: {
        label: 'Verification Url',
        type: 'text',
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachmentCount: {
        label: 'Attachments',
        type: 'attachmentCount',
        editable: false,
      },
    },
  },
  {
    id: 'credentialingTimeline',
    title: 'Credentialing Timeline',
    isNotEditable: false,
    allowAddMore: false,
    isEditableOnProvider: false,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      medicalDirectorName: { label: 'Medical Director', editable: false },
      receivedForCredentialingDate: {
        label: 'Received For Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attestationDate: {
        label: 'Attestation Date',
        editable: true,
        type: 'date',
        utcDate: true,
      },
      lastCredentialedDate: {
        label: 'Last Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      psvCompleteDate: {
        label: 'PSV Completed Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      credentialingDecisionDate: {
        label: 'Credentialing Decision Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      nextCredentialingDate: {
        label: 'Next Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      verificationDate: {
        label: 'Verification Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      initialCredentialingDate: {
        label: 'Initial Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'supportingDocuments',
    title: 'Supporting Documents',
  },
  {
    id: 'errorsWarnings',
    title: 'Errors and Warnings',
  },
];

export const CREDENTIALING_TABS = {
  PROVIDER_INFORMATION: 'providerInformation',
  FLAGS: 'flags',
  OUTREACH: 'outreach',
  ERRORS_WARNINGS: 'errorsWarnings',
  NOTES: 'notes',
};

export const credentialingTabs = [
  {
    id: CREDENTIALING_TABS.PROVIDER_INFORMATION,
    title: 'Provider Information',
  },
  {
    id: CREDENTIALING_TABS.FLAGS,
    title: 'Flags',
  },
  {
    id: CREDENTIALING_TABS.OUTREACH,
    title: 'Outreach',
  },
  {
    id: CREDENTIALING_TABS.NOTES,
    title: 'Notes',
  },
  {
    id: CREDENTIALING_TABS.ERRORS_WARNINGS,
    title: 'System Messages',
  },
];

export const PROVIDER_TABS = {
  OVERVIEW: 'overview',
  WORKFLOWS: 'workflows',
  FLAGS: 'flags',
  ORGS_ENTITIES: 'orgEntities',
};

export const providerTabs = [
  {
    id: PROVIDER_TABS.OVERVIEW,
    title: 'Overview',
  },
  {
    id: PROVIDER_TABS.WORKFLOWS,
    title: 'Workflows',
  },
  {
    id: PROVIDER_TABS.FLAGS,
    title: 'Flags',
  },
  {
    id: PROVIDER_TABS.ORGS_ENTITIES,
    title: 'Org-Entities',
  },
];

export const LICENSING_TABS = {
  OVERVIEW: 'overview',
  OUTREACH: 'outreach',
  NOTES: 'notes',
};

export const licensingTabs = [
  {
    id: LICENSING_TABS.OVERVIEW,
    title: 'Overview',
  },
  {
    id: LICENSING_TABS.OUTREACH,
    title: 'Outreach',
  },
  {
    id: LICENSING_TABS.NOTES,
    title: 'Notes',
  },
];

export const credentialingProviderDataCards: DataCardConfig[] = [
  {
    id: 'assignment',
    title: 'Workflow Info',
    cardType: 'form',
    skipStatusCheck: true,
    fields: {
      assignedTo: { label: 'Analyst', type: 'select', options: [] },
      assignedBy: { label: 'Assigned By', editable: false },
      lastActivity: { label: 'Last Activity', editable: false },
      daysOpen: { label: 'Days Open', editable: false },
      orgEntities: { label: 'Group', editable: false, type: 'collapse-grid' },
    },
    viewableRoles: [ANALYST_ROLE, CREDENTIALING_USER_ROLE],
    editableRoles: [SUPERVISOR_ROLE],
  },
  {
    id: 'overview',
    title: 'Overview',
    cardType: 'form',
    skipStatusCheck: true,
    fields: {
      firstName: { label: 'First Name', hidden: true, editable: false },
      middleName: {
        label: 'Middle Name',
        editable: false,
      },
      lastName: { label: 'Last Name', editable: false },
      dateOfBirth: { label: 'DOB', type: 'date', utcDate: true },
      providerType: {
        label: 'Provider Type',
        type: 'select',
        options: providerTypes,
        editable: false,
      },
      fileType: {
        label: 'File Type',
        type: 'select',
        options: fileTypes,
        editable: false,
        popupInfo: true,
      },
      npi: { label: 'NPI', editable: false },
      caqhProviderId: { label: 'CAQH Provider ID', editable: false },
      primaryEmail: { label: 'CAQH Provider Email', editable: false },
      lastFetchStatus: { label: 'Last Fetch', editable: false },
      assignedStates: {
        label: 'States to Credential',
        type: 'select',
        options: stateCodes,
        multiple: true,
        editable: false,
      },
      states: {
        label: 'States with License',
        type: 'select',
        options: stateCodes,
        multiple: true,
        editable: false,
      },
      flags: {
        label: 'Flags',
        type: 'chip',
        chipColor: 'error',
        editable: false,
      },
      providerStatus: { label: 'Provider Status', editable: false },
      credentialingCycle: {
        label: 'Credentialing Cycle',
        type: 'select',
        options: [CredentialingCycle.INITIAL, CredentialingCycle.RECRED],
      },
      credentialingStatus: {
        label: 'Cred Status',
        type: 'chip-select',
        chipColor: 'secondary',
        editable: false,
      },
      totalOutreachAttempts: {
        label: 'Outreach Attempts',
        editable: false,
      },
      lastOutreachAttempt: {
        label: 'Last Outreach Attempt',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      outreachReasons: {
        label: 'Outreach Reasons',
        editable: false,
      },
      externalId: {
        label: 'External Id',
        editable: false,
      },
    },
  },
  {
    id: 'credentialingTimeline',
    title: 'Credentialing Timeline',
    cardType: 'form',
    skipStatusCheck: true,
    columnSpacing: {
      xs: 6,
      sm: 4,
      md: 3,
      lg: 3,
    },
    fields: {
      medicalDirectorName: { label: 'Medical Director', editable: false },
      receivedForCredentialingDate: {
        label: 'Received For Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attestationDate: {
        label: 'Attestation Date',
        editable: true,
        type: 'date',
        utcDate: true,
      },
      lastCredentialedDate: {
        label: 'Last Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      psvCompleteDate: {
        label: 'PSV Completed Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      credentialingDecisionDate: {
        label: 'Credentialing Decision Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      nextCredentialingDate: {
        label: 'Next Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      flag: { label: 'Flag', type: 'chip', editable: false },
      initialCredentialingDate: {
        label: 'Initial Credentialing Date',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'stateLicenses',
    title: 'State License',
    cardType: 'table',
    fields: {
      state: {
        label: 'State',
        type: 'select',
        options: stateCodes,
        showFlag: true,
      },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      issueDate: { label: 'Issue date', type: 'date', utcDate: true },
      type: { label: 'Type', options: providerTypes, type: 'select' },
      status: {
        label: 'Status',
        options: stateLicenseStatuses,
        type: 'select',
      },
      licenseNumber: { label: 'License number' },
      licenseType: { label: 'License type' },
      flag: { label: 'Flag', type: 'chip' },
      dataLastAcquiredDate: {
        label: 'Report Date',
        type: 'date',
        utcDate: false,
        editable: true,
      },
      source: {
        label: 'Source',
        type: 'select',
        optionMap: stateLicensesSourcesMapByState,
        allowFreeText: true,
        dependencyFieldName: 'state',
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      attachments: {
        label: 'Attachments',
        type: 'attachments',
        editable: false,
      },
    },
  },
  {
    id: 'dea',
    title: 'DEA/CDS',
    cardType: 'table',
    fields: {
      state: { label: 'State', type: 'select', options: stateCodes },
      deaNumber: { label: 'License number' },
      type: { label: 'Type', type: 'select', options: DEA_DATA_TYPES },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      providerType: {
        label: 'Provider type',
        type: 'select',
        options: providerTypes,
      },
      status: { label: 'Status', type: 'select', options: deaActivities },
      flag: { label: 'Flag', type: 'chip' },
      source: { label: 'Source' },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachments: {
        label: 'Attachments',
        type: 'attachments',
        editable: false,
      },
    },
  },
  {
    id: 'boardCertifications',
    title: 'Board Certification',
    cardType: 'table',
    fields: {
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: specialities,
      },
      subSpecialty: {
        label: 'Sub specialty',
        type: 'select',
        options: subSpecialities,
      },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      issueDate: { label: 'Issue date', type: 'date', utcDate: true },
      durationType: {
        label: 'Duration Type',
        type: 'select',
        options: DurationTypes,
      },
      status: {
        label: 'Status',
        type: 'select',
        options: boardCertStatusOptions,
      },
      moc: { label: 'MOC', type: 'select', options: boardCertMocOptions },
      flag: { label: 'Flag', type: 'chip' },
      source: {
        label: 'Source',
        type: 'select',
        options: boardCertificationSources,
      },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachments: {
        label: 'Attachments',
        type: 'attachments',
        editable: false,
      },
    },
  },
  {
    id: 'nationalPractitionerDataBank',
    title: 'National Practitioner Data Bank',
    cardType: 'table',
    allowDownload: true,
    fields: {
      typeOfAction: {
        label: 'Type Of Action',
        type: 'select',
        options: natProviderDatabankActionTypes,
        allowFreeText: true,
      },
      reportType: {
        label: 'Report Type',
        type: 'select',
        options: npdbReportTypes,
      },
      deceased: {
        label: 'Patient Deceased',
        type: 'select',
        options: patientDeceasedOptions,
        saveKey: 'reportData.deceased',
      },
      totalPaymentForThisPractitioner: {
        label: 'MMPR Payment Amount',
        type: 'text',
        saveKey: 'reportData.totalPaymentForThisPractitioner',
      },
      reportDate: { label: 'Report Date', type: 'date', utcDate: false },
      processDate: { label: 'Process Date', type: 'date', utcDate: false },
      flag: { label: 'Flag', type: 'chip' },
      source: {
        label: 'Source',
        type: 'select',
        options: npdbSources,
      },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'licensureActions',
    title: 'Licensure Actions',
    cardType: 'table',
    fields: {
      lastActionDate: {
        label: 'Last action date',
        type: 'date',
        utcDate: false,
      },
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      state: { label: 'State', type: 'select', options: stateCodes },
      details: { label: 'Details', type: 'textarea' },
      actionType: {
        label: 'Action type',
        type: 'select',
        allowFreeText: true,
        options: ['No Action', 'Action Found'],
      },
      flag: { label: 'Flag', type: 'chip' },
      source: { label: 'Source' },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'sanctions',
    title: 'Sanctions',
    cardType: 'table',
    fields: {
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      typeOfAction: {
        label: 'Action type',
        type: 'select',
        options: sanctionActionTypes,
      },
      sanctionType: {
        label: 'Sanction type',
        type: 'select',
        options: sanctionTypes,
      },
      flag: { label: 'Flag', type: 'chip' },
      source: {
        label: 'Source',
        type: 'select',
        options: PROVIDERS.SANCTION_SOURCE,
      },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'malpracticeInsurance',
    title: 'Malpractice Insurance',
    cardType: 'table',
    fields: {
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      policyNumber: { label: 'Policy number' },
      status: {
        label: 'status',
        type: 'select',
        options: malpracticeInsuranceStatuses,
      },
      flag: { label: 'Flag', type: 'chip' },
      source: {
        label: 'Source',
        type: 'select',
        options: malpracticeInsuranceSources,
      },
      occurrenceCoverageAmount: {
        label: 'Occurrence Coverage Amount',
        type: 'amount',
      },
      aggregateCoverageAmount: {
        label: 'Aggregate Coverage Amount',
        type: 'amount',
      },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
      attachments: {
        label: 'Attachments',
        type: 'attachments',
        editable: false,
      },
    },
  },
  {
    id: 'perfIndicator',
    title: 'Performance Indicator',
    cardType: 'form',
    isEditable: true,
    fields: {
      complaints: { label: 'Total complaints' },
      clinical: { label: 'Clinical compliants' },
      service: { label: 'Service complaints' },
      benefits: { label: 'Benefit complaints' },
      claims: { label: 'Claim complaints' },
      accessIssues: { label: 'Access issue complaints' },
      flag: { label: 'Flag', type: 'chip' },
      source: { label: 'Source', editable: false, visible: false },
      verifiedByFullName: {
        visible: true,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: true,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'appVerification',
    title: 'App Verification',
    cardType: 'table',
    fields: {
      verificationType: {
        label: 'Verification Type',
        type: 'select',
        options: appVerificationTypes,
      },
      explanation: {
        label: 'Explanation',
        type: 'select',
        allowFreeText: true,
        options: appVerificationExplanations,
      },
      flag: { label: 'Flag', type: 'chip' },
      source: {
        label: 'Source',
        editable: true,
        type: 'select',
        allowFreeText: true,
        options: appVerificationSources,
      },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'training',
    title: 'Education and Training',
    cardType: 'table',
    fields: {
      source: {
        label: 'Source',
        type: 'select',
        options: educationTrainingSources,
      },
      type: {
        label: 'Type',
        type: 'select',
        options: educationTrainingType,
      },
      degree: {
        label: 'Degree',
        type: 'select',
        options: educationDegreeOptions,
      },
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: specialities,
      },
      subSpecialty: {
        label: 'Sub specialty',
        type: 'select',
        options: subSpecialities,
      },
      institution: {
        label: 'Institution',
      },
      startDate: {
        label: 'Start Date',
        type: 'date',
        utcDate: false,
      },
      endDate: {
        label: 'End Date',
        type: 'date',
        utcDate: false,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: {
        label: 'Verified By',
        editable: false,
        visible: false,
      },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
        visible: false,
      },
      attachments: {
        label: 'Attachments',
        type: 'attachments',
        editable: false,
      },
    },
  },
  {
    id: 'affiliation',
    title: 'Hospital Affiliation',
    cardType: 'table',
    fields: {
      name: {
        label: 'Name',
        type: 'text',
      },
      type: {
        label: 'Type',
        type: 'select',
        options: hospitalAffiliationTypes,
      },
      source: {
        label: 'Source',
        type: 'select',
        options: hospitalAffiliationSources,
      },
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: hospitalAffiliationSpecialties,
      },
      startDate: {
        label: 'Start Date',
        type: 'date',
        utcDate: false,
      },
      endDate: {
        label: 'End Date',
        type: 'date',
        utcDate: false,
      },
      verificationUrl: {
        label: 'Verification Url',
        type: 'text',
      },
      verifiedByFullName: {
        label: 'Verified By',
        editable: false,
        visible: false,
      },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
        visible: false,
      },
      attachments: {
        label: 'Attachments',
        type: 'attachments',
        editable: false,
      },
    },
  },
  {
    id: 'supportingDocuments',
    title: 'Supporting Documents',
  },
];

export const sectionsContainsAttachmentsArray = [
  'stateLicenses',
  'dea',
  'boardCertifications',
  'malpracticeInsurance',
  'training',
  'affiliation',
];

export const npdbActionTypes = ['MMPR', 'AAR', 'No Action'];
export const licensureActionTypes = ['MMPR', 'AAR', 'No Action'];

export const CRED_COMM_TABS = {
  OVERVIEW: 'overview',
  NPDB: 'npdbActions',
  LICENSURE_ACTIONS: 'licensureActions',
  SANCTIONS: 'sanctions',
  NOTES: 'notes',
};

export const credCommTabs = [
  {
    id: CRED_COMM_TABS.OVERVIEW,
    title: 'Overview',
  },
  {
    id: CRED_COMM_TABS.NPDB,
    title: 'NPDB',
  },
  {
    id: CRED_COMM_TABS.LICENSURE_ACTIONS,
    title: 'Licensure Actions',
  },
  {
    id: CRED_COMM_TABS.SANCTIONS,
    title: 'Sanctions',
  },
  {
    id: CRED_COMM_TABS.NOTES,
    title: 'Notes',
  },
];

export const credCommDataCards: DataCardConfig[] = [
  {
    id: CRED_COMM_TABS.OVERVIEW,
    title: 'Overview',
    cardTitle: 'Provider Overview',
    cardType: 'form',
    editable: false,
    fields: {
      npi: { label: 'NPI' },
      states: { label: 'States' },
      providerType: {
        label: 'Provider Type',
        type: 'select',
        options: providerTypes,
      },
      npdbActions: {
        label: 'NPDB Report Actions',
        type: 'multiselect-chip',
        options: npdbActionTypes,
      },
      sanctions: {
        label: 'Sanctions',
        type: 'multiselect-chip',
        options: sanctionTypes,
      },
      licensureActions: {
        label: 'Licensure Actions',
        type: 'multiselect-chip',
        options: licensureActionTypes,
      },
    },
    actions: [
      {
        label: 'View PSV',
      },
    ],
  },
  {
    id: CRED_COMM_TABS.NPDB,
    title: 'NPDB',
    cardTitle: 'NPDB Actions',
    cardType: 'form',
    fields: {
      typeOfAction: {
        label: 'Action Type',
        type: 'chip-select',
        options: natProviderDatabankActionTypes,
        allowFreeText: true,
      },
      processDate: { label: 'Process date', type: 'date', utcDate: false },
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      totalPaymentForThisPractitioner: {
        label: 'Settlement amount',
        displayValuePrefix: '$',
      },
      dateOfSettlement: {
        label: 'Settlement Date',
        type: 'date',
        utcDate: false,
      },
      judgmentOrSettlementDesc: {
        label: 'Settlement description',
        colSpacing: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      },
      medicalConditionDesc: {
        label: 'Medical condition description',
        colSpacing: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      },
      procedureDesc: {
        label: 'Procedure description',
        colSpacing: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      },
      allegationsDesc: {
        label: 'Allegation',
        colSpacing: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      },
      deceased: {
        label: 'Patient Deceased?',
        colSpacing: {
          xs: 6,
          sm: 4,
          md: 3,
          lg: 2,
        },
        type: 'select',
        options: patientDeceasedOptions,
      },
      providerAttestation: {
        label: 'Provider attestation',
        colSpacing: {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12,
        },
      },
    },
    collapsiblePanelFields: ['verifiedAt', 'verifiedByFullName'],
  },
  {
    id: CRED_COMM_TABS.LICENSURE_ACTIONS,
    title: 'Licensure Actions',
    cardTitle: 'Licensure Actions',
    cardType: 'table',
    fields: {
      lastActionDate: {
        label: 'Last action date',
        type: 'date',
        utcDate: false,
        showFlag: true,
      },
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      state: { label: 'State', type: 'select', options: stateCodes },
      details: { label: 'Details', type: 'textarea' },
      actionType: {
        label: 'Action type',
        type: 'select',
        allowFreeText: true,
        options: ['No Action', 'Action Found'],
      },
      source: { label: 'source', visible: false },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: {
        label: 'Verified By',
        editable: false,
        visible: false,
      },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
        visible: false,
      },
    },
    collapsiblePanelFields: ['verifiedAt', 'verifiedByFullName'],
  },
  {
    id: CRED_COMM_TABS.SANCTIONS,
    title: 'Sanctions',
    cardTitle: 'Sanctions',
    cardType: 'table',
    fields: {
      reportDate: { label: 'Date of Report', type: 'date' },
      typeOfAction: {
        label: 'Action Type',
        type: 'select',
        options: sanctionActionTypes,
      },
      sanctionType: {
        label: 'Sanction Type',
        type: 'select',
        options: sanctionTypes,
      },
      source: { label: 'Source' },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: {
        label: 'Verified By',
        editable: false,
        visible: false,
      },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        visible: false,
        type: 'date',
        utcDate: false,
      },
    },
    collapsiblePanelFields: ['verifiedAt', 'verifiedByFullName'],
  },
  {
    id: CRED_COMM_TABS.NOTES,
    title: 'Notes',
    cardTitle: 'Committee Notes',
    cardType: 'custom',
    fields: {},
  },
];

export const monitoringDataCards: DataCardConfig[] = [
  {
    id: 'assignment',
    title: 'Assignment',
    disableCollapse: true,
    allowAddMore: false,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      assignedTo: { label: 'Analyst', type: 'select', options: [] },
      assignedBy: { label: 'Assigned By', editable: false },
      lastActivity: { label: 'Last Activity', editable: false },
      daysOpen: { label: 'Days Open', editable: false },
    },
    viewableRoles: [ANALYST_ROLE],
    editableRoles: [SUPERVISOR_ROLE],
  },
  {
    id: 'overview',
    title: 'Overview',
    disableCollapse: true,
    allowAddMore: false,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      firstName: { label: 'First Name', editable: false },
      middleName: {
        label: 'Middle Name',
        editable: false,
      },
      lastName: { label: 'Last Name', editable: false },
      dateOfBirth: { label: 'DOB', type: 'date', utcDate: true },
      npi: { label: 'NPI', editable: false },
      providerType: {
        label: 'Specialty Type',
        type: 'select',
        options: providerTypes,
        editable: false,
      },
      providerStatus: { label: 'Provider Status', editable: false },
      caqhProviderId: { label: 'CAQH', editable: false },
      primaryEmail: { label: 'CAQH Provider Email', editable: false },
      createdAt: { label: 'Created At', editable: false, type: 'date' },
      reviewCompleteDate: {
        label: 'Review Complete Date',
        editable: false,
        type: 'date',
      },
      reviewCompletedBy: { label: 'Review Completed By', editable: false },
      flags: { label: 'Flag', type: 'chip', editable: false },
      assignedStates: {
        label: 'States to Credential',
        editable: false,
      },
      states: {
        label: 'States with License',
        editable: false,
      },
      externalId: {
        label: 'External Id',
        editable: false,
      },
    },
  },
  {
    id: 'stateLicenses',
    title: 'State License',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      state: {
        label: 'State',
        type: 'select',
        options: stateCodes,
        showFlag: true,
      },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      issueDate: { label: 'Issue date', type: 'date', utcDate: true },
      type: { label: 'Type', options: providerTypes, type: 'select' },
      dataLastAcquiredDate: {
        label: 'Report Date',
        type: 'date',
        utcDate: false,
        editable: true,
      },
      licenseType: { label: 'License type' },
      status: {
        label: 'Status',
        options: stateLicenseStatuses,
        type: 'select',
      },
      licenseNumber: { label: 'License number' },
      source: { label: 'Source' },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'dea',
    title: 'DEA/CDS',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      state: {
        label: 'State',
        type: 'select',
        options: stateCodes,
        showFlag: true,
      },
      deaNumber: { label: 'License number' },
      type: { label: 'Type', type: 'select', options: DEA_DATA_TYPES },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      providerType: {
        label: 'Provider type',
        type: 'select',
        options: providerTypes,
      },
      status: { label: 'Status', type: 'select', options: deaActivities },
      source: { label: 'Source' },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'boardCertifications',
    title: 'Board Certification',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      specialty: {
        label: 'Specialty',
        type: 'select',
        options: specialities,
      },
      subSpecialty: {
        label: 'Sub specialty',
        type: 'select',
        options: subSpecialities,
        showFlag: true,
      },
      expirationDate: { label: 'Expiration date', type: 'date', utcDate: true },
      issueDate: { label: 'Issue date', type: 'date', utcDate: true },
      durationType: {
        label: 'Duration Type',
        type: 'select',
        options: DurationTypes,
      },
      status: {
        label: 'Status',
        type: 'select',
        options: boardCertStatusOptions,
      },
      moc: { label: 'MOC', type: 'select', options: boardCertMocOptions },
      source: {
        label: 'Source',
        type: 'select',
        options: boardCertificationSources,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'nationalPractitionerDataBank',
    title: 'National Practitioner Data Bank',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      typeOfAction: {
        label: 'Type Of Action',
        type: 'select',
        options: natProviderDatabankActionTypes,
        allowFreeText: true,
      },
      reportType: {
        label: 'Report Type',
        type: 'select',
        options: npdbReportTypes,
      },
      deceased: {
        label: 'Patient Deceased',
        type: 'select',
        options: patientDeceasedOptions,
        saveKey: 'reportData.deceased',
      },
      totalPaymentForThisPractitioner: {
        label: 'MMPR Payment Amount',
        type: 'text',
        saveKey: 'reportData.totalPaymentForThisPractitioner',
      },
      reportDate: { label: 'Report Date', type: 'date', utcDate: false },
      processDate: { label: 'Process Date', type: 'date', utcDate: false },
      flag: { label: 'Flag', type: 'chip' },
      source: { label: 'Source', type: 'select', options: npdbSources },
      verifiedByFullName: {
        visible: false,
        label: 'Verified By',
        editable: false,
      },
      verifiedAt: {
        visible: false,
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'licensureActions',
    title: 'Licensure Actions',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      lastActionDate: {
        label: 'Last action date',
        type: 'date',
        utcDate: false,
        showFlag: true,
      },
      reportDate: { label: 'Report date', type: 'date', utcDate: false },
      state: { label: 'State', type: 'select', options: stateCodes },
      details: { label: 'Details', type: 'textarea' },
      actionType: {
        label: 'Action type',
        type: 'select',
        allowFreeText: true,
        options: ['No Action', 'Action Found'],
      },
      source: { label: 'source', visible: false },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'sanctions',
    title: 'Sanctions',
    allowAddMore: true,
    isEditableOnProvider: true,
    isEditableOnCredentialingWorkflow: true,
    fields: {
      reportDate: {
        label: 'Report date',
        type: 'date',
        utcDate: false,
        showFlag: true,
      },
      typeOfAction: {
        label: 'Action type',
        type: 'select',
        options: sanctionActionTypes,
      },
      sanctionType: {
        label: 'Sanction type',
        type: 'select',
        options: sanctionTypes,
      },
      source: {
        label: 'Source',
        type: 'select',
        options: PROVIDERS.SANCTION_SOURCE,
      },
      flag: { label: 'Flag', type: 'chip' },
      verifiedByFullName: { label: 'Verified By', editable: false },
      verifiedAt: {
        label: 'Verified At',
        editable: false,
        type: 'date',
        utcDate: false,
      },
    },
  },
  {
    id: 'supportingDocuments',
    title: 'Supporting Documents',
  },
  {
    id: 'errorsWarnings',
    title: 'Errors and Warnings',
  },
  {
    id: 'notes',
    title: 'Notes',
    fields: {},
  },
];

export const facilitiesStateLicenseTypes = [
  'State License',
  'Business Permit',
  'Occupational License',
  'Medical gas permit',
];

export const FileType = {
  CLEAN: 'Clean',
  NON_CLEAN: 'Non_Clean',
} as const;

export const ManualFileType = {
  ...FileType,
  PENDING: 'Pending',
};

export const facilityDataCards: DataCardConfig[] = [
  {
    id: 'assignment',
    title: 'Assignment',
    cardType: 'form',
    gridCols: 12,
    skipStatusCheck: true,
    fields: {
      assignedTo: {
        label: 'Analyst',
        type: 'select',
        options: [],
        editable: false,
      },
      assignedBy: { label: 'Assigned By', editable: false },
      lastActivity: { label: 'Last Activity', editable: false },
      daysOpen: { label: 'Days Open', editable: false },
    },
    viewableRoles: [ANALYST_ROLE, CREDENTIALING_USER_ROLE],
    editableRoles: [SUPERVISOR_ROLE],
  },
  {
    id: 'overview',
    title: 'Overview',
    disableCollapse: true,
    allowAddMore: false,
    cardType: 'form',
    gridCols: 12,
    fields: {
      credentialingStatus: {
        label: 'Cred Status',
        type: 'chip-select',
        chipColor: 'default',
        options: credentialingStatuses,
        editable: false,
      },
      tin: { label: 'Tin / Tax ID' },
      npi: { label: 'NPI' },
      fileType: {
        label: 'File Type',
        type: 'select',
        options: fileTypes,
        popupInfo: true,
      },
      medicaidNumber: { label: 'Medicaid Number' },
      medicareNumber: { label: 'Medicare Number' },
      dba: { label: 'DBA Name' },
      name: { label: 'Name' },
      address: { label: 'Facility Address' },
      city: { label: 'City' },
      zipcode: { label: 'ZIP Code' },
      state: { label: 'State', type: 'select', options: stateCodes },
      contactName: { label: 'Contact person' },
      contactPhone: { label: 'Contact phone' },
      contactEmail: { label: 'Contact email' },
      contactFax: { label: 'Contact fax' },
      externalId: { label: 'External ID' },
    },
  },
  {
    id: 'credentialingTimeline',
    title: 'Credentialing Timeline',
    disableCollapse: true,
    allowAddMore: false,
    cardType: 'form',
    gridCols: 12,
    fields: {
      medicalDirectorName: {
        label: 'Medical Director',
        editable: false,
      },
      credentialingStartDate: {
        label: 'Received for Credentialing Date',
        type: 'date',
        editable: false,
      },
      attestationDate: {
        label: 'Attestation Date',
        editable: true,
        type: 'date',
        utcDate: false,
      },
      lastCredentialingDate: {
        label: 'Last Credentialing Date',
        type: 'date',
        editable: false,
      },
      psvCompleteDate: {
        label: 'PSV Completed Date',
        type: 'date',
        editable: false,
      },
      credentialingDecisionDate: {
        label: 'Credentialing Decision Date',
        type: 'date',
        editable: false,
      },
      nextCredentialingDate: {
        label: 'Next Credentialing Date',
        type: 'date',
        editable: false,
      },
    },
  },
  {
    id: 'stateLicenses',
    title: 'State Licenses',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      licenseType: {
        label: 'License Type',
        type: 'select',
        options: facilitiesStateLicenseTypes,
      },
      source: { label: 'Source' },
      status: {
        label: 'Status',
        type: 'select',
        options: ['Active', 'Expired'],
      },
      licenseNumber: { label: 'Number' },
      issueDate: { label: 'Issue Date', type: 'date', utcDate: false },
      expirationDate: {
        label: 'Expiration Date',
        type: 'date',
        utcDate: false,
      },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'liabilityInsurances',
    title: 'Liability Insurances',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      insuranceType: {
        label: 'Insurance Type',
        type: 'select',
        options: ['General Liability', 'Professional Liability'],
      },
      policyNumber: { label: 'Policy #' },
      source: {
        label: 'Source',
        type: 'select',
        options: ['Copy of Insurance', 'Other'],
      },
      expirationDate: { label: 'Expiration', type: 'date', utcDate: false },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'sanctions',
    title: 'Sanctions / Exclusions',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      sanctionType: { label: 'Sanction Type' },
      source: { label: 'Source' },
      reportResults: {
        label: 'Report Results',
        type: 'select',
        options: ['No Match Found', 'Match Found'],
      },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'accreditations',
    title: 'Accreditation',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      applicable: {
        label: 'Applicable',
        type: 'select',
        options: ['Yes', 'No (site visit required)', 'N/A'],
      },
      accreditationBody: { label: 'Accreditation Body' },
      status: {
        label: 'Status',
        type: 'select',
        options: ['Accredited', 'Not Accredited'],
      },
      effectiveDate: { label: 'Effective Date', type: 'date', utcDate: false },
      expirationDate: {
        label: 'Expiration Date',
        type: 'date',
        utcDate: false,
      },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'certifications',
    title: 'Certificates',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      certificateType: {
        label: 'Certificate Type',
        type: 'select',
        options: ['CLIA', 'CMS', 'CDS', 'DEA', 'N/A'],
      },
      source: { label: 'Source' },
      number: { label: 'Number' },
      issueDate: { label: 'Issue Date', type: 'date', utcDate: false },
      expirationDate: {
        label: 'Expiration Date',
        type: 'date',
        utcDate: false,
      },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'npdb',
    title: 'National Practitioner Data Bank',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      applicable: {
        label: 'Applicable',
        type: 'select',
        options: ['Yes', 'No', 'N/A'],
      },
      typeOfAction: {
        label: 'Type Of Action',
        type: 'select',
        options: natProviderDatabankActionTypes,
        allowFreeText: true,
      },
      source: { label: 'Source', type: 'select', options: npdbSources },
      reportResults: {
        label: 'Report Results',
        type: 'select',
        options: npdbReportTypes,
      },
      reportDate: { label: 'Report Date', type: 'date', utcDate: false },
      processDate: { label: 'Process Date', type: 'date', utcDate: false },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'siteVisits',
    title: 'Site Visit',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      applicable: {
        label: 'Applicable',
        type: 'select',
        options: ['Yes', 'No', 'N/A'],
      },
      source: { label: 'Source' },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'perfIndicator',
    title: 'Performance Indicator',
    disableCollapse: true,
    allowAddMore: true,
    cardType: 'table',
    gridCols: 12,
    fields: {
      applicable: {
        label: 'Applicable',
        type: 'select',
        options: ['Yes', 'No', 'N/A'],
      },
      complaints: { label: 'Complaints' },
      clinical: { label: 'Clinical' },
      benefits: { label: 'Benefits' },
      claims: { label: 'Claims' },
      accessIssues: { label: 'Access Issues' },
      verifiedAt: {
        label: 'Verified Date',
        type: 'date',
        editable: false,
        utcDate: false,
      },
      verifiedByFullName: { label: 'Verified By', editable: false },
    },
  },
  {
    id: 'facilitySupportingDocuments',
    title: 'Supporting Documents',
    disableCollapse: true,
    gridCols: 12,
    fields: {
      name: { label: 'File Name' },
      type: { label: 'Type' },
      lastModified: { label: 'Last Modified', type: 'Date' },
    },
  },
];

export const FACILITY_CRED_TABS = {
  FACLITY_INFORMATION: 'facilityInformation',
  OUTREACH: 'outreach',
  NOTES: 'notes',
};
export const facilityWorkflowTabs = [
  {
    id: FACILITY_CRED_TABS.FACLITY_INFORMATION,
    title: 'Facility Information',
  },
  {
    id: FACILITY_CRED_TABS.OUTREACH,
    title: 'Outreach',
  },
  {
    id: FACILITY_CRED_TABS.NOTES,
    title: 'Notes',
  },
];

export const cardIdSectionMap = {
  stateLicenses: 'state-licenses',
  dea: 'dea-data',
  licensureActions: 'licensure-actions',
  sanctions: 'sanctions',
  boardCertifications: 'board-certifications',
  malpracticeInsurance: 'malpractice-insurances',
  perfIndicator: 'performance-data',
  nationalPractitionerDataBank: 'npdb-data',
  npdbActions: 'npdb-data',
  appVerification: 'application-verifications',
  training: 'education-trainings',
  affiliation: 'hospital-affiliation',
  errorsWarnings: 'data-sources',
  supportingDocuments: 'supporting-documents',
};

export const providerSubcollections = [
  {
    id: 'stateLicenses',
    subcollectionName: 'state-licenses',
    label: 'State Licenses',
  },
  { id: 'dea', subcollectionName: 'dea-data', label: 'DEA Data' },
  {
    id: 'licensureActions',
    subcollectionName: 'licensure-actions',
    label: 'Licensure Actions',
  },
  { id: 'sanctions', subcollectionName: 'sanctions', label: 'Sanctions' },
  {
    id: 'boardCertifications',
    subcollectionName: 'board-certifications',
    label: 'Board Certifications',
  },
  {
    id: 'malpracticeInsurance',
    subcollectionName: 'malpractice-insurances',
    label: 'Malpractice Insurances',
  },
  {
    id: 'perfIndicator',
    subcollectionName: 'performance-data',
    label: 'Performance Data',
  },
  {
    id: 'nationalPractitionerDataBank',
    subcollectionName: 'npdb-data',
    label: 'NPDB Data',
  },
  { id: 'npdbActions', subcollectionName: 'npdb-data', label: 'NPDB Data' },
  {
    id: 'appVerification',
    subcollectionName: 'application-verifications',
    label: 'Application Verifications',
  },
  {
    id: 'training',
    subcollectionName: 'education-trainings',
    label: 'Education Trainings',
  },
  {
    id: 'affiliation',
    subcollectionName: 'hospital-affiliation',
    label: 'Hospital Affiliation',
  },
  {
    id: 'errorsWarnings',
    subcollectionName: 'data-sources',
    label: 'Data Sources',
  },
  {
    id: 'supportingDocuments',
    subcollectionName: 'supporting-documents',
    label: 'Supporting Documents',
  },
  { id: 'nppes', subcollectionName: 'nppes', label: 'NPPES' },
];

export const outreachReasons = [
  'License not present and or not current',
  'DEA not current',
  'Current malpractice insurance coverage',
  'Expired Attestation',
  'Work History Verification',
  'Gap Analysis',
  'Not Authorized by Provider',
  'Alternate Outreach',
  'Application Data Submitted',
  'First Provider Contact',
  'Initial Outreach',
  'New Provider',
  'Profile Data Submitted',
  'Wrong State CAQH Application',
  'Not on Roster',
  'NPI Missing',
  'Provider Deceased',
  'Initial Profile Complete',
  'Reattestation',
  'Provider Retired',
  'Disclosure Question Explanation',
  'Health Status Question Explanation',
  'Application Amendment (incorrect name/incorrect board certification listed, etc)',
  'Malpractice Insurance Expiring Soon',
  'Opt Out',
  'Returned mail',
  'CAQH Unavailable',
  'Education Verification',
  'Missing Disclosure of Ownership',
  'Missing Supervisory/Collaboration Agreement',
  'Missing Application Release',
  'Missing CLIA',
  'Missing Medicaid/Medicare ID',
  'W9 Incomplete',
  'Health Plan Follow-up / Inquiry',
];

export const extraOutreachReasonsPE = [
  'Application Status (Enrollment)',
  'Contract Agreement Request',
];

export const outreachDocTypes = [
  'Resume/CV',
  'ECFMG Certificate',
  'Liability Insurance',
  'National Practitioner Data Bank',
  'Residency Diploma',
  'Fellowship Diploma',
  'Professional Diploma',
  'Copy of SSN Card',
  'Professional',
  'Medical Diploma',
  'Controlled Substance Registration (CSR) Certificate',
  'DEA Certificate',
  "Driver's License",
  'Background Check',
  '2" by 2" Profile Picture',
  'Passport',
  'Birth Certificate',
  'Claims Disclosure',
  'Disclosure Attestation',
  'ANCC/AANP Certificate',
  'Board Certification Diploma',
  'CPR Training Certificate',
  'License Copy',
  'W9 Form',
  'Signature',
  'Signature Initials',
  'CAQH Signature',
  'CAQH Signature Initials',
  'Attestation Signature',
  'Military Service Document',
  'Exam',
  'CME Certificate',
  'Primary Source Screenshot',
  'Biography',
  'Additional name document',
  'Other',
  'OFAC',
  'DME',
  'Medicaid Exclusions',
];
export const supportingDocTypes = [
  'CAQH documents with Application',
  'Application',
  'Disclosure Attestation',
  'Application Release',
  'CAQH Signature',
  'CAQH Signature Initials',
  'Attestation Signature',
  'Form A - Adverse and Other Actions',
  'Form B - Professional Liability Actions',
  'Form C - Liability Insurance',
  'Form D - Criminal Actions',
  'Form E - Medical Condition',
  'Form F - Chemical Substances or Alcohol Abuse',
  'Malpractice Explanation',
  'Section D - Attestation Questions',
  'License Copy',
  'State License',
  'State License-MD',
  'Maryland State License Certificate',
  'DEA Certificate',
  'Controlled Substance Registration (CSR) Certificate',
  'DEA',
  'DEA Waiver',
  'CDS',
  'Board Action',
  'Board Certification Diploma',
  'Board Certification',
  'Diploma',
  'ANCC/AANP Certificate',
  'Certificate of Advanced Nurse Practitioners',
  'Diplomate of National Board of Medical Examiners Certificate',
  'Education Verification',
  'National Practitioner Data Bank (NPDB)',
  'Liability/Malpractice Insurance (COI)',
  'Certificate of Insurance',
  'Federal Tort Claim Act Coverage (FTCA)',
  'Letter of Self Insurance',
  'Professional Liability Insurance',
  'Professional Liability Insurance 2',
  'Professional Liability Insurance 3',
  'Professional Liability Verification',
  'Signed Malpractice',
  'Resume',
  'Resume/CV',
  'Primary Source Verification File (PSV)',
  'Certificate for Conducting Xray and/or Laboratory Services',
  'Certificate or Letter Certifying Formal Post-Graduate Training',
  'Certificates of Completion (Med School, Internship etc.)',
  'CLIA Certification',
  'Correspondence',
  'DD214, Record of Military Service',
  'Disclosure',
  'Disclosure of Ownership',
  'DPS',
  'ECFMG',
  'Exam Certification',
  'Hospital Letter, Verification of Hospital Credentialing, or Alternative Pathways',
  'National Provider Identification Certificate',
  'Other Certificate',
  'Radiology Certification',
  'Reference Letter',
  'Schedule AP1',
  'Schedule B - Professional Liability Claims Information Form',
  'Schedule C - Regulation Acknowledgement',
  'Scholar Visa IAP-66',
  'State Application Addendum',
  'State Authorization',
  'State Release',
  'Sanctions (OIG)',
  'Sanctions (MOO)',
  'Sanctions (SAM/GSA)',
  'Accreditation',
  'Supervisory/Collaboration Agreement',
  'TB Skin Test',
  'Termination',
  'Therapeutic/Diagnostic Pharmaceutical Agents License',
  'VISA',
  'W-9',
  'Workers Compensation Certificate of Coverage',
  'ECFMG Certificate',
  'Residency Diploma',
  'Fellowship Diploma',
  'Professional Diploma',
  'Copy of SSN Card',
  'Medical Diploma',
  "Driver's License",
  'Background Check',
  '2" by 2" Profile Picture',
  'Passport',
  'Birth Certificate',
  'Claims Disclosure',
  'CPR Training Certificate',
  'W9 Form',
  'Signature',
  'Signature Initials',
  'Military Service Document',
  'Exam',
  'CME Certificate',
  'Primary Source Screenshot',
  'Biography',
  'Additional name document',
  'OFAC',
  'DME',
  'Medicaid Exclusions',
  'CMS Certificate',
  'Other',
  'State Medicaid Exclusion',
  'Employment Verification',
  'NPI Verification',
  'PATCH',
  'Delineation of Privilege',
  'FACIS',
  'Code of Conduct',
  'Disclosure and Authorization to Obtain Criminal Background Reports',
  'Medicare/Medical Assistance/Champus Statement Acknowledgment',
  'Release and Attestation',
  'Preclusion List',
  'Start Date Change Ticket',
  'LMS',
  'MD Medicaid Disclosure',
  'PA Medicaid Disclosure',
  'PHC Supplement',
  'Proxy',
  'Health MSO Orientation',
  'NCCPA/CAQ',
].sort();
export const supportDocsSortOrder = [
  'CAQH documents with Application',
  'Application',
  'Disclosure Attestation',
  'Application Release',
  'CAQH Signature',
  'CAQH Signature Initials',
  'Attestation Signature',
  'Form A - Adverse and Other Actions',
  'Form B - Professional Liability Actions',
  'Form C - Liability Insurance',
  'Form D - Criminal Actions',
  'Form E - Medical Condition',
  'Form F - Chemical Substances or Alcohol Abuse',
  'Malpractice Explanation',
  'Section D - Attestation Questions',
  'License Copy',
  'State License',
  'State License-MD',
  'Maryland State License Certificate',
  'DEA Certificate',
  'Controlled Substance Registration (CSR) Certificate',
  'DEA',
  'DEA Waiver',
  'CDS',
  'Board Action',
  'Board Certification Diploma',
  'Board Certification',
  'Diploma',
  'ANCC/AANP Certificate',
  'Certificate of Advanced Nurse Practitioners',
  'Diplomate of National Board of Medical Examiners Certificate',
  'National Practitioner Data Bank (NPDB)',
  'Liability/Malpractice Insurance (COI)',
  'Certificate of Insurance',
  'Federal Tort Claim Act Coverage (FTCA)',
  'Letter of Self Insurance',
  'Professional Liability Insurance',
  'Professional Liability Insurance 2',
  'Professional Liability Insurance 3',
  'Professional Liability Verification',
  'Signed Malpractice',
  'Resume',
  'Resume/CV',
  'Primary Source Verification File (PSV)',
  'Certificate for Conducting Xray and/or Laboratory Services',
  'Certificate or Letter Certifying Formal Post-Graduate Training',
  'Certificates of Completion (Med School, Internship etc.)',
  'CLIA Certification',
  'Correspondence',
  'DD214, Record of Military Service',
  'Disclosure',
  'Disclosure of Ownership',
  'DPS',
  'ECFMG',
  'Exam Certification',
  'Hospital Letter, Verification of Hospital Credentialing, or Alternative Pathways',
  'National Provider Identification Certificate',
  'Other Certificate',
  'Radiology Certification',
  'Reference Letter',
  'Schedule AP1',
  'Schedule B - Professional Liability Claims Information Form',
  'Schedule C - Regulation Acknowledgement',
  'Scholar Visa IAP-66',
  'State Application Addendum',
  'State Authorization',
  'State Release',
  'Sanctions (OIG)',
  'Sanctions (MOO)',
  'Sanctions (SAM/GSA)',
  'Accreditation',
  'Supervisory/Collaboration Agreement',
  'TB Skin Test',
  'Termination',
  'Therapeutic/Diagnostic Pharmaceutical Agents License',
  'VISA',
  'W-9',
  'Workers Compensation Certificate of Coverage',
  'ECFMG Certificate',
  'Residency Diploma',
  'Fellowship Diploma',
  'Professional Diploma',
  'Copy of SSN Card',
  'Medical Diploma',
  "Driver's License",
  'Background Check',
  '2" by 2" Profile Picture',
  'Passport',
  'Birth Certificate',
  'Claims Disclosure',
  'CPR Training Certificate',
  'W9 Form',
  'Signature',
  'Signature Initials',
  'Military Service Document',
  'Exam',
  'CME Certificate',
  'CMS Certificate',
  'Primary Source Screenshot',
  'Biography',
  'Additional name document',
  'OFAC',
  'DME',
  'Medicaid Exclusions',
  'State Medicaid Exclusion',
  'Other',
];

export const openModalValues = ['Disclosure Questions', 'Admitting Privileges'];
export const MONITORING_WORKFLOW_PAGE_SIZES = [5, 10, 15, 20];

export const EDITABLE_STATUSES = [
  'In Progress',
  'Data missing',
  'Outreach in progress',
  'Credentialing restarted',
  'Recredentialing in progress',
];

export const STATUS_BARCHART_GROUPS = [
  {
    label: 'In progress',
    keys: EDITABLE_STATUSES,
  },
  {
    label: 'Review',
    keys: [
      CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
      'PSV ready',
      'Hold for Cred Comm',
      'Tabled',
    ],
  },
  {
    label: 'Complete',
    keys: ['Cred approved', 'Cred denied', 'Withdrawn/Cancelled'],
  },
  {
    label: 'Other',
    keys: ['Not sent to credentialing', 'Provider Terminated'],
  },
];

export interface ConditionClause {
  onStepTitle?: string;
  roles?: string[];
  hasEditProvider?: boolean;
  isCompleted?: boolean;
  psvFileSignedUrlExists?: boolean;
  canViewNpdbPdf?: boolean;
  canGenerateNpdbPdf?: boolean;
  credCycle?:
    | typeof CredentialingCycle.INITIAL
    | typeof CredentialingCycle.RECRED;
}

export interface MatchConditions {
  userRoles: string[];
  hasEditProvider?: boolean;
  isCompleted?: boolean;
  psvFileSignedUrl?: boolean;
  steps?: CredTimelineStep[];
  onStep?: string;
  canGenerateNpdbPdf?: boolean;
  canViewNpdbPdf?: boolean;
  credCycle?:
    | typeof CredentialingCycle.INITIAL
    | typeof CredentialingCycle.RECRED;
}

export interface MatchConditionParams extends MatchConditions {
  condition: ConditionClause;
}

export interface MatchConditionsParams extends MatchConditions {
  conditions: ActionConditions;
}

export interface ActionConditions {
  must?: ConditionClause[];
  mustNot?: ConditionClause[];
  should?: ConditionClause[];
}
export interface CredActionConfigItem {
  id: string;
  title: string;
  icon: string;
  conditions: ActionConditions;
  roles: string[];
  inputType?: 'file';
}

export const credActions: CredActionConfigItem[] = [
  {
    id: 'notStarted',
    title: CredSteps.NOT_STARTED,
    icon: 'check_circle_outline',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
      ],
    },
  },
  {
    id: 'psvCompleteByCertifyOS',
    title: getCredStepDisplayLabel(CredSteps.PSV_COMPLETE_BY_CERTIFYOS),
    icon: 'check_circle_outline',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [SUPERVISOR_ROLE],
        },
      ],
    },
  },
  {
    id: 'recredentialingInProgress',
    title: 'Recredentilaing in progress',
    icon: 'check_circle_outline',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          credCycle: CredentialingCycle.RECRED,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          credCycle: CredentialingCycle.RECRED,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          credCycle: CredentialingCycle.RECRED,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
          credCycle: CredentialingCycle.RECRED,
        },
      ],
    },
  },
  {
    id: 'inProgress',
    title: CredSteps.IN_PROGRESS,
    icon: 'start',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
      mustNot: [
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          credCycle: CredentialingCycle.RECRED,
        },
      ],
    },
  },
  {
    id: 'outreachInProgress',
    title: CredSteps.OUTREACH_IN_PROGRESS,
    icon: 'mail-icon',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
    },
  },
  {
    id: 'dataMissing',
    title: CredSteps.DATA_MISSING,
    icon: 'flag',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
    },
  },
  {
    id: 'generatePsv',
    title: 'Generate PSV',
    icon: 'post_add',
    roles: [],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.TABLED,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
      ],
    },
  },
  {
    id: 'replacePsv',
    title: 'Upload PSV',
    icon: 'published_with_changes',
    roles: [],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.TABLED,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
      ],
    },
    inputType: 'file',
  },
  {
    id: 'downloadPsv',
    title: 'Download PSV',
    icon: 'download',
    roles: [],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.TABLED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.CRED_APPROVED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.CRED_DENIED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.WITHDRAWN_CANCELLED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
      ],
    },
  },
  {
    id: 'psvReady',
    title: 'PSV Ready',
    icon: 'verified',
    roles: [SUPERVISOR_ROLE],
    conditions: {
      must: [
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
    },
  },
  {
    id: 'viewNpdbReport',
    title: 'View NPDB Report',
    icon: 'visibility',
    roles: [SUPERVISOR_ROLE],
    conditions: {
      must: [
        {
          canViewNpdbPdf: true,
        },
      ],
    },
  },
  {
    id: 'generateNpdbReport',
    title: 'Generate NPDB Report as Supporting Doc',
    icon: 'post_add',
    roles: [SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      must: [
        {
          canGenerateNpdbPdf: true,
        },
      ],
    },
  },
  {
    id: 'hold',
    title: 'Table',
    icon: 'pan_tool_outlined',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.PSV_READY,
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
      ],
    },
  },
  {
    id: 'triggerDataPipeline',
    title: 'Trigger data pipeline',
    icon: 'api',
    roles: [CREDENTIALING_USER_ROLE, ANALYST_ROLE, SUPERVISOR_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
      ],
    },
  },
  {
    id: 'approve',
    title: 'Approve',
    icon: 'check',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
        {
          onStepTitle: CredSteps.TABLED,
        },
        {
          onStepTitle: CredSteps.PSV_READY,
        },
      ],
    },
  },
  {
    id: 'reject',
    title: 'Deny',
    icon: 'do_not_disturb',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
        {
          onStepTitle: CredSteps.TABLED,
        },
        {
          onStepTitle: CredSteps.PSV_READY,
        },
      ],
    },
  },
  {
    id: 'sendToCredentialingCommittee',
    title: 'Send to Credentialing Committee',
    icon: 'pan_tool_outlined',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.PSV_READY,
        },
        {
          onStepTitle: CredSteps.TABLED,
        },
      ],
    },
  },
  {
    id: 'cancel',
    title: 'Cancel',
    icon: 'cancel',
    roles: [
      CREDENTIALING_USER_ROLE,
      SUPERVISOR_ROLE,
      ANALYST_ROLE,
      MEDICAL_DIRECTOR_ROLE,
      PROVISIONAL_APPROVER_ROLE,
    ],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
            SUPERVISOR_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
        },
        {
          onStepTitle: CredSteps.TABLED,
          roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
        },
      ],
    },
  },
];

export const FACILITY_CREDENTIALING_TABS = {
  FACILITY_INFORMATION: 'providerInformation',
  OUTREACH: 'outreach',
};

export const facilityCredentialingTabs = [
  {
    id: FACILITY_CREDENTIALING_TABS.FACILITY_INFORMATION,
    title: 'Facility Information',
  },
  {
    id: FACILITY_CREDENTIALING_TABS.OUTREACH,
    title: 'Outreach',
  },
];

export const facilityCredActions = [
  {
    id: 'notStarted',
    title: CredSteps.NOT_STARTED,
    icon: 'check_circle_outline',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
      ],
    },
  },
  {
    id: 'inProgress',
    title: CredSteps.IN_PROGRESS,
    icon: 'start',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
      mustNot: [
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          credCycle: CredentialingCycle.RECRED,
        },
      ],
    },
  },
  {
    id: 'psvCompleteByCertifyOs',
    title: getCredStepDisplayLabel(CredSteps.PSV_COMPLETE_BY_CERTIFYOS),
    icon: 'check_circle_outline',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [SUPERVISOR_ROLE],
        },
      ],
    },
  },
  {
    id: 'psvReady',
    title: CredSteps.PSV_READY,
    icon: 'verified',
    roles: [SUPERVISOR_ROLE],
    conditions: {
      must: [
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
    },
  },
  {
    id: 'generatePsv',
    title: 'Generate PSV',
    icon: 'post_add',
    roles: [],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.TABLED,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
      ],
    },
  },
  {
    id: 'downloadPsv',
    title: 'Download PSV',
    icon: 'download',
    roles: [],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
          psvFileSignedUrlExists: true,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.TABLED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.CRED_APPROVED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.CRED_DENIED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.WITHDRAWN_CANCELLED,
          psvFileSignedUrlExists: true,
          roles: [
            CREDENTIALING_USER_ROLE,
            ANALYST_ROLE,
            MEDICAL_DIRECTOR_ROLE,
            SUPERVISOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
          ],
        },
      ],
    },
  },
  {
    id: 'outreachInProgress',
    title: CredSteps.OUTREACH_IN_PROGRESS,
    icon: 'mail-icon',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
    },
  },
  {
    id: 'dataMissing',
    title: CredSteps.DATA_MISSING,
    icon: 'flag',
    roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
        },
        {
          onStepTitle: CredSteps.PSV_COMPLETE_BY_CERTIFYOS,
        },
      ],
    },
  },
  {
    id: 'hold',
    title: 'Table',
    icon: 'pan_tool_outlined',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.PSV_READY,
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
      ],
    },
  },
  {
    id: 'approve',
    title: 'Approve',
    icon: 'check',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
        {
          onStepTitle: CredSteps.TABLED,
        },
        {
          onStepTitle: CredSteps.PSV_READY,
        },
      ],
    },
  },
  {
    id: 'reject',
    title: 'Deny',
    icon: 'do_not_disturb',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
        {
          onStepTitle: CredSteps.TABLED,
        },
        {
          onStepTitle: CredSteps.PSV_READY,
        },
      ],
    },
  },
  {
    id: 'cancel',
    title: 'Cancel',
    icon: 'cancel',
    roles: [
      CREDENTIALING_USER_ROLE,
      SUPERVISOR_ROLE,
      ANALYST_ROLE,
      MEDICAL_DIRECTOR_ROLE,
      PROVISIONAL_APPROVER_ROLE,
    ],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.NOT_STARTED,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.RECREDENTIALING_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.DATA_MISSING,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.OUTREACH_IN_PROGRESS,
          roles: [CREDENTIALING_USER_ROLE, SUPERVISOR_ROLE, ANALYST_ROLE],
        },
        {
          onStepTitle: CredSteps.PSV_READY,
          roles: [
            MEDICAL_DIRECTOR_ROLE,
            PROVISIONAL_APPROVER_ROLE,
            SUPERVISOR_ROLE,
          ],
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
          roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
        },
        {
          onStepTitle: CredSteps.TABLED,
          roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
        },
      ],
    },
  },
  {
    id: 'hold',
    title: 'Send to Credentialing Committee',
    icon: 'pan_tool_outlined',
    roles: [MEDICAL_DIRECTOR_ROLE, PROVISIONAL_APPROVER_ROLE],
    conditions: {
      should: [
        {
          onStepTitle: CredSteps.PSV_READY,
        },
        {
          onStepTitle: CredSteps.HOLD_FOR_CRED_COMM,
        },
      ],
    },
  },
];

export const uneditableCredSteps = [
  CredSteps.PSV_READY,
  CredSteps.HOLD_FOR_CRED_COMM,
  CredSteps.TABLED,
  CredSteps.CRED_APPROVED,
  CredSteps.CRED_DENIED,
  CredSteps.WITHDRAWN_CANCELLED,
];

export const uploadSourceTypes = [
  { label: 'Pipeline', value: 'pipeline' },
  { label: 'CertifyOS Staff', value: 'certifyos' },
  { label: 'Non-CertifyOS User', value: 'client' },
];

export const pageSourceTypes = [
  { label: 'Credentialing', value: 'credentialing' },
  { label: 'Enrollment', value: 'enrollment' },
  { label: 'Licensing', value: 'licensing' },
  { label: 'Providers', value: 'providers' },
  { label: 'Groups', value: 'org-entities' },
];

export const groupFileTypes = [
  ...entitySupportingDocuments.map(({ name, label }) => ({
    label,
    value: name,
  })),
  ...entityTaxDocuments.map(({ name, label }) => ({ label, value: name })),
];

export const CertifyOrgId = ['l5vpCm92n19t6Sdp5V9H', 'qhgozzqeVKM4jwRKP3be'];

export const ExtendedRoasterOrgIDs = [
  'wFb7yKrwMTJEhMVxZVdU',
  'qhgozzqeVKM4jwRKP3be',
];

export const organizationTypes = [
  { value: 'Health plan', label: 'Health plan' },
  {
    value: 'Provider Group or Health System',
    label: 'Provider Group or Health System',
  },
  { value: 'Digital Health Company', label: 'Digital Health Company' },
];

export const orgAccessPermissionOptions = [
  {
    id: 'credentialing',
    title: 'Credentialing',
    subtitle: 'Validate provider information',
  },
  {
    id: 'licensing',
    title: 'Licensing',
    subtitle: 'License a provider in a new state',
  },
  {
    id: 'monitoring',
    title: 'Monitoring',
    subtitle: 'Monthly credentialing validation',
  },
  {
    id: 'contracting',
    title: 'Contracting',
    subtitle: 'Contracting validation',
  },
  {
    id: 'facilities',
    title: 'Facilities',
    subtitle: 'Facilities validation',
  },
  {
    id: 'committee',
    title: 'Committee',
    subtitle: 'Committee validation',
  },
];

export const formErrorMessages = {
  requiredField: 'This is a required field',
  numberField: 'Value must be a number',
};

export const ShowActionCredSteps = [
  CredSteps.PSV_READY,
  CredSteps.WITHDRAWN_CANCELLED,
  CredSteps.PROVIDER_TERMINATED,
];

export enum StatusActions {
  MOVE_TO_PSV_READY = 'MOVE_TO_PSV_READY',
  TERMINATE = 'TERMINATE',
  UNTERMINATE = 'UNTERMINATE',
  WITHDRAW_CANCELED = 'WITHDRAW_CANCELED',
  COMPLETED = 'COMPLETED',
  MOVE_TO_PSV_COMPLETE_BY_CERTIFYOS = 'MOVE_TO_PSV_COMPLETE_BY_CERTIFYOS',
  MOVE_TO_HOLD_FOR_CRED_COMMS = 'MOVE_TO_HOLD_FOR_CRED_COMMS',
}

const fileTypeConfigFieldDefault = (): FileLogicFieldType => ({
  enabled: false,
  threshold: 0,
  score: 0,
});

export const FilesLogicDefault: FileTypeConfigsPayload = {
  npdbEnabled: false,
  licensureActionsEnabled: false,
  sanctionsEnabled: false,
  cleanThreshold: 4,
  recentYears: fileTypeConfigFieldDefault(),
  mmprReports: fileTypeConfigFieldDefault(),
  nonMmprReports: fileTypeConfigFieldDefault(),
  patientsDeceased: fileTypeConfigFieldDefault(),
  totalMmprPayment: fileTypeConfigFieldDefault(),
  licensureActions: fileTypeConfigFieldDefault(),
  sanctionMOO: fileTypeConfigFieldDefault(),
  sanctionOIG: fileTypeConfigFieldDefault(),
  sanctionSAM: fileTypeConfigFieldDefault(),
};

export const DefaultFileTypeConfigOverrides: Partial<FileTypeConfigOverridesType> =
  {
    manual: false,
    autoCalculateFileType: true,
  };

export const FacilityFilesLogicDefault: FacilityFileTypeConfigsPayload = {
  npdbEnabled: false,
  sanctionsEnabled: false,
  cleanThreshold: 4,
  sanctionsMatchFound: fileTypeConfigFieldDefault(),
  npdbAdverseActionsFound: fileTypeConfigFieldDefault(),
  licensesEnabled: false,
  expiredLicensesFound: fileTypeConfigFieldDefault(),
  missingLicenses: fileTypeConfigFieldDefault(),
};

export const FILE_LOGIC_NPDB_FIELDS = [
  'recentYears',
  'mmprReports',
  'nonMmprReports',
  'patientsDeceased',
  'totalMmprPayment',
];

export const FILE_LOGIC_SANCTION_FIELDS = [
  'sanctionMOO',
  'sanctionOIG',
  'sanctionSAM',
];

export const defaultAirtableData = {
  airtableUrl: '',
  airtableTitle: '',
};

export const DownloadNotificationTypes = [
  ENotificationType.EXPORT,
  ENotificationType.CREDENTIALING_EXPORT,
  ENotificationType.MONITORING_EXPORT,
  ENotificationType.PROVIDER_EXPORT,
  ENotificationType.EXPORT_WORKFLOW,
];

export const CLEAN_SCORE_OPTIONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

export const orgEntityDataCard: DataCardConfig = {
  id: 'overview',
  title: 'Overview',
  cardType: 'form',
  skipStatusCheck: true,
  fields: {
    affiliation: { label: 'Affiliation', editable: false },
    tin: { label: 'TIN', editable: false },
    npi: { label: 'Group NPI', editable: false },
    externalGroupId: {
      label: 'External Group ID',
      editable: false,
    },
    specialty: { label: 'Specialty', editable: false },
    networkEffectiveDate: {
      label: 'Network effective date',
      type: 'date',
      utcDate: true,
    },
    serviceAddress: { label: 'Service Address', editable: false },
    mailingAddress: { label: 'Mailing Address', editable: false },
    billingAddress: { label: 'Billing Address', editable: false },
    phone: { label: 'Phone', editable: false },
    fax: { label: 'Fax', editable: false },
    states: {
      label: 'States',
      type: 'select',
      editable: false,
    },
  },
};

export const orgEntitySections: DataCardConfig[] = [
  { title: 'Overview', id: 'overview' },
  {
    title: 'Enrollments',
    id: 'enrollments',
    cardType: 'table',
    fields: {
      generatedId: {
        label: 'Enrollment #',
      },
      payorName: {
        label: 'Payor',
      },
      planName: {
        label: 'Plan',
      },
      jurisdiction: {
        label: 'Juridiction',
      },
      lineOfBusiness: {
        label: 'Line of Business',
      },
      status: {
        label: 'Status',
        type: 'chip',
      },
      providerCount: {
        label: 'Providers Enrolled',
        type: 'number',
      },
      actions: {
        label: 'Actions',
        type: 'actions',
      },
    },
  },
  { title: 'Billing and tax', id: 'billingAndTax' },
  { title: 'Locations', id: 'locations' },
  { title: 'Providers', id: 'providers' },
  { title: 'Documents', id: 'documents' },
];

export const enrollmentAddressFormFields: EnrollmentAddressFormFieldParams[] = [
  { name: 'addressLine1', label: 'Address line 1', colSpacing: 12 },
  { name: 'zip', label: 'Zip', colSpacing: 12 },
  { name: 'city', label: 'City', colSpacing: 6 },
  {
    name: 'state',
    label: 'State',
    colSpacing: 6,
    type: 'select',
    options: stateCodes,
  },
  { name: 'country', label: 'Country', colSpacing: 12, disabled: true },
];

export const enrollmentProviderTableConfig: DataCardConfig = {
  id: 'enrollment-providers',
  title: 'Providers included',
  cardType: 'table',
  fields: {
    npi: { label: 'NPI' },
    firstName: { label: 'First Name' },
    lastName: { label: 'Last Name' },
    providerType: { label: 'Type' },
    specialty: { label: 'Specialty' },
    enrollmentStatus: { label: 'Status', type: 'chip' },
    actions: { label: 'Actions', type: 'actions' },
  },
};

export const enrollmentProviderSelectTableConfig: DataCardConfig = {
  id: 'enrollment-providers-select',
  title: 'Associate Providers',
  cardType: 'table',
  fields: {
    npi: { label: 'NPI' },
    firstName: { label: 'First Name' },
    lastName: { label: 'Last Name' },
    providerType: { label: 'Provider Type' },
    specialty: { label: 'Specialty' },
    locations: { label: 'Locations' },
  },
};

export const ENROLLMENT_STATUSES = {
  SUSPENDED: 'Suspended',
  TERMINATED: 'Terminated',

  PENDING_REQUEST: 'Pending Request',
  REQUESTED: 'Requested',
  ONBOARDING: 'Onboarding',
  MISSING_INFORMATION: 'Missing Information',
  INFORMATION_VERIFIED: 'Information Verified',
  SUBMITTED: 'Submitted',
  CONTRACTING: 'Contracting',
  CREDENTIALING_BY_HEALTH_PLAN: 'Credentialing by Health Plan',
  ADDITIONAL_INFORMATION_REQUESTED: 'Additional Information Requested',
  OTHER_PROCESSING: 'Other Processing',
  EFFECTIVE: 'Effective',
  REJECTED: 'Rejected',
  ABANDONED: 'Abandoned',
};

export const ENROLLMENT_STATUS_UPDATE_CONFIRM_DIALOG_CONFIG = {
  [ENROLLMENT_STATUSES.TERMINATED]: {
    title: 'Terminate Enrollment',
    summary:
      'Once you terminate you will not be able to edit or associate providers. You can activate back if you need to.',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  },
  [ENROLLMENT_STATUSES.SUSPENDED]: {
    title: 'Suspend Enrollment',
    summary:
      'Once you suspend you will not be able to associate providers to the enrollment. You can activate back if you need to.',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  },
};

export const ENROLLMENT_PROVIDER_STATUSES = {
  INVALID: 'Invalid',
  SUSPENDED: 'Suspended',
  TERMINATED: 'Terminated',
  EFFECTIVE: 'Effective',
};

export const ENROLLMENT_PROVIDER_STATUS_UPDATE_CONFIRM_DIALOG_CONFIG = {
  [ENROLLMENT_PROVIDER_STATUSES.TERMINATED]: {
    title: 'Terminate Provider Enrollment',
    summary: 'You can activate back the provider if you need to.',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  },
  [ENROLLMENT_PROVIDER_STATUSES.SUSPENDED]: {
    title: 'Suspend Provider Enrollment',
    summary: 'You can activate back the provider if you need to.',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  },
  [ENROLLMENT_PROVIDER_STATUSES.INVALID]: {
    title: 'Mark Provider Enrollment as Invalid',
    summary: 'You can activate back the provider if you need to.',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
  },
};

export const LICENSING_WORKFLOW_STATUS = {
  ALL: 'All',
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  BLOCKED: 'Blocked',
  APP_PREPARATION_AND_REVIEW: 'App Preparation & Review',
  SUBMTTED: 'Submitted',
  FOLLOW_UP: 'Follow up',
  RESUBMITTED: 'Resubmitted',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
} as const;

export const LICENSING_WORKFLOW_TYPES = {
  NEW: 'New',
  RENEWAL: 'Renewal',
} as const;

export const TASK_TYPE = {
  COMPLETE: 'Complete',
  SIGN: 'Sign',
  FILL: 'Fill',
  SUPPORT: 'Support',
  UPLOAD: 'Upload',
  FORM: 'Form',
  MESSAGE: 'Message',
  REVIEW: 'Review',
  ATTEST: 'Attest',
  MISSING_INFORMATION: 'Missing Information',
  THIRD_PARTY_TASK: 'Third Party Task',
  UPDATE_LICENSE_DETAILS: 'Update License Details',
  ESCALATION: 'Escalation',
} as const;

export const SYSTEM_GENERATED_TASK_TYPES = [
  TASK_TYPE.UPDATE_LICENSE_DETAILS,
] as const;

export const TASK_TYPES = Object.values(TASK_TYPE).sort((v1, v2) =>
  v1.localeCompare(v2),
);

export const PE_WORKFLOW_TASK_TYPES = [
  TASK_TYPE.ATTEST,
  TASK_TYPE.ESCALATION,
  TASK_TYPE.MISSING_INFORMATION,
  TASK_TYPE.SIGN,
].sort((v1, v2) => v1.localeCompare(v2));

export const LICENSING_WORKFLOW_TASK_TYPES = Object.values(TASK_TYPE).sort(
  (v1, v2) => v1.localeCompare(v2),
);

export const LICENSING_WORKFLOW_STEPS = [
  {
    label: 'In Progress',
    value: 'move-to-data-in-progress',
  },
  {
    label: 'Blocked',
    value: 'move-to-blocked',
  },
  {
    label: 'App Preparation & Review',
    value: 'move-to-app-prep-and-review',
  },
  {
    label: 'Submitted',
    value: 'move-to-app-submitted',
  },
  {
    label: 'Follow up',
    value: 'move-to-post-submission-followups',
  },
  {
    label: 'Resubmitted',
    value: 'move-to-resubmitted',
  },
  {
    label: 'Completed',
    value: 'move-to-completed',
  },
  {
    label: 'Cancelled',
    value: 'move-to-cancelled',
  },
];

export const MissingInformationStatus = {
  OPEN: 'Open',
  COMPLETED: 'Completed',
} as const;

export const PAYOR_ENROLLMENT_WORKFLOW_STATUS = {
  PENDING_REQUEST: 'Pending Request',
  REQUESTED: 'Requested',
  ONBOARDING: 'Onboarding',
  MISSING_INFORMATION: 'Missing Information',
  INFORMATION_VERIFIED: 'Information Verified',
  SUBMITTED: 'Submitted',
  CONTRACTING: 'Contracting',
  CREDENTIALING_BY_HEALTH_PLAN: 'Credentialing by Health Plan',
  ADDITIONAL_INFORMATION_REQUESTED: 'Additional Information Requested',
  OTHER_PROCESSING: 'Other Processing',
  EFFECTIVE: 'Effective',
  REJECTED: 'Rejected',
  ABANDONED: 'Abandoned',
  ON_HOLD: 'On Hold',
} as const;

/**
 * If you are adding or modifying statusReason for any status,
 * please make sure to do it in backend as well
 */
export const PE_WORKFLOW_STEPS: {
  label: string;
  value: string;
  visibleToStates: string[];
  statusReason: string[];
}[] = [
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.PENDING_REQUEST,
    value: 'move-to-pending-request',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
    ],
    statusReason: [
      'State license missing',
      'DEA license missing',
      'Practice location address missing',
      'Other',
    ],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
    value: 'move-to-requested',
    visibleToStates: [PAYOR_ENROLLMENT_WORKFLOW_STATUS.PENDING_REQUEST],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
    value: 'move-to-onboarding',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
    value: 'move-to-missing-information',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.PENDING_REQUEST,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: ['Group documents missing', 'Other'],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
    value: 'move-to-information-verified',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
    value: 'move-to-submitted',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
    value: 'move-to-contracting',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
    value: 'move-to-credentialing-by-health-plan',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
    value: 'move-to-additional-information-requested',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
    value: 'move-to-other-processing',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [
      'Registering Medicare Number',
      'Obtaining DEA license',
      'Obtaining state license ',
      'Other',
    ],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.EFFECTIVE,
    value: 'move-to-effective',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.REJECTED,
    value: 'move-to-rejected',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: ['Outside HP Service Area', 'Review Rejected', 'Other'],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.ABANDONED,
    value: 'move-to-abandoned',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    ],
    statusReason: [
      'Change of Health Plan',
      'Duplicate',
      'Specialty Not Supported by Health Plan',
      'Revoked By Customer',
      'Other',
    ],
  },
  {
    label: PAYOR_ENROLLMENT_WORKFLOW_STATUS.ON_HOLD,
    value: 'move-to-on-hold',
    visibleToStates: [
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ONBOARDING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.MISSING_INFORMATION,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.INFORMATION_VERIFIED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.SUBMITTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CONTRACTING,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.CREDENTIALING_BY_HEALTH_PLAN,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.ADDITIONAL_INFORMATION_REQUESTED,
      PAYOR_ENROLLMENT_WORKFLOW_STATUS.OTHER_PROCESSING,
    ],
    statusReason: [
      'Temporarily Held',
      'To Start Re-submission',
      'Current Panel Closure',
      'Pending Resubmission (60 to 90 days)',
      'MCD / Medicare App Approval',
      'Other',
    ],
  },
];

export const ACCOUNT_TYPES = [
  'MEDICARE',
  'MEDICAID',
  'NPPES',
  'DEA',
  'CSR',
  'CAQH',
  'FSMB',
  'BOARD PORTAL',
  'BOARD CERTIFICATION',
  'ECFMG',
  'AMA',
  'IMLC',
  'FNIN',
  'UPIN',
  'OTHER',
];

export const PROFESSIONAL_ID_TYPES = [
  'Medicare Number',
  'Medicaid Number',
  'ECFMG Number',
  'Foreign National Identification Number',
  'Unique Physician Identification Number',
];

export const RACES = [
  'Alaska Native',
  'American Indian',
  'Asian',
  'Black/African American',
  'Native Hawaiian',
  'Pacific Islander',
  'White',
].sort(stringCompareFn);

export const LANGUAGES = [
  'AFAR',
  'ABKHAZ',
  'AVESTAN',
  'AFRIKAANS',
  'AKAN',
  'AMHARIC',
  'ARAGONESE',
  'ARABIC',
  'ASSAMESE',
  'AVARIC',
  'AYMARA',
  'AZERBAIJANI',
  'SOUTH AZERBAIJANI',
  'BASHKIR',
  'BELARUSIAN',
  'BULGARIAN',
  'BIHARI',
  'BISLAMA',
  'BAMBARA',
  'BENGALI - BANGLA',
  'TIBETAN STANDARD - TIBETAN - CENTRAL',
  'BRETON',
  'BOSNIAN',
  'CATALAN - VALENCIAN',
  'CHECHEN',
  'CHAMORRO',
  'CORSICAN',
  'CREE',
  'CZECH',
  'OLD CHURCH SLAVONIC - CHURCH SLAVONIC - OLD BULGARIAN',
  'CHUVASH',
  'WELSH',
  'DANISH',
  'GERMAN',
  'DIVEHI - DHIVEHI - MALDIVIAN',
  'DZONGKHA',
  'EWE',
  'GREEK (MODERN)',
  'ENGLISH',
  'ESPERANTO',
  'SPANISH',
  'CASTILIAN',
  'ESTONIAN',
  'BASQUE',
  'PERSIAN (FARSI)',
  'FULA - FULAH - PULAAR - PULAR',
  'FINNISH',
  'FIJIAN',
  'FAROESE',
  'FRENCH',
  'WESTERN FRISIAN',
  'IRISH',
  'SCOTTISH GAELIC - GAELIC',
  'GALICIAN',
  'GUARANÍ',
  'GUJARATI',
  'MANX',
  'HAUSA',
  'HEBREW (MODERN)',
  'HINDI',
  'HIRI MOTU',
  'CROATIAN',
  'HAITIAN - HAITIAN CREOLE',
  'HUNGARIAN',
  'ARMENIAN',
  'HERERO',
  'INTERLINGUA',
  'INDONESIAN',
  'INTERLINGUE',
  'IGBO',
  'NUOSU',
  'INUPIAQ',
  'IDO',
  'ICELANDIC',
  'ITALIAN',
  'INUKTITUT',
  'JAPANESE',
  'JAVANESE',
  'GEORGIAN',
  'KONGO',
  'KIKUYU - GIKUYU',
  'KWANYAMA - KUANYAMA',
  'KAZAKH',
  'KALAALLISUT - GREENLANDIC',
  'KHMER',
  'KANNADA',
  'KOREAN',
  'KANURI',
  'KASHMIRI',
  'KURDISH',
  'KOMI',
  'CORNISH',
  'KYRGYZ',
  'LATIN',
  'LUXEMBOURGISH - LETZEBURGESCH',
  'GANDA',
  'LIMBURGISH - LIMBURGAN - LIMBURGER',
  'LINGALA',
  'LAO',
  'LITHUANIAN',
  'LUBA-KATANGA',
  'LATVIAN',
  'MALAGASY',
  'MARSHALLESE',
  'MĀORI',
  'MACEDONIAN',
  'MALAYALAM',
  'MONGOLIAN',
  'MARATHI (MARĀṬHĪ)',
  'MALAY',
  'MALTESE',
  'BURMESE',
  'NAURU',
  'NORWEGIAN BOKMÅL',
  'NORTH NDEBELE',
  'NEPALI',
  'NDONGA',
  'DUTCH',
  'NORWEGIAN NYNORSK',
  'NORWEGIAN',
  'SOUTH NDEBELE',
  'NAVAJO, NAVAHO',
  'CHICHEWA - CHEWA - NYANJA',
  'OCCITAN',
  'OJIBWE, OJIBWA',
  'OROMO',
  'ORIYA',
  'OSSETIAN - OSSETIC',
  'PANJABI - PUNJABI',
  'PĀLI',
  'POLISH',
  'PASHTO - PUSHTO',
  'PORTUGUESE',
  'QUECHUA',
  'ROMANSH',
  'KIRUNDI',
  'ROMANIAN',
  'RUSSIAN',
  'KINYARWANDA',
  'SANSKRIT (SAṀSKṚTA)',
  'SARDINIAN',
  'SINDHI',
  'NORTHERN SAMI',
  'SANGO',
  'SINHALA - SINHALESE',
  'SLOVAK',
  'SLOVENE',
  'SAMOAN',
  'SHONA',
  'SOMALI',
  'ALBANIAN',
  'SERBIAN',
  'SWATI',
  'SOUTHERN SOTHO',
  'SUNDANESE',
  'SWEDISH',
  'SWAHILI',
  'TAMIL',
  'TELUGU',
  'TAJIK',
  'THAI',
  'TIGRINYA',
  'TURKMEN',
  'TAGALOG',
  'TSWANA',
  'TONGA (TONGA ISLANDS)',
  'TURKISH',
  'TSONGA',
  'TATAR',
  'TWI',
  'TAHITIAN',
  'UYGHUR - UIGHUR',
  'UKRAINIAN',
  'URDU',
  'UZBEK',
  'VENDA',
  'VIETNAMESE',
  'VOLAPÜK',
  'WALLOON',
  'WOLOF',
  'XHOSA',
  'YIDDISH',
  'YORUBA',
  'ZHUANG - CHUANG',
  'CHINESE',
  'ZULU',
  'OTHER',
]
  .map((item) => {
    return item
      .split(' ')
      .map((chunk) => capitalize(chunk))
      .join(' ');
  })
  .sort(stringCompareFn);

export const ETHNICITIES = [
  'African',
  'Caribbean',
  'Indian',
  'Melanesian',
  'Australasian/Aboriginal',
  'Chinese',
  'Guamanian',
  'Japanese',
  'Korean',
  'Polynesian',
  'European/Anglo Saxon',
  'Other Pacific Islander',
  'Latin American',
  'Arabic',
  'Vietnamese',
  'Micronesian',
  'Declined to Respond',
  'Other Hispanic',
  'US or Canadian Indian',
  'Other Asian',
  'Puerto Rican',
  'Filipino',
  'Mexican',
  'Alaskan Native',
  'Cuban',
].sort(stringCompareFn);

export const PROFESSIONAL_EXAM_CODES = [
  'USMLE',
  'FLEX',
  'NBME',
  'SPEX',
  'MCCQE',
  'NBOME',
  'NCLEX',
  'BCBA',
  'PANCE',
  'ASWB',
  'NCE',
  'NCMHCE',
];

export const TaskStatus = {
  OPEN: 'Open',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
} as const;

export const EXPIRY_DURATIONS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Expired in 30 days',
    value: '-30',
  },
  {
    label: 'Expired in 60 days',
    value: '-60',
  },
  {
    label: 'Expired in 90 days',
    value: '-90',
  },
  {
    label: 'Expired in 120 days',
    value: '-120',
  },
  {
    label: 'Expiring in 30 days',
    value: '30',
  },
  {
    label: 'Expiring in 60 days',
    value: '60',
  },
  {
    label: 'Expiring in 90 days',
    value: '90',
  },
];

export const PE_WORKFLOW_REQUEST_TYPES = {
  GROUP: 'Group',
  INDIVIDUAL: 'Individual',
};
export type PEWorkflowRequestType =
  (typeof PE_WORKFLOW_REQUEST_TYPES)[keyof typeof PE_WORKFLOW_REQUEST_TYPES];

const affiliationTypes = ['Direct', 'Delegated'];

interface TabConfig {
  id: string;
  label: string;
  cards?: DataCardConfig[];
}

interface WorkflowTabsConfig {
  OVERVIEW: TabConfig;
  MISSING_INFORMATION: TabConfig;
  COMMUNICATION: TabConfig;
  ACTIVITY_LOG: TabConfig;
  // PROVIDERS: TabConfig;
  // OTHER_WORKFLOWS: TabConfig;
}

export const PE_GROUP_WORKFLOW_TABS: WorkflowTabsConfig = {
  OVERVIEW: {
    id: 'overview',
    label: 'Overview',
    cards: [
      {
        editable: true,
        id: 'overview',
        title: 'General',
        cardType: 'form',
        skipStatusCheck: true,
        fields: {
          affiliation: {
            label: 'Affiliation',
            type: 'select',
            options: affiliationTypes,
            editableRoles: [ENTITY_MANAGER, PE_LEAD_ROLE],
          },
          tin: {
            label: 'TIN',
            editableRoles: [ENTITY_MANAGER, PE_LEAD_ROLE],
          },
          npi: {
            label: 'Group NPI',
            editableRoles: [ENTITY_MANAGER, PE_LEAD_ROLE],
          },
          specialty: {
            label: 'Specialty',
            type: 'select',
            options: specialities,
          },
          dba: {
            label: 'DBA Name',
            editableRoles: [ENTITY_MANAGER, PE_LEAD_ROLE],
          },
          externalGroupId: {
            label: 'External Group ID',
            editableRoles: [ENTITY_MANAGER, PE_LEAD_ROLE],
          },
          networkEffectiveDate: {
            label: 'Corporate Effective Date',
            type: 'date',
            editable: true,
          },
          // TODO: Group Types?
          practiceType: { label: 'Group Type' },
          states: {
            label: 'States',
            type: 'select',
            options: stateCodes,
            editable: false,
          },
          primaryPracticeLocation: {
            label: 'Primary Practice Location',
            editable: false,
          },
          locationCount: { label: 'Locations', editable: false },
          effectiveProvidersCount: {
            label: 'Enrolled Providers',
            editable: false,
          },
          unenrolledProviderCount: {
            label: 'Not Enrolled Providers',
            editable: false,
          },
          providersCount: {
            label: 'PE In-progress Providers',
            editable: false,
          },
        },
        actions: {
          edit: true,
          add: false,
          delete: false,
        },
      },
      {
        editable: true,
        id: 'workflowInfo',
        title: 'Workflow info',
        cardType: 'form',
        skipStatusCheck: true,
        fields: {
          assignedTo: {
            label: 'Analyst',
            type: 'select',
            editable: true,
            options: [],
          },
          daysOpen: { label: 'Days Open', type: 'number', editable: false },
          applicationId: {
            label: 'Application ID',
            editable: false,
          },
          resubmissionParentWorkflowId: {
            label: 'Initial Application ID',
            editable: false,
            type: 'link',
            baseUri: '/payor-enrollments',
            hideWhenValueIsEmpty: true,
          },
          outreachAttempts: { label: 'Outreach Attempts', editable: false },
          nextActivityDate: {
            label: 'Next Activity',
            type: 'date-next-activity',
            customViewField: true,
            editable: false,
            colSpacing: {
              xs: 2,
              sm: 4,
              md: 4,
              lg: 4,
            },
          },
          previousActivityDate: { label: 'Last Activity', editable: false },
          lastActivityNote: { label: 'Last Activity Note', editable: false },
          status: { label: 'Status', customViewField: true, editable: false },
        },
        actions: {
          edit: true,
          add: false,
          delete: false,
        },
        isEditable: true,
      },
      {
        id: 'timeline',
        title: 'Timeline',
      },
      {
        id: 'tasks',
        title: 'Tasks',
      },
      {
        id: 'planDetails',
        title: 'Request details',
        fields: {
          jurisdiction: { label: 'Jurisdiction' },
          lineOfBusiness: { label: 'Line Of Business' },
          payorName: { label: 'Payor Name' },
          planName: { label: 'Plan' },
        },
      },
      {
        editable: true,
        id: 'logins',
        title: 'Logins',
        cardType: 'form',
        skipStatusCheck: true,
        fields: {
          accountType: {
            label: 'Name',
            type: 'select',
            options: ACCOUNT_TYPES,
          },
          link: { label: 'Link' },
          username: { label: 'Username' },
          password: { label: 'Password' },
        },
        actions: {
          edit: true,
          add: true,
          delete: true,
        },
      },
      {
        id: 'enrollmentContracts',
        title: 'Enrollments & Contracts',
      },
      {
        id: 'professionalIds',
        title: 'Professional IDs',
        cardType: 'table',
        fields: {
          type: {
            label: 'Type',
            type: 'select',
            options: PROFESSIONAL_ID_TYPES,
          },
          note: { label: 'Notes' },
          number: { label: 'Number' },
          issueDate: { label: 'Issue Date', type: 'date', utcDate: true },
          expirationDate: {
            label: 'Expiration Date',
            type: 'date',
            utcDate: true,
          },
          state: {
            label: 'State',
            type: 'select',
            options: stateCodes,
          },
          source: { label: 'Source' },
        },
        isEditable: true,
      },
      {
        id: 'documents',
        title: 'Documents',
        fields: {
          fileType: {
            label: 'File Type',
            type: 'select',
            options: AllSupportingDocumentsOfEntity,
          },
          file: {
            label: 'File',
            type: 'file',
          },
        },
      },
      {
        id: 'providers',
        title: 'Provider PE Applications',
      },
      {
        id: 'locations',
        title: 'Locations',
      },
      {
        id: 'billingAndTax',
        title: 'Billing and tax',
      },
      {
        id: 'other',
        title: 'Other Information',
      },
    ],
  },
  MISSING_INFORMATION: {
    id: 'missingInformation',
    label: 'Missing information',
  },
  COMMUNICATION: {
    id: 'communication',
    label: 'Communication',
  },
  ACTIVITY_LOG: {
    id: 'activityLog',
    label: 'Activity log',
  },
  // PROVIDERS: {
  //   id: 'providers',
  //   label: 'Providers',
  // },
  // OTHER_WORKFLOWS: {
  //   id: 'otherWorkflows',
  //   label: 'Other workflows',
  // },
};

export const ADDRESS_SOURCE_TITLES = {
  caqh: 'CAQH',
  manual: 'Manual',
};

export const PsvRequirementSections = {
  assignment: 'Assignment',
  overview: 'Overview',
  credentialingTimeline: 'Credentialing Timeline',
  stateLicenses: 'State Licenses',
  dea: 'DEA/CDS',
  boardCertifications: 'Board Certification',
  nationalPractitionerDataBank: 'National Practitioner Data Bank',
  licensureActions: 'Licensure Actions',
  sanctions: 'Sanctions',
  malpracticeInsurance: 'Malpractice Insurance',
  appVerification: 'App Verification',
  training: 'Education and Training',
  supportingDocuments: 'Supporting Documents',
  other: 'Other',

  // POC sections
  onboardingApplication: 'Onboarding Application',
  amaPhysicianProfile: 'AMA physician profile',
  releaseAndAttestation: 'Release and Attestation',
  licenses: 'License(s)',
  education: 'Education',
  boardCertification: 'Board certification',
  hospitalAffiliation: 'Hospital affiliation',
  ecfmgCertificate: 'ECFMG certificate (if applicable)',
  certificateOfInsurance: 'Certificate of Insurance',
  cv: 'CV',
  npi: 'NPI',
  paPatch: 'PA PATCH',
  facis: 'FACIS',
  preclusionList: 'Preclusion List',
  npdb: 'NPDB',
};

export const NOTIFICATION_TYPE = {
  REAL_TIME: 'REAL_TIME',
  DIGEST: 'DIGEST',
};

export const NOTIFICATION_EVENT = {
  PAYOR_ENROLLMENT_WORKFLOW_CREATED: 'create:payor-enrollment-workflow',
  PAYOR_ENROLLMENT_WORKFLOW_ASSIGNMENT:
    'update:payor-enrollment-workflow:assignment',
  PAYOR_ENROLLMENT_WORKFLOW_STATUS: 'update:payor-enrollment-workflow:status',
  TASK_CREATED: 'create:task',
  TASK_ASSIGNMENT: 'update:task:assignment',
  TASK_REASSIGNMENT: 'update:task:reassignment',
  TASK_COMPLETION: 'update:task:status:completed',
  TASK_CANCELLED: 'update:task:status:cancelled',
  TASK_NOTE_ADDED: 'create:task:note',
  TASK_ATTACHMENT_UPLOADED: 'upload:task:attachment',
} as const;

export const NOTIFICATION_CONFIG_ITEMS: INotificationConfigItem[] = [
  {
    id: 'notificationFrequencyInHours',
    label: 'Notification Frequency',
  },
  {
    id: 'create:payor-enrollment-workflow',
    label: 'PE application creation',
  },
  {
    id: 'update:payor-enrollment-workflow:assignment',
    label: 'PE application assignment',
  },
  {
    id: 'update:payor-enrollment-workflow:status',
    label: 'PE application status change',
  },
  {
    id: 'update:task:assignment',
    label: 'Task creation and assignment',
  },
  {
    id: 'update:task:status:completed',
    label: 'Task completion',
  },
  {
    id: 'update:task:status:cancelled',
    label: 'Task cancellation',
  },
  {
    id: 'update:task:reassignment',
    label: 'Task reassignment',
  },
  {
    id: 'create:task:note',
    label: 'Task note added (Real time only)',
  },
  {
    id: 'upload:task:attachment',
    label: 'Task attachment uploaded (Real time only)',
  },
];

export const NOTIFICATION_FREQUENCY_OPTIONS = [
  {
    label: '2 hours',
    value: 2,
  },
  {
    label: '4 hours',
    value: 4,
  },
  {
    label: '6 hours',
    value: 6,
  },
  {
    label: '12 hours',
    value: 12,
  },
  {
    label: '24 hours',
    value: 24,
  },
];

export const SUPPORTING_DOCUMENTS_TABS = {
  GROUP: 'Group Documents',
  PROVIDER: 'Provider Documents',
};

export type SupportingDocumentsTypes =
  (typeof SUPPORTING_DOCUMENTS_TABS)[keyof typeof SUPPORTING_DOCUMENTS_TABS];

export const PEGroupWorkflowSectionsMissingInformationConfig =
  PE_GROUP_WORKFLOW_TABS.OVERVIEW.cards
    ?.map((item) => {
      const ignoreList = ['overview', 'workflowInfo', 'planDetails'];
      const idMappings: { [key: string]: string } = {
        professionalIds: 'professional-ids',
      };

      if (!item.fields || ignoreList.includes(item.id)) {
        return null;
      }

      const fieldsIgnoreList = ['flag'];

      const section: {
        id: string;
        title: string;
        fieldConfig: MissingInfoFormFielsConfig;
      } = {
        id: idMappings[item.id] || item.id,
        title: item.title,
        fieldConfig: {
          title: item.title,
          section: item.title,
          infoText: ``,
          valueType: 'object|array',
          canHaveMultipleEntries: true,
          fields: [],
        },
      };

      section.fieldConfig.fields = Object.keys(item.fields)
        .map((fieldName) => {
          const fields = item.fields as any;
          if (
            !fields ||
            fields?.[fieldName].editable === false ||
            fieldsIgnoreList.includes(fieldName)
          ) {
            return;
          }
          const field = fields[fieldName];
          return {
            name: fieldName,
            type: field.type || 'input',
            label: field.label,
            options: field.options,
          };
        })
        .filter(Boolean) as any;

      return section;
    })
    .filter(Boolean);

export const MASKED_PASSWORD = '*************';

// Max 20MB file size
export const MAX_FILE_SIZE = 20 * 1024 * 1024;

// WellspanPOC rules-engine check-list
export const POC_PSV_TASKS: PsvChecklist = {
  tasks: [
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'Application',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'CAQH',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'Code of Conduct',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description:
        'Disclosure and Authorization to Obtain Criminal Background Reports',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'LMS',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'MD Medicaid Disclosure',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'Medicare/Medicaid/Champus Statement Acknowledgment',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'PA Medicaid Disclosure',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'PHC Supplement',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'Proxy',
    },
    {
      sectionDescription: 'Onboarding Application',
      section: 'onboardingApplication',
      description: 'Health MSO Orientation',
    },
    {
      sectionDescription: 'AMA physician profile',
      section: 'amaPhysicianProfile',
      description: 'Action notifications reported to AMA',
    },
    {
      sectionDescription: 'Release and Attestation',
      section: 'releaseAndAttestation',
      description: '',
    },
    {
      sectionDescription: 'License(s)',
      section: 'licenses',
      description: '',
    },
    {
      sectionDescription: 'DEA',
      section: 'dea',
      description: '',
    },
    {
      sectionDescription: 'Education',
      section: 'education',
      description: 'Current and/or historical medical school',
    },
    {
      sectionDescription: 'Education',
      section: 'education',
      description:
        'Current and/or historical ACGME-accredited graduate medical training programs',
    },
    {
      sectionDescription: 'Board certification',
      section: 'boardCertification',
      description: '',
    },
    {
      sectionDescription: 'Hospital affiliation',
      section: 'hospitalAffiliation',
      description: '',
    },
    {
      sectionDescription: 'ECFMG certificate (if applicable)',
      section: 'ecfmgCertificate',
      description: '',
    },
    {
      sectionDescription: 'Certificate of Insurance',
      section: 'certificateOfInsurance',
      description: '',
    },
    {
      sectionDescription: 'CV',
      section: 'cv',
      description: '',
    },
    {
      sectionDescription: 'NPI',
      section: 'npi',
      description: '',
    },
    {
      sectionDescription: 'PA PATCH',
      section: 'paPatch',
      description: '',
    },
    {
      sectionDescription: 'FACIS',
      section: 'facis',
      description: '',
    },
    {
      sectionDescription: 'Preclusion List',
      section: 'preclusionList',
      description: '',
    },
    {
      sectionDescription: 'NPDB for network',
      section: 'npdb',
      description: '',
    },
  ],
};

export const WELLSPAN_POC = {
  NPDB_REPORT: {
    providerId: 'vhp2STKrAqCVNZGyhUup',
    organizationId: 'qhgozzqeVKM4jwRKP3be',
    fileUrl:
      'https://storage.cloud.google.com/certifyos-dev-app-data/qhgozzqeVKM4jwRKP3be/credentialing/272CA5p2yrWmO5PNfHx1/npdb_YH%20NPDB.pdf',
  },
  ORG_ENTITIES: [
    { label: 'SCP', value: 'scp' },
    { label: 'York Hospital', value: 'york' },
  ],
};

export const UNASSIGNED = {
  label: 'Unassigned',
  value: 'nassigned',
};

export const CRED_STATUSES_ALLOWED_TO_CHANGE_ASSIGNEE = [
  CredSteps.IN_PROGRESS,
  CredSteps.RECREDENTIALING_IN_PROGRESS,
  CredSteps.NOT_STARTED,
  CredSteps.OUTREACH_IN_PROGRESS,
  CredSteps.DATA_MISSING,
];
export const PE_RESUBMISSION_REASONS = [
  'Collected Required Information',
  'Improved Eligibility',
  'Service coverage addressed',
  'Appeal-worthy',
  'Other',
];

export const PE_LISTING_QUICK_FILTERS = {
  REJECTED_WORKFLOWS: 'rejected',
};
export type PayorEnrollmentQuickFilter =
  (typeof PE_LISTING_QUICK_FILTERS)[keyof typeof PE_LISTING_QUICK_FILTERS];

export const PE_CYCLE = {
  INITIAL: 'Initial',
  RESUBMISSION: 'Resubmission',
} as const;

export const PE_CYCLES = [PE_CYCLE.INITIAL, PE_CYCLE.RESUBMISSION];

export type PayorEnrollmentCycle = (typeof PE_CYCLE)[keyof typeof PE_CYCLE];

export const PREFERRED_TIMEZONE = 'America/New_York';

export const PROVIDER_ENROLLMENT_TERMINATION_REASONS = [
  'Termed Provider',
  'Provider moved to another place',
  'Clinic or group closed',
];

export const GROUP_ENROLLMENT_TERMINATION_REASONS = [
  'Termed Group',
  'Clinic or group closed',
];

export const PLATFORM_TABLES = {
  CREDENTIALING_WORKFLOWS: 'credentialing-workflows',
  MONITORING_WORKFLOWS: 'monitoring-workflows',
  FACILITIES: 'facilities',
  FACILITY_CREDENTIALING_WORKFLOWS: 'facility-credentialing-workflows',
  PAYOR_ENROLLMENT_WORKFLOWS: 'payor-enrollment-workflows',
};
export type PlatformTable =
  (typeof PLATFORM_TABLES)[keyof typeof PLATFORM_TABLES];
