import { apiResponseDateToDate, formatDate } from '#/Utils/date';
import { LicensingRequestFormValue } from '#/components/Licensing/ExistingProviderLicensingRequestsManager/LicensingRequestsWorkflowsStep';
import { NewProviderLicensingRequestFormValue } from '#/components/Licensing/NewProviderLicensingRequestsManager/NewProviderLicensingRequestForm/NewProviderLicensingRequestForm';
import { PossibleItemFlags } from '#/types';
import { LicensingWorkflowResponse } from '#/types/licensing-workflows.types';
import { differenceInDays } from 'date-fns';
import { first, isEmpty } from 'lodash';

export function transformNewProviderLicensingRequestToAPIRequestPayload(
  formValue: NewProviderLicensingRequestFormValue,
) {
  const homeAddress = formValue.addresses.find((item) => item.type === 'Home');
  const workAddress = formValue.addresses.find((item) => item.type === 'Work');
  const homeContact = formValue.contacts.find((item) => item.type === 'Home');
  const workContact = formValue.contacts.find((item) => item.type === 'Work');
  const mobileContact = formValue.contacts.find(
    (item) => item.type === 'Mobile',
  );
  const primaryEmail = formValue.contacts.find((item) =>
    Boolean(item.email?.trim()),
  )?.email;

  return {
    licensesRequested: [...formValue.licenses],
    firstName: formValue.provider.firstName,
    lastName: formValue.provider.lastName,
    middleName: formValue.provider.middleName,
    npi: formValue.provider.npi,
    providerType: formValue.provider.providerType,
    caqhProviderId: formValue.provider.caqhProviderId,
    allProviderStates: formValue.provider.states?.join(','),
    homeAddress,
    workAddress,
    primaryEmail,
    workPhoneNumber: workContact?.phoneNumber,
    homePhoneNumber: homeContact?.phoneNumber,
    mobileNumber: mobileContact?.phoneNumber,
  };
}

export function transformLicenseRequestFormValueToPayload(
  formValue: LicensingRequestFormValue,
) {
  return formValue.licenses.map((item) => {
    return {
      providerId: item.providerId,
      npi: item.npi,
      licensesRequested: [
        {
          licenseState: item.licenseState,
          licenseType: item.licenseType,
          isNew: true,
        },
      ],
    };
  });
}

export function transformLicensingWorkflowDetails(
  response: LicensingWorkflowResponse,
) {
  const stateLicenses = response['state-licenses'] || [];
  const boardCertifications = response['board-certifications'] || [];
  const licensureActions = response['licensure-actions'] || [];
  const sanctions = response['sanctions'] || [];
  const dea = response['dea-data'] || [];
  const insurances = response['malpractice-insurances'] || [];
  const perf = response['performance-data'] || [];
  const nationalProviderDataBank = response['npdb-data'] || [];
  const appVerifications = response['application-verifications'] || [];
  const professionalIds = response['professional-ids'] || [];
  const eduTrainings = response['education-trainings'] || [];
  const supportingDocuments = response['supporting-documents'] || [];
  const professionalExams = response['professional-exams'] || [];
  const logins = response.logins || [];
  const lastActivity =
    response.assignment.analyst.lastActivity ||
    response.assignment.specialist.lastActivity;
  const assignedOn =
    response.assignment.analyst.assignedOn ||
    response.assignment.specialist.assignedOn;
  const assignedOnDate = new Date(assignedOn);
  const daysOpen = !!assignedOn
    ? differenceInDays(new Date(), assignedOnDate)
    : '';

  const calculateFlag = (item: PossibleItemFlags) => {
    const isProviderExposedToNewFlags = 'currentFlags' in response;
    let calculatedFlag;

    if (item.flags || isProviderExposedToNewFlags) {
      calculatedFlag = !isEmpty(item.flags)
        ? first(Object.values(item.flags))
        : {};
    } else {
      calculatedFlag = { ...item.flag };
    }

    if (isEmpty(calculatedFlag)) {
      return {};
    }

    return {
      ...calculatedFlag,
    };
  };

  return {
    ...response,
    ssn: response.hasSSN ? '******' : '',
    race: response.race?.[0]?.raceEthnicityLevel1,
    otherLanguages: response.otherLanguages?.map((item) => item.name) ?? [],
    dateOfBirth: response.dateOfBirth && formatDate(response.dateOfBirth),
    overview: [
      {
        id: 'overview',
        firstName: response.firstName,
        lastName: response.lastName,

        dateOfBirth: response.dateOfBirth && formatDate(response.dateOfBirth),
        npi: response.npi,
        providerType: response.providerType,
        caqhProviderId: response.caqhProviderId,
        caqhProviderEmail: response.primaryEmail,
        assignedStates: response.assignedStates,
      },
    ],
    assignmentInfo: {
      id: 'assignmentInfo',
      analyst: response.assignment?.analyst?.assignedToId,
      specialist: response.assignment?.specialist?.assignedToId,
      lastActivity,
      daysOpen,
    },
    'workflow-details': response['workflow-details']
      ? {
          ...response['workflow-details'],
          createdAt: formatDate(response['workflow-details'].createdAt),
          completedAt:
            response['workflow-details'].completedAt &&
            formatDate(response['workflow-details'].completedAt),
        }
      : {},
    'state-licenses': stateLicenses.map((item) => ({
      id: item.id,
      state: item.state,
      expirationDate: formatDate(item.expirationDate as unknown as string),
      issueDate: formatDate(item.issueDate as unknown as string),
      type: item.type,
      status: item.status,
      licenseNumber: item.licenseNumber,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'dea-data': dea.map((item, idx) => ({
      id: item.id || idx.toString(), // Added index as fallback if the id is missing as it breaks the UI
      state: item.state,
      deaNumber: item.deaNumber,
      expirationDate: formatDate(item.expirationDate as unknown as string),
      providerType: item.providerType,
      source: item.source,
      status: item.status,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'board-certifications': boardCertifications.map((item) => ({
      id: item.id,
      expirationDate: formatDate(item.expirationDate as unknown as string),
      issueDate: formatDate(item.issueDate as unknown as string),
      subSpecialty: item.subSpecialty,
      durationType: item.durationType,
      status: item.status,
      specialty: item.specialty,
      document: item.document,
      moc: item.moc,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'licensure-actions': licensureActions.map((item) => ({
      id: item.id,
      lastActionDate: formatDate(item.lastActionDate as unknown as string),
      reportDate: formatDate(item.reportDate as unknown as string),
      actionType: item.actionType,
      source: item.source,
      state: item.state,
      details: item.details,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    sanctions: sanctions.map((item) => ({
      id: item.id,
      reportDate: formatDate(item.reportDate as unknown as string),
      typeOfAction: item.typeOfAction,
      sanctionType: item.sanctionType,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'malpractice-insurances': insurances.map((item) => ({
      expirationDate: formatDate(item.expirationDate as unknown as string),
      id: item.id,
      policyNumber: item.policyNumber,
      source: item.source,
      status: item.status,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'performance-data': perf.map((item) => ({
      id: item.id,
      complaints: item.complaints,
      clinical: item.clinical,
      service: item.service,
      benefits: item.benefits,
      claims: item.claims,
      accessIssues: item.accessIssues,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'npdb-data': nationalProviderDataBank.map((item) => ({
      id: item.id,
      typeOfAction: item.typeOfAction,
      reportType: item.reportType,
      deceased: item?.reportData?.deceased,
      totalPaymentForThisPractitioner:
        item?.reportData?.totalPaymentForThisPractitioner ||
        item?.reportData?.amountOfPractitioner ||
        item?.reportData?.amount,
      lastReportDate: formatDate(item.lastReportDate as unknown as string),
      reportDate: formatDate(item.reportDate as unknown as string),
      processDate: formatDate(item.processDate as unknown as string),
      document: item.document,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'application-verifications': appVerifications.map((item) => ({
      id: item.id,
      verificationType: item.verificationType,
      explanation: item.explanation,
      source: item.source,
      disclosureQuestions: item?.disclosureQuestions,
      admittingPrivileges: item?.admittingPrivileges,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'professional-ids': professionalIds.map((item) => ({
      id: item.id,
      type: item.type,
      note: item.note,
      number: item.number,
      flag: calculateFlag(item as PossibleItemFlags),
      issueDate: formatDate(item.issueDate as unknown as string),
      expirationDate: formatDate(item.expirationDate as unknown as string),
    })),
    'professional-exams': professionalExams.map((item) => ({
      id: item.id,
      type: item.type,
      passed: item.passed,
      step: item.step,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
      attemptedDate: formatDate(item.attemptedDate as unknown as string),
    })),
    'education-trainings': eduTrainings.map((item) => ({
      id: item.id,
      dateOfScreenshot: formatDate(item.dateOfScreenshot as unknown as string),
      document: item.document,
      source: item.source,
      type: item.type,
      specialty: item.specialty,
      institution: item.institution,
      startDate: formatDate(item.startDate as unknown as string),
      endDate: formatDate(item.endDate as unknown as string),
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedAt: apiResponseDateToDate(item.verifiedAt as unknown as string),
      verifiedByFullName: item.verifiedByFullName,
    })),
    'supporting-documents': supportingDocuments.map((item) => ({
      createdAt: new Date(item.createdAt as unknown as string),
      createdBy: item.createdBy,
      fileType: item.fileType,
      fileUrl: item.fileUrl,
      originalFilename: item.originalFilename,
      gsUrl: item.gsUrl,
      id: item.id,
      flag: item.flag,
      subCollectionReference: item.subCollectionReference,
    })),
    notes: (response.notes ?? []).map((note) => {
      return {
        ...note,
        createdAt: apiResponseDateToDate(note?.createdAt),
      };
    }),
    logins,
  };
}
