import format from 'date-fns/format';
import {
  capitalize,
  first,
  get,
  groupBy,
  isEmpty,
  omit,
  pick,
  pickBy,
} from 'lodash';
import {
  CredentialingStatusType,
  FetchProviderListApiResponse,
  FetchProviderListResponse,
  Flag,
  FlagApiResponse,
  FlagOptions,
  OrgEntityDetails,
  PossibleItemFlags,
  ProviderListItem,
} from '#/types';
import { ProviderFormData } from '../components/ProviderList/ProviderListFilterForm';
import {
  ErrorResponseFromServer,
  ErrorResponseItem,
  ProviderDetails,
  ProviderDetailsAPIResponse,
  ErrorsWarningsData,
} from '../types';
import {
  dateDiffInDays,
  dateInUtc,
  firestoreDateInUtc,
  apiResponseDateToDate,
  formatDateForBackend,
  formatDateTime,
} from '#/Utils/date';
import { transformCredentialingWorkflowTimeline } from './credentialing.transformer';

import { FirestoreTimestamp } from '#/Utils/types';
import { CredSteps, WELLSPAN_POC } from '#/Utils/Constants';
import { useFlags } from 'flagsmith/react';
import { CollapsedDataGridProps } from '#/components/CollapseTable';

interface ProviderFormDataRequest
  extends Omit<
    ProviderFormData,
    | 'createdAtStart'
    | 'createdAtEnd'
    | 'credentialedStart'
    | 'credentialedEnd'
    | 'nextCredentialingStart'
    | 'nextCredentialingEnd'
    | 'credentialingStatusUpdatedAtStart'
    | 'credentialingStatusUpdatedAtEnd'
  > {
  credentialedEnd?: string;
  credentialedStart?: string;
  createdAtStart?: string;
  createdAtEnd?: string;
  nextCredentialingStart?: string;
  nextCredentialingEnd?: string;
}
export const transformProvidersQueryParams = (formData: ProviderFormData) => {
  const params: ProviderFormDataRequest = omit(formData, [
    'createdAtStart',
    'createdAtEnd',
    'credentialedStart',
    'credentialedEnd',
    'nextCredentialingStart',
    'nextCredentialingEnd',
    'credentialingStatusUpdatedAtStart',
    'credentialingStatusUpdatedAtEnd',
  ]);

  if (formData) {
    if (formData.createdAtStart) {
      params.createdAtStart = formatDateForBackend(formData.createdAtStart!);
    }
    if (formData.createdAtEnd) {
      params.createdAtEnd = formatDateForBackend(formData.createdAtEnd!);
    }
    if (formData.credentialedStart) {
      params.credentialedStart = formatDateForBackend(
        formData.credentialedStart!,
      );
    }
    if (formData.credentialedEnd) {
      params.credentialedEnd = formatDateForBackend(formData.credentialedEnd!);
    }
    if (formData.nextCredentialingStart) {
      params.nextCredentialingStart = formatDateForBackend(
        formData.nextCredentialingStart!,
      );
    }
    if (formData.nextCredentialingEnd) {
      params.nextCredentialingEnd = formatDateForBackend(
        formData.nextCredentialingEnd!,
      );
    }
  }

  return pickBy(params);
};

export const transformProvidersResponse = (
  response: FetchProviderListApiResponse,
): FetchProviderListResponse => {
  const { data, count } = response;
  const records: ProviderListItem[] = data.map((providerResponseItem) => {
    const listItem: ProviderListItem = {
      id: providerResponseItem.id,
      editProviderId: providerResponseItem.editProviderId,
      providerId: providerResponseItem.providerId,
      firstName: providerResponseItem.firstName,
      middleName: providerResponseItem.middleName,
      lastName: providerResponseItem.lastName,
      npi: providerResponseItem.npi,
      assignedStates: providerResponseItem.assignedStates,
      providerStatus: providerResponseItem.providerStatus,
      states: providerResponseItem.states || [],
      allProviderStates: providerResponseItem.states || [],
      caqhProviderId: providerResponseItem.caqhProviderId,
      updatedAt: apiResponseDateToDate(providerResponseItem.updatedAt),
      isFlagged: providerResponseItem.isFlagged,
      providerType: providerResponseItem?.providerType,
      fileType: providerResponseItem.fileType,
      credentialingDate: apiResponseDateToDate(
        providerResponseItem?.credentialingWorkflowTimeline
          ?.lastCredentialedDate,
      ),
      credentialingWorkflowTimeline: transformCredentialingWorkflowTimeline(
        providerResponseItem.credentialingWorkflowTimeline,
      ),
      credentialingStatus: providerResponseItem?.credentialingStatus,
      createdBy: providerResponseItem.createdBy,
      createdAt: apiResponseDateToDate(providerResponseItem.createdAt),
      lastFetchDate: apiResponseDateToDate(providerResponseItem.lastFetchDate),
      currentFlags: providerResponseItem.currentFlags
        ? Object.fromEntries(
            Object.entries(providerResponseItem.currentFlags).map(
              ([flagId, flag]) => [flagId, transformFlagApiResponse(flag)],
            ),
          )
        : {},
    };
    if (providerResponseItem.inProgressLicensingWorkflows !== undefined) {
      listItem.inProgressLicensingWorkflows =
        providerResponseItem.inProgressLicensingWorkflows;
    }
    if (providerResponseItem.submittedLicensingWorkflows !== undefined) {
      listItem.submittedLicensingWorkflows =
        providerResponseItem.submittedLicensingWorkflows;
    }
    if (providerResponseItem.completedLicensingWorkflows !== undefined) {
      listItem.completedLicensingWorkflows =
        providerResponseItem.completedLicensingWorkflows;
    }
    return listItem;
  });
  return { data: records, count };
};

const findNamesMismatchFlag = (
  currentFlags: Record<string, Flag> | undefined,
) => {
  const flag = Object.values(currentFlags || {}).find(
    (flag) => flag.flagType === 'NAMES_MISMATCH',
  );
  if (!flag) {
    return undefined;
  }
  return pick(flag, ['id', 'active', 'flagType', 'description'] as const);
};

export const transformFlagApiResponse = (flag: FlagApiResponse): Flag => {
  return {
    ...flag,
    modifiedActiveAt: apiResponseDateToDate(flag.modifiedActiveAt),
    modifiedReadAt: apiResponseDateToDate(flag.modifiedReadAt),
  };
};

const generateErrorMessage = (response: ErrorResponseFromServer) => {
  let result: ErrorResponseItem[] = [];
  return Object.entries(groupBy(response, 'reason')).reduce(
    (acc, [key, val]) => {
      const err = {
        reason: key,
        providerIds: val.map((v) => v.providerId),
        providerNames: val.map((p) => p.providerName),
      };
      acc.push(err);
      return acc;
    },
    result,
  );
};

export const transformTerminationResponse = (
  response: ErrorResponseFromServer,
): ErrorResponseItem[] | string => {
  if (Array.isArray(response)) {
    return generateErrorMessage(response);
  }

  return `Providers terminated successfully`;
};

export const transformUnterminationResponse = (
  response: ErrorResponseFromServer,
): ErrorResponseItem[] | string => {
  if (Array.isArray(response)) {
    return generateErrorMessage(response);
  }

  return `Providers unterminated successfully`;
};

export const transformProviderDetails = (
  response: ProviderDetailsAPIResponse,
  isMonitoring?: boolean,
): ProviderDetails => {
  const stateLicenses = response['state-licenses'] || [];
  const boardCertifications = response['board-certifications'] || [];
  const currentFlags = response['currentFlags'];
  const licensureActions = response['licensure-actions'] || [];
  const sanctions = response['sanctions'] || [];
  const dea = response['dea-data'] || [];
  const insurances = response['malpractice-insurances'] || [];
  const perf = response['performance-data'] || [];
  const nationalPractitionerDataBank = response['npdb-data'] || [];
  const appVerifications = response['application-verifications'] || [];
  const eduTrainings = response['education-trainings'] || [];
  const hospitalAffiliations = response['hospital-affiliation'] || [];
  const errorsWarnings = response['data-sources'] || [];
  const supportingDocuments = response['supporting-documents'] || [];
  const flagsObjects = response?.flagsObjects || [];
  const flags = response?.flags || {};
  const credentialingWorkflowTimeline = response?.credentialingWorkflowTimeline;
  const outreachReasons =
    response?.credentialingWorkflow?.outreachReasons || [];
  const lastFetchDate = apiResponseDateToDate(response.lastFetchDate);
  const caqhData = response?.['caqh-data'] || [];
  const lastAttDate = get(
    caqhData,
    '0.practitionerProfileDetail.lastAttestationDate',
  );
  let attestationDate: Date | undefined = lastAttDate
    ? new Date(lastAttDate)
    : undefined;

  const verifications = response?.verifications;

  const credentialingStatus = (response?.credentialingStatus ||
    response?.credentialingWorkflow?.onStep?.title) as CredentialingStatusType;

  const calculateFlag = (
    item: PossibleItemFlags,
    excludeSecondarySource?: boolean,
  ) => {
    const isProviderImportedOrInProgress = [
      CredSteps.NOT_SENT_TO_CREDENTIALING,
      CredSteps.IN_PROGRESS,
      CredSteps.RECREDENTIALING_IN_PROGRESS,
      CredSteps.CREDENTIALING_RESTARTED,
      CredSteps.DATA_MISSING,
      'Data Missing', // Need to make casing consistent across the board
      CredSteps.OUTREACH_IN_PROGRESS,
    ].includes(credentialingStatus);

    const isProviderExposedToNewFlags = 'currentFlags' in response;
    let calculatedFlag;

    if (item.flags || isProviderExposedToNewFlags) {
      const flagItems = Object.values(item.flags || {});
      const activeFlags = flagItems.filter((x) => Boolean(x.active));

      calculatedFlag = !isEmpty(item.flags)
        ? excludeSecondarySource
          ? first(
              flagItems.filter(
                (x) =>
                  Boolean(x.active) && !x.flagType.includes('SECONDARY_SOURCE'),
              ),
            )
          : first(activeFlags.length ? activeFlags : flagItems)
        : {};
    } else {
      calculatedFlag = { ...item.flag };
    }

    if (isEmpty(calculatedFlag)) {
      return {};
    }

    return {
      ...calculatedFlag,
      showMoreActionsAsFlagHasIdAndWorkflowInImportedOrInprogressStatus:
        Boolean((calculatedFlag as Flag).id) && isProviderImportedOrInProgress,
    };
  };

  return {
    id: response.id,
    firstName: response.firstName,
    lastName: response.lastName,
    middleName: response.middleName,

    providerType: response.providerType,
    providerId: response.providerId,
    outreachReasons,
    hasEditProvider: response.hasEditProvider,
    providerStatus: response.providerStatus,
    flagsObjects,
    flags,
    currentFlags,
    caqhData,
    updatedBy: response.updatedBy,
    updatedAt: apiResponseDateToDate(response.updatedAt),
    credentialingStatus,
    isCredentialingWorkflowCompleted:
      response.credentialingWorkflow?.isCompleted || false,
    fileType: response.fileType,
    defaultFileTypeConfigOverrides: response.defaultFileTypeConfigOverrides,
    fileTypeAutoCalculatedValue: response.fileTypeAutoCalculatedValue,
    fileTypeManuallyOverridden: response.fileTypeManuallyOverridden,
    verifications: verifications,
    nppes: response.nppes && response.nppes[0],
    businessPurpose: response.businessPurpose,
    overview: [
      {
        id: 'overview',
        firstName: response.firstName,
        lastName: response.lastName,
        middleName: response.middleName,
        dateOfBirth:
          response.dateOfBirth && firestoreDateInUtc(response.dateOfBirth),
        providerStatus: response.providerStatus,
        npi: response.npi,
        providerType: response.providerType,
        fileType: response?.fileType,
        caqhProviderId: response.caqhProviderId,
        primaryEmail: response.primaryEmail,
        homeAddress: response.homeAddress,
        lastFetchStatus: lastFetchDate
          ? `${capitalize(response.lastFetchStatus)}: ${format(
              lastFetchDate,
              'MM/dd/yyyy @ hh:mm:ssa',
            )}`
          : '',
        lastFetchDate: lastFetchDate || null,
        credentialingCycle: response.credentialingWorkflow?.credentialingCycle,
        credentialingStatus,
        assignedStates: response.assignedStates,
        states: response.states,
        flags: findNamesMismatchFlag(response.currentFlags),
        verifications: verifications,
        fileTypeScores: response.fileTypeScores,
        externalId: response.externalId,
      },
    ],
    assignment: [
      {
        id: 'assignment',
        assignedBy:
          (response.credentialingWorkflow?.assignment?.assignedByFirstName ||
            '') +
          ' ' +
          (response.credentialingWorkflow?.assignment?.assignedByLastName ||
            ''),
        assignedTo: response.credentialingWorkflow?.assignment?.assignedToId,
        lastActivity: formatDateTime(
          apiResponseDateToDate(
            response.credentialingWorkflow?.assignment?.lastActivity!,
          ),
        ),
        daysOpen: dateDiffInDays(
          // Set time to zero to get exact date difference and ignoring timestamps,
          new Date(
            (
              apiResponseDateToDate(
                response?.credentialingWorkflow?.createdAt,
              ) as Date
            )?.setHours(0, 0, 0, 0),
          ),
          response?.credentialingWorkflow?.isCompleted
            ? (apiResponseDateToDate(
                response?.credentialingWorkflow?.completedAt,
              ) as Date)
            : new Date(new Date().setHours(0, 0, 0, 0)),
        ),
      },
    ],
    stateLicenses: stateLicenses.map((item) => ({
      id: item.id,
      state: item.state,
      expirationDate: firestoreDateInUtc(item.expirationDate),
      issueDate: firestoreDateInUtc(item.issueDate),
      type: item.type,
      status: item.status,
      licenseNumber: item.licenseNumber,
      dataLastAcquiredDate: firestoreDateInUtc(item.dataLastAcquiredDate),
      licenseType: item.licenseType,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags, isMonitoring),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
      attachmentCount: item.attachmentCount,
    })),
    dea: dea.map((item, idx) => ({
      id: item.id || idx.toString(), // Added index as fallback if the id is missing as it breaks the UI
      state: item.state,
      deaNumber: item.deaNumber,
      type: item.type,
      expirationDate: firestoreDateInUtc(item.expirationDate),
      providerType: item.providerType,
      source: item.source,
      status: item.status,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
      attachmentCount: item.attachmentCount,
    })),
    boardCertifications: boardCertifications.map((item) => ({
      id: item.id,
      expirationDate: firestoreDateInUtc(item.expirationDate),
      issueDate: firestoreDateInUtc(item.issueDate),
      subSpecialty: item.subSpecialty,
      durationType: item.durationType,
      status: item.status,
      specialty: item.specialty,
      document: item.document,
      moc: item.moc,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      flags: item.flags,
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
      attachmentCount: item.attachmentCount,
    })),
    licensureActions: licensureActions.map((item) => ({
      id: item.id,
      lastActionDate: firestoreDateInUtc(item.lastActionDate),
      reportDate: firestoreDateInUtc(item.reportDate),
      actionType: item.actionType,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      state: item.state,
      details: item.details,
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
    })),
    sanctions: sanctions.map((item) => ({
      id: item.id,
      reportDate: firestoreDateInUtc(item.reportDate),
      typeOfAction: item.typeOfAction,
      sanctionType: item.sanctionType,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
    })),
    malpracticeInsurance: insurances.map((item) => ({
      expirationDate: firestoreDateInUtc(item.expirationDate),
      id: item.id,
      policyNumber: item.policyNumber,
      source: item.source,
      status: item.status,
      occurrenceCoverageAmount: item.occurrenceCoverageAmount,
      aggregateCoverageAmount: item.aggregateCoverageAmount,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
      attachmentCount: item.attachmentCount,
    })),
    perfIndicator: perf.map((item) => ({
      id: item.id,
      complaints: item.complaints,
      clinical: item.clinical,
      service: item.service,
      benefits: item.benefits,
      claims: item.claims,
      accessIssues: item.accessIssues,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
    })),
    nationalPractitionerDataBank: nationalPractitionerDataBank.map((item) => ({
      id: item.id,
      typeOfAction: item.typeOfAction,
      reportType: item.reportType,
      deceased: item?.reportData?.deceased,
      totalPaymentForThisPractitioner:
        item?.reportData?.totalPaymentForThisPractitioner ||
        item?.reportData?.amountOfPractitioner ||
        item?.reportData?.amount,
      lastReportDate: firestoreDateInUtc(item.lastReportDate),
      reportDate: firestoreDateInUtc(item.reportDate),
      processDate: firestoreDateInUtc(item.processDate),
      document: item.document,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
    })),
    appVerification: appVerifications.map((item) => ({
      id: item.id,
      verificationType: item.verificationType,
      explanation: item.explanation,
      source: item.source,
      disclosureQuestions: item?.disclosureQuestions,
      admittingPrivileges: item?.admittingPrivileges,
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
    })),
    training: eduTrainings.map((item) => ({
      id: item.id,
      dateOfScreenshot: firestoreDateInUtc(item.dateOfScreenshot),
      document: item.document,
      source: item.source,
      type: item.type,
      degree: item.degree,
      specialty: item.specialty,
      subSpecialty: item.subSpecialty,
      institution: item.institution,
      startDate: firestoreDateInUtc(item.startDate),
      endDate: firestoreDateInUtc(item.endDate),
      flag: calculateFlag(item as PossibleItemFlags),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
      attachmentCount: item.attachmentCount,
    })),
    affiliation: hospitalAffiliations.map((item) => ({
      id: item.id,
      name: item.name,
      source: item.source,
      type: item.type,
      verificationUrl: item.verificationUrl,
      specialty: item.specialty,
      startDate: firestoreDateInUtc(item.startDate) ?? '',
      endDate: firestoreDateInUtc(item.endDate),
      verifiedOn: apiResponseDateToDate(item.verifiedOn),
      verifiedAt: apiResponseDateToDate(item.verifiedAt),
      verifiedByFullName: item.verifiedByFullName,
      attachmentCount: item.attachmentCount,
    })),
    errorsWarnings: errorsWarnings.map(
      (item) =>
        ({
          id: item.id,
          name: item.name,
          description: item?.errorObject
            ? item?.errorObject[0]?.description
            : '',
          createdAt:
            item.createdAt &&
            apiResponseDateToDate(item.createdAt as FirestoreTimestamp),
          success: item.success,
        }) as ErrorsWarningsData,
    ),
    credentialingTimeline: [
      {
        ...transformCredentialingWorkflowTimeline(
          credentialingWorkflowTimeline,
        ),
        attestationDate: credentialingWorkflowTimeline.attestationDate
          ? firestoreDateInUtc(credentialingWorkflowTimeline.attestationDate)
          : dateInUtc(attestationDate),
      },
    ],

    supportingDocuments: supportingDocuments.map((item) => ({
      createdAt: apiResponseDateToDate(item.createdAt)!,
      createdBy: item.createdBy,
      fileType: item.fileType,
      fileUrl: item.fileUrl,
      originalFilename: item.originalFilename,
      isFileErrored: item.isFileErrored,
      gsUrl: item.gsUrl,
      id: item.id,
      flag: item.flag,
      subCollectionReference: item.subCollectionReference,
    })),
  };
};

export const transformProviderOrgEntities = (
  orgEntities: OrgEntityDetails[],
): CollapsedDataGridProps | undefined => {
  return orgEntities?.length
    ? {
        label: `${orgEntities
          .slice(0, 2)
          .map(({ generalInfo }) => generalInfo?.name)
          .join(
            ', ',
          )}${orgEntities.length > 2 ? ` and ${orgEntities.length - 2} more` : ''}`,
        tableData: {
          columns: [{ field: 'name', headerName: 'Group Name', width: 200 }],
          rows: orgEntities.map(({ generalInfo, id }) => ({
            name: generalInfo?.name,
            id,
          })),
        },
      }
    : undefined;
};
