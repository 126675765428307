import { MASKED_PASSWORD } from '#/Utils/Constants';
import { apiResponseDateToDate, formatDate } from '#/Utils/date';
import { PossibleItemFlags } from '#/types';
import {
  FetchPEWorkflowByIdResponse,
  FetchPEWorkflowResponse,
  Logins,
  TransformedFetchPEWorkflowByIdResponse,
} from '#/types/provider-enrollment-workflows.types';
import { isEmpty, first, uniqueId, get, uniq } from 'lodash';
import moment from 'moment';

export const transformPayorEnrollmentWorkflowsDetails = (
  response: FetchPEWorkflowResponse,
) => {
  return {
    data: response.workflows.map((item) => ({
      ...item,
      createdAt: apiResponseDateToDate(item.createdAt),
    })),
    total: response.count,
  };
};

export const transformPayorEnrollmentWorkflowByIdResponse = (
  response: FetchPEWorkflowByIdResponse,
): TransformedFetchPEWorkflowByIdResponse => {
  const data =
    response.requestType === 'Group'
      ? transformGroupPayorEnrollmentWorkflowDetails(response)
      : transformProviderPayorEnrollmentWorkflowDetails(response);
  return data as unknown as any;
};

const arrangeLogins = (logins: Logins[] = []) => {
  return logins?.map((login) => {
    return {
      accountType: login.accountType,
      link: login.link,
      username: login.username,
      id: get(login, 'id', uniqueId()),
      state: login.state,
      password: login.secretName ? MASKED_PASSWORD : null,
    };
  }) as TransformedFetchPEWorkflowByIdResponse['logins'];
};

export const transformGroupPayorEnrollmentWorkflowDetails = (
  response: FetchPEWorkflowByIdResponse,
): TransformedFetchPEWorkflowByIdResponse => {
  const { generalInfo, contactPerson } = response?.overview || {
    generalInfo: {},
    contactPerson: {},
  };

  // Contact Person Details
  const { phone, email, fax, fullName, serviceAddress, mailingAddress } =
    contactPerson ?? {};

  const states = uniq(
    response.locations
      ?.map((location: any) => {
        return [
          location?.serviceAddress?.state,
          location?.billingAddress?.state,
          location?.mailingAddress?.state,
        ];
      })
      .reduce((acc, addressStates) => {
        acc = [...acc, ...addressStates.filter((item) => !!item)];
        return acc;
      }, []),
  );

  return {
    ...response,
    overview: {
      affiliation: generalInfo.affiliation,
      tin: generalInfo.tin,
      specialty: generalInfo.specialty,
      npi: generalInfo.npi,
      dba: response.entityFullName,
      ptan: generalInfo.ptan,
      networkEffectiveDate: apiResponseDateToDate(
        generalInfo.networkEffectiveDate,
      ),
      practiceType: generalInfo.practiceType,
      states,
      primaryPracticeLocation: null,
      locationCount: generalInfo?.locationCount ?? 0,
      effectiveProvidersCount: generalInfo?.effectiveProvidersCount ?? 0,
      unenrolledProviderCount: generalInfo?.unenrolledProviderCount ?? 0,
      providersCount: generalInfo?.providersCount ?? 0,
      externalGroupId: generalInfo?.externalGroupId ?? '',
    },
    contact: {
      phone,
      email,
      fax,
      fullName,
      serviceAddress,
      mailingAddress,
    },
    workflowInfo: {
      assignedTo: response?.assignment?.assignedToId,
      daysOpen:
        response?.assignment?.assignedOn &&
        moment(response?.assignment?.assignedOn).diff(moment(), 'days'),
      applicationId: response?.id,
      resubmissionParentWorkflowId: response?.resubmissionParentWorkflowId,
      outreachAttempts: response?.outreachAttempts,
      nextActivityDate: response?.nextActivityDate,
      previousActivityDate: formatDate(response?.previousActivityDate),
      lastActivityNote: response?.lastActivityNote,
    },
    planDetails: {
      ...response.planDetails,
      groupName: response.entityFullName,
    },
    createdAt: apiResponseDateToDate(response.createdAt),
    logins: arrangeLogins(response.logins),
    providerDetailsInEnrollment: response.providerDetailsInEnrollment.map(
      (item) => ({
        id: item.id,
        npi: item?.npi,
        firstName: item?.firstName,
        lastName: item?.lastName,
        type: item?.requestType,
        specialty: null,
        locations: null,
        applicationStatus: item?.status,
        jurisdiction: item?.planDetails?.jurisdiction,
        plan: item?.planDetails?.planName,
        payor: item?.planDetails?.payorName,
        lineOfBusiness: item?.planDetails?.lineOfBusiness,
        createdAt: item.createdAt,
      }),
    ),
    professionalIds: (response['professional-ids'] || []).map((item) => ({
      id: item.id,
      type: item.type,
      note: item.note,
      number: item.number,
      source: item.source,
      state: item.state,
      issueDate: formatDate(item.issueDate as unknown as string),
      expirationDate: formatDate(item.expirationDate as unknown as string),
    })),
  };
};

export function transformProviderPayorEnrollmentWorkflowDetails(
  response: FetchPEWorkflowByIdResponse,
) {
  const stateLicenses = response['state-licenses'] || [];
  const boardCertifications = response['board-certifications'] || [];
  const licensureActions = response['licensure-actions'] || [];
  const sanctions = response['sanctions'] || [];
  const dea = response['dea-data'] || [];
  const insurances = response['malpractice-insurances'] || [];
  const perf = response['performance-data'] || [];
  const nationalProviderDataBank = response['npdb-data'] || [];
  const appVerifications = response['application-verifications'] || [];
  const professionalIds = response['professional-ids'] || [];
  const eduTrainings = response['education-trainings'] || [];
  const supportingDocuments = response['supporting-documents'] || [];
  const professionalExams = response['professional-exams'] || [];
  const admittingPrivileges = response['admitting-privileges'] || [];
  const calculateFlag = (item: PossibleItemFlags) => {
    const isProviderExposedToNewFlags = 'currentFlags' in response;
    let calculatedFlag;

    if (item.flags || isProviderExposedToNewFlags) {
      calculatedFlag = !isEmpty(item.flags)
        ? first(Object.values(item.flags))
        : {};
    } else {
      calculatedFlag = { ...item.flag };
    }

    if (isEmpty(calculatedFlag)) {
      return {};
    }

    return {
      ...calculatedFlag,
    };
  };

  return {
    ...response,
    ssn: response.hasSSN ? '******' : '',
    race: response.race?.[0]?.raceEthnicityLevel1,
    otherLanguages: response.otherLanguages?.map((item) => item.name) ?? [],
    dateOfBirth: response.dateOfBirth && formatDate(response.dateOfBirth),
    assignedToId: response?.assignment?.assignedToId,
    lastActivity:
      response?.assignment?.lastActivity &&
      formatDate(response.assignment.lastActivity),
    daysOpen:
      response?.assignment?.assignedOn &&
      moment(moment()).diff(response?.assignment?.assignedOn, 'days'),
    applicationId: response?.id,
    resubmissionParentWorkflowId: response?.resubmissionParentWorkflowId,
    outreachAttempts: response?.outreachAttempts,
    nextActivityDate: response.nextActivityDate,
    previousActivityDate: formatDate(response.previousActivityDate),
    lastActivityNote: response.lastActivityNote,
    planDetails: {
      ...(response.planDetails || {}),
      groupName: response.groupName,
    },
    logins: arrangeLogins(response.logins),
    'state-licenses': stateLicenses.map((item) => ({
      id: item.id,
      state: item.state,
      expirationDate: formatDate(item.expirationDate as unknown as string),
      issueDate: formatDate(item.issueDate as unknown as string),
      type: item.type,
      status: item.status,
      licenseNumber: item.licenseNumber,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'dea-data': dea.map((item, idx) => ({
      id: item.id || idx.toString(), // Added index as fallback if the id is missing as it breaks the UI
      state: item.state,
      deaNumber: item.deaNumber,
      expirationDate: formatDate(item.expirationDate as unknown as string),
      providerType: item.providerType,
      source: item.source,
      status: item.status,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'board-certifications': boardCertifications.map((item) => ({
      id: item.id,
      expirationDate: formatDate(item.expirationDate as unknown as string),
      issueDate: formatDate(item.issueDate as unknown as string),
      subSpecialty: item.subSpecialty,
      durationType: item.durationType,
      status: item.status,
      specialty: item.specialty,
      document: item.document,
      moc: item.moc,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'licensure-actions': licensureActions.map((item) => ({
      id: item.id,
      lastActionDate: formatDate(item.lastActionDate as unknown as string),
      reportDate: formatDate(item.reportDate as unknown as string),
      actionType: item.actionType,
      source: item.source,
      state: item.state,
      details: item.details,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    sanctions: sanctions.map((item) => ({
      id: item.id,
      reportDate: formatDate(item.reportDate as unknown as string),
      typeOfAction: item.typeOfAction,
      sanctionType: item.sanctionType,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'malpractice-insurances': insurances.map((item) => ({
      expirationDate: formatDate(item.expirationDate as unknown as string),
      id: item.id,
      policyNumber: item.policyNumber,
      source: item.source,
      status: item.status,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'performance-data': perf.map((item) => ({
      id: item.id,
      complaints: item.complaints,
      clinical: item.clinical,
      service: item.service,
      benefits: item.benefits,
      claims: item.claims,
      accessIssues: item.accessIssues,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'npdb-data': nationalProviderDataBank.map((item) => ({
      id: item.id,
      typeOfAction: item.typeOfAction,
      reportType: item.reportType,
      deceased: item?.reportData?.deceased,
      totalPaymentForThisPractitioner:
        item?.reportData?.totalPaymentForThisPractitioner ||
        item?.reportData?.amountOfPractitioner ||
        item?.reportData?.amount,
      lastReportDate: formatDate(item.lastReportDate as unknown as string),
      reportDate: formatDate(item.reportDate as unknown as string),
      processDate: formatDate(item.processDate as unknown as string),
      document: item.document,
      source: item.source,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'application-verifications': appVerifications.map((item) => ({
      id: item.id,
      verificationType: item.verificationType,
      explanation: item.explanation,
      source: item.source,
      disclosureQuestions: item?.disclosureQuestions,
      admittingPrivileges: item?.admittingPrivileges,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'professional-ids': professionalIds.map((item) => ({
      id: item.id,
      type: item.type,
      note: item.note,
      number: item.number,
      flag: calculateFlag(item as PossibleItemFlags),
      issueDate: formatDate(item.issueDate as unknown as string),
      expirationDate: formatDate(item.expirationDate as unknown as string),
      source: item.source,
      state: item.state,
    })),
    'admitting-privileges': admittingPrivileges.map((item) => ({
      ...item,
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      endDate: formatDate(item.endDate as unknown as string),
      startDate: formatDate(item.startDate as unknown as string),
    })),
    'professional-exams': professionalExams.map((item) => ({
      id: item.id,
      type: item.type,
      passed: item.passed,
      step: item.step,
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
      attemptedDate: formatDate(item.attemptedDate as unknown as string),
    })),
    'education-trainings': eduTrainings.map((item) => ({
      id: item.id,
      dateOfScreenshot: formatDate(item.dateOfScreenshot as unknown as string),
      document: item.document,
      source: item.source,
      type: item.type,
      specialty: item.specialty,
      institution: item.institution,
      startDate: formatDate(item.startDate as unknown as string),
      endDate: formatDate(item.endDate as unknown as string),
      flag: calculateFlag(item as PossibleItemFlags),
      updatedAt: apiResponseDateToDate(item.updatedAt as unknown as string),
      updatedByFullName: item.updatedByFullName,
    })),
    'supporting-documents': supportingDocuments.map((item) => ({
      createdAt: new Date(item.createdAt as unknown as string),
      createdBy: item.createdBy,
      fileType: item.fileType,
      fileUrl: item.fileUrl,
      originalFilename: item.originalFilename,
      gsUrl: item.gsUrl,
      id: item.id,
      flag: item.flag,
      subCollectionReference: item.subCollectionReference,
    })),
    notes: (response.notes ?? []).map((note) => {
      return {
        ...note,
        createdAt: apiResponseDateToDate(note?.createdAt),
      };
    }),
  };
}
